import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

/**
 * @function
 * This receives a list of either voice emotion styles or voice emotion degrees and
 * filters it depending on the selected voice from the selected parts redux.
 * @param {Array} possibleValuesArray: either possibleStylesFile or possibleDegreesFile from /state/possibleEmotions.
 * @param {String} tts: TTS provider that comes from the selectedPart redux state. 
 * @param {String} selectedVoice: The voice that comes from the selectedPart redux state.
 * @returns JSX: Material UI MenuItems rendering selectable options to choose depending on the 
 * TTS provider.
 */
export const filterPossibleValues = (possibleValuesArray: any, tts: string, selectedVoice: any) => {
    if (tts === "microsoft") {
        if (selectedVoice?.emotions) {
            possibleValuesArray = possibleValuesArray.filter((element: any) => element.availableTTS.find((nestedElement: any) => nestedElement === tts))
            return possibleValuesArray
                .filter((element: any) =>
                    selectedVoice.emotions.find(
                        (emotion: any) => emotion.name.toLowerCase() === element.value.toLowerCase()))
                .map((element: any) => {
                    return (
                        <MenuItem
                            value={element.value.toLowerCase()}
                            key={element.value}
                        >
                            <Typography component='div'>{element.value}</Typography>
                        </MenuItem>
                    )
                })
        } else {
            return []
        }
    } else {
        return possibleValuesArray
            .filter((element: any) => element.availableTTS.find((nestedElement: any) => nestedElement === tts))
            .map((element: any) => {
                return (<MenuItem value={element.value.toLowerCase()} key={element.value} >
                    <Typography component='div'>{element.value}</Typography>
                </MenuItem>)
            })
    }
};
