import { Emotion } from "../interfaces/article/Emotion.interface";

/**
 * @constant
 * Array of values that represent the current available emotion styles.
 */
export const possibleStylesFile: Emotion[] = [
    { value: "Anger", availableTTS: ["deepzen"] },
    { value: "Angry", availableTTS: ["microsoft"] },
    { value: "Cheerful", availableTTS: ["microsoft"] },
    { value: "Disgust", availableTTS: ["deepzen"] },
    { value: "Empathetic", availableTTS: ["microsoft"] },
    { value: "Excited", availableTTS: ["deepzen", "microsoft"] },
    { value: "Fear", availableTTS: ["deepzen"] },
    { value: "Friendly", availableTTS: ["microsoft"] },
    { value: "Happy", availableTTS: ["deepzen"] },
    { value: "Hopeful", availableTTS: ["microsoft"] },
    { value: "Sad", availableTTS: ["deepzen", "microsoft"] },
    { value: "Shouting", availableTTS: ["microsoft"] },
    { value: "Surprise", availableTTS: ["deepzen"] },
    { value: "Terrified", availableTTS: ["microsoft"] },
    { value: "Unfriendly", availableTTS: ["microsoft"] },
    { value: "Whispering", availableTTS: ["microsoft"] },

    { value: "Automatic", availableTTS: ["cerence"] },
    { value: "Chat", availableTTS: ["microsoft"] },
    { value: "Customerservice", availableTTS: ["microsoft"] },
    { value: "Documentary", availableTTS: ["cerence"] },
    { value: "Headlines", availableTTS: ["cerence"] },
    { value: "Narration-professional", availableTTS: ["microsoft"] },
    { value: "Neutral", availableTTS: ["cerence"] },
    { value: "News", availableTTS: ["cerence"] },
    { value: "Newscast", availableTTS: ["microsoft"] },
    { value: "Newscast-casual", availableTTS: ["microsoft"] },
    { value: "Newscast-formal", availableTTS: ["microsoft"] },
    { value: "News Dialog", availableTTS: ["cerence"] },
    { value: "News Intro", availableTTS: ["cerence"] },
    { value: "News Mezzo", availableTTS: ["cerence"] },
    { value: "News Outro", availableTTS: ["cerence"] },
    { value: "News Sport", availableTTS: ["cerence"] },
    { value: "News World", availableTTS: ["cerence"] },
];

/**
 * @constant
 * Array of elements that represent the current emotion's intensity degree available values. 
 */
export const possibleDegreesFile = [
    { value: "Low", availableTTS: ["deepzen"] },
    { value: "Medium", availableTTS: ["deepzen"] },
    { value: "High", availableTTS: ["deepzen"] },
];
