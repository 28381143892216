import { PlayerState } from "../interfaces/PlayerState.interface";


export const playerDefaultState: PlayerState = {
    controls: false,
    duration: 0,
    isAlreadyPlayed: false,
    isBuffering: false,
    light: false,
    loadAgain: true,
    loaded: 0,
    loadedSeconds: 0.0,
    loop: false,
    muted: false,
    pip: false,
    playbackRate: 1.0,
    playbackRateLabel: '1x',
    played: 0.0,
    playedSeconds: 0,
    playing: false,
    seeking: false,
    url: '',
    volume: 0.8,
}
