import { OdiaTag } from "../odiaTag";

export const breakTag1 = new OdiaTag(
    "break-1",
    (part: any, tagContent: string, tagParameters: any, index: number) => {
        part.pauses.push({
            duration: 1000,
            offset: index
        });
        return part;
    },
    "Break tag - 1.0s",
    ["microsoft", "google", "deepzen"],
    "1.0s"
);
