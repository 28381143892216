import { useState } from 'react';

// mui
import { useTheme } from '@mui/material/styles';
import {
    Button,
    Menu,
    MenuItem,
    Typography
} from '@mui/material';
import { PlaybackRate } from '../../../../../../interfaces/playbackRate.interface';
import { PlayerState } from '../../../../../../interfaces/PlayerState.interface';

// interfaces

interface Props {
    playbackRate: number,
    playbackRateLabel: string,
    setPlayerState: React.Dispatch<React.SetStateAction<PlayerState>>
}

const playbackRates: PlaybackRate[] = [
    {
        id: 0,
        playbackRate: 0.5,
        playbackRateLabel: '0.5x'
    },
    {
        id: 1,
        playbackRate: 0.8,
        playbackRateLabel: '0.8x'
    },
    {
        id: 2,
        playbackRate: 1,
        playbackRateLabel: '1x'
    },
    {
        id: 3,
        playbackRate: 1.2,
        playbackRateLabel: '1.2x'
    },
    {
        id: 4,
        playbackRate: 1.5,
        playbackRateLabel: '1.5x'
    },
    {
        id: 5,
        playbackRate: 2,
        playbackRateLabel: '2x'
    },
]

export const SpeedRateSelectorMenu = ({ playbackRate, playbackRateLabel, setPlayerState }: Props) => {
    const theme = useTheme();

    const color = theme.palette.grey[600]

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleShowMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSelectRate = (id: number) => {
        const rateFilter = playbackRates.filter(rate => rate.id === id)[0];

        if (rateFilter) {
            const {
                playbackRate,
                playbackRateLabel
            } = rateFilter

            setPlayerState((state: any) => ({
                ...state,
                playbackRate,
                playbackRateLabel
            }))
        }

        handleClose();
    };

    return (
        <>

            <Button
                id="playback-button"
                aria-controls={open ? 'playback-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleShowMenu}
                sx={{ color }}
            >
                {playbackRateLabel}
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'playback-button',
                }}
            >
                {playbackRates.map(rate => (
                    <MenuItem
                        key={`rate-${rate.id}`}
                        onClick={() => handleSelectRate(rate.id)}
                    >
                        <Typography
                            color={
                                rate.playbackRate === playbackRate
                                    ? theme.palette.grey[400]
                                    : color
                            }
                        >
                            {rate.playbackRateLabel}
                        </Typography>

                    </MenuItem>
                ))}
            </Menu>
        </>

    )
}
