
import Swal from "sweetalert2";

// helpers
import { odiaTagIndexes } from "./indexes/odiaTagIndexes";
import { textReplacementIndexes } from "./indexes/textReplacementIndexes";
import { deleteHighlightTags } from "../runtimeProcessArticle/flagParts";
import { ArticlePart } from "../../../interfaces/article/ArticlePart.interface";


const applyHtmlClean = (html: string): string => {
    let outputContent = html.slice();

    // replace front related changes
    textReplacementIndexes.forEach((index) => {
        outputContent = outputContent.replace(index.toReplace, index.replacement);
    });
    return outputContent;
}

export const htmlToDTOPart = (html: string, part: ArticlePart): ArticlePart => {
    let partCopy = { ...part };

    //remove highlight tags
    partCopy.contentHtml = deleteHighlightTags(html);
    partCopy.contentHtml = applyHtmlClean(partCopy.contentHtml);
    const splitContent = partCopy.contentHtml.split(/(<[\s\S]*?>)/g);

    let { newPart, newContent } = applyTags(partCopy, splitContent, partCopy.voice.TTS);

    newPart.contentHtml = partCopy.contentHtml;
    newPart.content = newContent;
    return newPart;
}

export const dtoToHtmlPart = (part: ArticlePart): string => {
    return part.content;
}

export const htmlToDTOArticle = (name: string, articleParts: ArticlePart[], uid: number) => {
    let outputContent = "";
    let newParts = [...articleParts]

    // format title
    newParts[0] = htmlToDTOPart(newParts[0].content, newParts[0]);

    // format rest of article and add to content
    for (var i = 1; i < newParts.length; i++) {
        newParts[i] = htmlToDTOPart(newParts[i].content, newParts[i])

        outputContent += `<voice name="${newParts[i].voice.name}">`;
        outputContent += newParts[i].content;
        outputContent += `</voice>`;
    }

    // Introducing this modification. Otherwise, parts would get span tags embedded in other
    // properties after saving
    newParts.forEach((part, index) => {
        part = htmlToDTOPart(part.content, part);
    });

    let xmlData = {
        name,
        title: htmlToDTOPart(newParts[0].content, newParts[0]).content,
        voiceTitle: newParts[0].voice || "voiceHenri",
        content: outputContent,
        voiceLead: "voiceHenri",
        voiceContent: "voiceHenri",
        uid,
        parts: [...newParts]
    }

    return xmlData;
}

/**
 * Check tags and replace them with the correct ssml tag.
 * @param elements Array of HTML elements.
 * @param tts used TTS for the section.
 * @param currentTag Current tag (Recursive).
 * @param tagContent Tag content (Recursive).
 * @param tagParameters Tag parameters (Recursive).
 * @return {*} Parsed text.
 */
const applyTags = (
    part: ArticlePart,
    elements: string[],
    tts: string,
    currentTag: any = null,
    tagContent: Array<any> = [],
    tagParameters: any = null,
    offset: number = 0
) => {
    let partCopy = { ...part };
    for (let index = 0; index < elements.length; index++) {
        const element = elements[index];

        // we check if the element is a starting tag
        const matchingTag = element.match(/<span name=".*>/);
        if (matchingTag) {
            const tag = matchingTag[0].split('"')[1];
            for (let i = 0; i < odiaTagIndexes.length; i++) {
                const odiaTag = odiaTagIndexes[i];

                // tag found
                if (odiaTag.name === tag) {
                    elements[index] = '';
                    const beginning = elements.slice(0, index);

                    // We check if the tag is allowed with the current tts.
                    let isTagAvailableforTTS = false;
                    for (let ttsIndex = 0; ttsIndex < odiaTag.availableTTS.length; ttsIndex++) {
                        if (tts === odiaTag.availableTTS[ttsIndex]) {
                            isTagAvailableforTTS = true;
                            break;
                        }
                    }
                    if (!isTagAvailableforTTS) {
                        Swal.fire({
                            title: 'Error',
                            text: 'The tag "' + tag + '" is not supported by the selected voice.',
                            icon: 'error',
                            confirmButtonText: 'Ok'
                        });
                        throw new Error('The tag "' + tag + '" is not supported by the selected voice.');
                    }

                    const { newPart, newContent } = applyTags(
                        partCopy,
                        elements.slice(index),
                        tts,
                        odiaTag,
                        [],
                        element,
                        elements.slice(0, index).join('').length
                    );
                    elements = beginning.concat(newContent);
                    partCopy = newPart;
                    break;

                    // no tag found
                } else if (i === odiaTagIndexes.length - 1) {
                    console.warn('Tag not recognized : ' + tag);
                    Swal.fire("Error", "The tag " + tag + " is not recognized.", "error");
                }

            }

        } else if (currentTag) {
            // if we're in a tag, we search for a closing balise
            const matchingEndTag = element.match('</span>');
            if (matchingEndTag) {
                elements[index] = "";
                currentTag.action(part, tagContent, tagParameters, offset);

                return {
                    newPart: partCopy,
                    newContent: elements.join('')
                };

            } else {
                // While we're in a tag, we erase the content from the final result and we store it in tagContent
                tagContent.push(element);
                elements[index] = '';
            }
        }
    }
    return {
        newPart: partCopy,
        newContent: elements.join('')
    }
}
