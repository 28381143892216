import React, { useEffect, useState } from 'react';

// modules
import { useDispatch, useSelector } from 'react-redux';
import * as yup from "yup";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import Flag from 'react-world-flags';

// mui
import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    Stack,
    Typography
} from '@mui/material'

// interfaces
import { Selector } from '../../../../../../interfaces/Selector.interface';
import { Article } from "../../../../../../interfaces/article/Article.interface";
import { Playlist } from "../../../../../../interfaces/Playlist.interface";

// helpers
import { formatDateToString } from '../../../../../../helpers/formatDateToString';
import { getFlagCode } from '../../../../../../data/languageLabels';
import { LabelTypography } from '../../styles/RightPanelStyle';
import { loadPlaylists } from "../../../../../../actions/playlist.action";
import api from "../../../../../../helpers/services/api";


interface Props {
    playlist: Playlist
}

export const ArticleSelector = React.memo(({ playlist }: Props) => {
    const dispatch = useDispatch();

    // state selector
    const files = useSelector((state: Selector) => state.files);

    const [filteredFiles, setFilteredFiles] = useState<Article[]>([]);

    const initialFormValues: { articleIds: number[] } = {
        articleIds: []
    }

    const validationShape = {
        articleIds: yup
            .array()
            .min(1)
            .required('Add articles to your playlist')
    }

    const validationSchema = yup.object().shape(validationShape);

    const formik = useFormik({
        initialValues: initialFormValues,
        validationSchema,
        onSubmit: async (values: any) => {
            await handleSubmit(values)
            formik.resetForm();
        }
    });

    const handleSubmit = async (values: any) => {
        try {
            for (let articleId of values.articleIds) {
                await api.playlist.addArticle(articleId, playlist.id);
            }
            formik.values.articleIds = [];
            await Swal.fire('Success', 'New article(s) successfully added to the playlist', 'success');
        } catch (e) {
            await Swal.fire('Error', 'Error adding new article(s) to the playlist', 'error');
        }
        loadPlaylists().then(action => dispatch(action));
    }

    // Update the selected article ID list
    const handleSelectedArticle = (id: number) => {

        if (formik.values.articleIds.includes(id)) {
            const index = formik.values.articleIds.indexOf(id);
            formik.values.articleIds.splice(index, 1);
        }
        else {
            formik.values.articleIds.push(id);
        }
    }

    useEffect(() => {
        const playlistArticleIds = playlist.articles.map(article => article.id);
        setFilteredFiles(files.filter(file => !playlistArticleIds.includes(file.id)));
    }, [playlist, files])


    return (
        <Box sx={{
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: 'divider',
            borderRadius: '20px',
            p: 2
        }}>
            <form onSubmit={formik.handleSubmit} autoComplete="off">
                <LabelTypography
                    data-test="playlistrightpanel-lbl-add"
                    sx={{ mb: 2, textAlign: 'center' }}
                >
                    Add Articles
                </LabelTypography>

                {filteredFiles.map((file =>
                    (file.audioMediaFile != null)
                        ? (
                            <FormControlLabel
                                key={file.id}
                                sx={{ mb: '4px' }}
                                control={
                                    <Checkbox
                                        id={`${file.id}`}
                                        onChange={() => handleSelectedArticle(file.id)}
                                    />
                                }
                                label={<Box sx={{ display: 'inline' }}
                                >
                                    {
                                        file.language &&
                                        <Flag
                                            id={"flag-img-article"}
                                            code={getFlagCode(file.language.code)}
                                        />
                                    }
                                    <Typography
                                        sx={{ fontSize: '14px', fontWeight: 'bold', display: 'inline', ml: '4px' }}
                                    >
                                        {file.fileName}
                                    </Typography>
                                    <Typography sx={{ fontSize: '14px' }}>
                                        {formatDateToString(file.createdAt)}
                                    </Typography>
                                </Box>}
                            />)
                        : <React.Fragment key={file.id}></React.Fragment>
                ))}

                <Stack
                    alignItems="center"
                    direction="row"
                    justifyContent="center"
                    spacing={{ xs: 1, sm: 2, md: 4 }}
                    sx={{ mt: 3 }}
                >
                    <Button
                        data-test="playlistrightpanel-btn-add"
                        variant="contained"
                        type="submit"
                        disabled={formik.isSubmitting}
                    >
                        Add
                    </Button>
                </Stack>
            </form>
        </Box>
    );
})
