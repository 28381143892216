// store
import { store } from "../store/store";

// actions
import {
    showInformationSnackBar,
    showNotPermanentErrorSnackBar,
    showNotPermanentSuccessSnackBar
} from "../actions/snackBar.actions";

// services
import api from "./services/api";

// interfaces
import { Language } from "../interfaces/article/Language.interface";

export async function translate(articleId: number, languageCode: string, translator: string) {
    store.dispatch(showInformationSnackBar('Translation started'));

    const allLanguagesResponse = await api.language.getAll();
    const allLanguages: Language[] = await allLanguagesResponse.json();

    const language = allLanguages.find((language) => language.code === languageCode);
    if (language !== undefined) {
        const result = await api.article.translate(articleId, language.id, translator)
        if (result.status === 201) {
            store.dispatch(showNotPermanentSuccessSnackBar('Translation finished'));
        } else {
            store.dispatch(showNotPermanentErrorSnackBar('Translation failed'));
        }
    } else {
        store.dispatch(showNotPermanentErrorSnackBar('Translation failed'));
    }
}