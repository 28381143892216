import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';

// actions
import { deleteUser } from '../../../../../actions/users.actions';

// mui
import { ButtonGroup, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';


export const UserTableButtons = ({ userId }: { userId: number }) => {
    const dispatch = useDispatch();

    const handleDeleteUser = (u: number) => {
        Swal.fire({
            title: 'Are you sure you want to delete this user?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel'
        }).then(result => {
            if (result.isConfirmed) {
                deleteUser(u).then(action => dispatch(action));
            }
        })
    }


    return (
        <ButtonGroup component="span">
            <IconButton
                key={`edit-${userId}`}
                color="primary"
                component={Link}
                to={`/users/edit/${userId}`}
            >
                <EditIcon />
            </IconButton>
            <IconButton
                key={`delete-${userId}`}
                color="secondary"
                onClick={() => handleDeleteUser(userId)}
            >
                <DeleteIcon />
            </IconButton>
        </ButtonGroup>
    )
}
