import { AnyAction } from "redux";
import { types } from "../actions/actionTypes/actionTypes";
import { PopupState } from "../interfaces/Selector.interface";

export const popupType = {
    newPlaylist: "newPlaylist",
    rawArticle: "rawArticle",
    sectionLanguage: "sectionLanguage",
    translation: "translation",
    uploadAudio: "uploadAudio",
    editArticleDisplayName: "editArticleDisplayName"
}


const initialState: PopupState = {
    showModal: null,
    article: null,
    editArticleDisplayNamePayload: null,
};

export const popupsReducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case types.popupShow:
            return {
                ...state,
                showModal: action.payload.data,
            }
        case types.popupShowRawArticle:
            return {
                ...state,
                showModal: popupType.rawArticle,
            };
        case types.popupShowTranslation:
            return {
                ...state,
                showModal: popupType.translation,
                article: action.payload.data,
            }
        case types.popupShowNewPlaylist:
            return {
                ...state,
                showModal: popupType.newPlaylist,
            }
        case types.popupShowUploadAudio:
            return {
                ...state,
                showModal: popupType.uploadAudio,
            }
        case types.popupShowEditArticleDisplayName:
            return {
                ...state,
                showModal: popupType.editArticleDisplayName,
                editArticleDisplayNamePayload: action.payload.data,
            }
        case types.popupShowSectionLanguage:
            return {
                ...state,
                showModal: popupType.sectionLanguage,
            }
        case types.popupHide:
            return {
                ...state,
                showModal: null,
            }
        default:
            return state;
    }
}