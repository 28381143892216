// modules
import { useDispatch } from 'react-redux';

// mui
import { TreeItem } from '@mui/x-tree-view';

// components
import { ItemIcon } from './itemIcon/ItemIcon';
import { ItemTitle } from './itemTitle/ItemTitle';

// actions
import {
    clearActiveArticle,
    editorSetIsDownloadable,
    setActiveArticle,
} from "../../../../../../actions/articles.actions";
import { resetAudioState } from '../../../../../../actions/audio.actions';
import { partsUpdate } from '../../../../../../actions/parts.actions';
import { showWarningSnackBar } from '../../../../../../actions/snackBar.actions';

// helpers
import api from "../../../../../../helpers/services/api";
import { dtoToHtmlPart } from '../../../../../../helpers/articles/saveArticle/processEditorArticle';

// interfaces
import { Article, ArticleWithContent } from '../../../../../../interfaces/article/Article.interface';


interface Props {
    item: Article,
    showArticleName: boolean
}

export const VideoFileItem = ({ item, showArticleName }: Props) => {
    const dispatch = useDispatch();

    const { id, createdAt, fileName } = item;
    let title: string = "";

    if (showArticleName) {
        title = fileName.replace(/\.[^/.]+$/, "");
    } else {
        // Format date to display it
        const date = new Date(createdAt);
        const options: Intl.DateTimeFormatOptions = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
        };
        const formatter = new Intl.DateTimeFormat('en-UK', options);
        const formattedDate = formatter.format(date).replace(',', ' at');

        title = formattedDate;
    }

    /**
     * Manage the selection of an article and set it active.
     * @param article {Article} - The article to load

     */
    const handleSelectFile = async (article: Article): Promise<void> => {
        if (!article) return;

        const resp = await api.article.get(id);
        const respBody: ArticleWithContent = await resp.json();

        const { parts, ...rest } = respBody;
        const articleData = { ...rest };

        const extendedParts = parts.map(part => ({
            ...part,
            contentHtml: dtoToHtmlPart(part),
            contentHtmlOriginal: dtoToHtmlPart(part)
        }))

        if (respBody.status) {
            dispatch(setActiveArticle(articleData));
            dispatch(partsUpdate([...extendedParts]));
        } else {
            dispatch(clearActiveArticle());
            dispatch(showWarningSnackBar('Article could not be loaded'))
        }


        const { status, videoMediaFile } = articleData;

        // length > 4 to ensure it's longer than a file extension like '.mp4'
        if ((status === 'DRAFT' || status === 'PUBLISHED') && videoMediaFile?.url && videoMediaFile?.url?.length > 4) {
            dispatch(editorSetIsDownloadable(true));
        }
        else {
            dispatch(editorSetIsDownloadable(false));
            dispatch(resetAudioState());
        }
    }


    return (
        <TreeItem
            icon={<ItemIcon item={item} />}
            id={`${id}`}
            key={`${id}`}
            label={
                <ItemTitle
                    title={title}
                    isParentArticle={showArticleName}
                    selectedVersion={item.selectedVersion}
                />
            }
            nodeId={`${id}`}
            onClick={() => handleSelectFile(item)}
        />
    )
}
