// mui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    CircularProgress,
    Container,
    Typography,
} from '@mui/material'


/**
 * The loading page component for the app
 * @constructor
 */
export const LoadingPage = () => {
    const theme = useTheme();

    return (
        <Container
            component="main"
            maxWidth="xs"
        >
            <Box
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: theme.spacing(8),
                }}
            >
                <img
                    src={
                        theme.palette.mode === 'dark'
                            ? './assets/logo_odia_light.png'
                            : './assets/logo_odia.png'
                    }
                    alt='logo'
                    style={{ marginBottom: theme.spacing(5) }}
                />

                <CircularProgress
                    size={100}
                    sx={{ my: 10 }}
                />

                <Typography
                    component='div'
                    variant='body1'
                    sx={{
                        textAlign: 'center',
                        color: theme.palette.grey[600],
                        lineHeight: 2
                    }}
                >
                    This should take less than a second
                    <br />
                    If you are seeing this, there could be some issue contacting the server
                </Typography>


            </Box>
        </Container>
    )
}
