import { Client } from "./client.model";


export class LexiconEntry {

    id: number;
    originalText: string;
    translationText: string;
    isGeneric: boolean;
    client: Client | null;
    language: string;
    isTtsDependant: boolean;
    tts: string | null;

    constructor(
        id: number,
        originalText: string,
        translationText: string,
        isGeneric: boolean,
        client: Client | null,
        language: string,
        isTtsDependant: boolean,
        tts: string | null
    ) {
        this.id = id;
        this.originalText = originalText;
        this.translationText = translationText;
        this.isGeneric = isGeneric;
        this.client = client;
        this.language = language;
        this.isTtsDependant = isTtsDependant;
        this.tts = tts;
    }
}
