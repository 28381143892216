import { useDispatch, useSelector } from "react-redux";

// mui
import { Box, Modal } from "@mui/material";

// interfaces
import { Selector } from "../../interfaces/Selector.interface";

// redux
import { hidePopup } from "../../actions/popup.action";
import { popupType } from "../../reducers/popupsReducer";

// components
import { NewPlaylistPopup } from "../popups/NewPlaylistPopup";
import { RawArticlePopup } from "../popups/RawArticlePopup";
import { TranslateArticlePopup } from "../popups/TranslateArticlePopup";
import { UploadAudioPopup } from "../popups/UploadAudioPopup";
import { EditArticleDisplayNamePopup } from "../popups/EditArticleDisplayNamePopup";
import { SectionLanguagePopup } from "../popups/SectionLanguagePopup";


export const Popup = () => {
    const dispatch = useDispatch();

    // state selector
    const article = useSelector((state: Selector) => state.popups.article);
    const showModal = useSelector((state: Selector) => state.popups.showModal);
    const editArticleDisplayNamePayload = useSelector((state: Selector) => state.popups.editArticleDisplayNamePayload);

    const handleClose = () => dispatch(hidePopup());


    return (
        <Modal
            open={showModal != null}
            onClose={handleClose}
            aria-labelledby="generic-popup"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 5,
                    borderRadius: 10,
                    overflowY: "auto",
                    maxHeight: "95%"
                }}
            >
                {
                    showModal === popupType.rawArticle &&
                    <RawArticlePopup handleModalClose={handleClose} />
                }

                {
                    showModal === popupType.translation &&
                    <TranslateArticlePopup articles={article} handleModalClose={handleClose} />
                }

                {
                    showModal === popupType.newPlaylist &&
                    <NewPlaylistPopup handleModalClose={handleClose} />
                }

                {
                    showModal === popupType.uploadAudio &&
                    <UploadAudioPopup handleModalClose={handleClose} />
                }

                {
                    showModal === popupType.editArticleDisplayName &&
                    <EditArticleDisplayNamePopup
                        handleModalClose={handleClose}
                        article={editArticleDisplayNamePayload?.article}
                    />
                }

                {
                    showModal === popupType.sectionLanguage &&
                    <SectionLanguagePopup handleModalClose={handleClose} />
                }
            </Box>
        </Modal>
    );

}