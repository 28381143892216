export class OdiaTag {
    // the tag to execute
    name: string;

    // the text to display in the editorPage
    displayText: string;

    // the list of TTS providers that support this tag
    availableTTS: Array<string>;

    // the action that affect the article part
    action: Function;

    // the tag to display
    displayTag: string;


    constructor(tag: string, action: Function, displayText: string, availableTTS: Array<string>, displayTag: string) {
        // the name of the tag that will be searched
        this.name = tag;
        // the text to display in the editorPage
        this.displayText = displayText;
        // the tts providers that support this tag
        this.availableTTS = availableTTS;
        // the action
        this.action = action;
        // the tag to display
        this.displayTag = displayTag;
    }
}
