import React from 'react';

import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';

// mui
import {
    Box,
    Button,
    Container,
    FormControl,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

// actions
import {
    createUser,
    updateUser
} from '../../../../../actions/users.actions';
import { Selector } from "../../../../../interfaces/Selector.interface";
import { User } from '../../../../../interfaces/user/user.interface';


const MainLayout = styled(Box)(({ theme }) => ({
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
}));


export const UserEditForm = React.memo(() => {
    const theme = useTheme();
    const dispatch = useDispatch();

    // status selector
    const editingUser = useSelector((state: Selector) => state.users.activeUser);
    const clientsList = useSelector((state: Selector) => state.users.clientsList);
    const userRole = useSelector((state: Selector) => state.auth.role);

    const editingMode = (editingUser && editingUser?.id) ? true : false;

    let allowedUserRoles = ['Admin', 'User'];
    if (userRole === 'SuperAdmin') allowedUserRoles = allowedUserRoles.concat(userRole);

    const user: User = {
        name: editingUser?.name || '',
        email: editingUser?.email || '',
        password: '',
        typeDescription: editingUser?.typeDescription || '',
        id: editingUser?.id || 0,
        videoAuthorisation: editingUser?.videoAuthorisation || false,
        impersonator: editingUser?.impersonator || false,
        canBeImpersonated: editingUser?.canBeImpersonated || false,
        client: editingUser?.client || '',
        generationMode: editingUser?.generationMode || '',
        type: editingUser?.type || 0
    }

    let clients: string[] = [];
    clientsList.forEach(client => {
        if (!clients.includes(client.name)) clients.push(client.name)
    })


    const handleSubmit = (values: User) => {
        if (editingMode) {
            updateUser(values).then(action => dispatch(action));
        } else {
            createUser(values).then(action => dispatch(action));
        }
    }

    const validationShape = {
        name: yup
            .string()
            .min(4, 'User name should be of minimum 4 characters length')
            .required('User name is required'),
        email: yup
            .string()
            .email('Enter a valid email'),
        password: yup
            .string()
            .min(8, 'Password should be of minimum 8 characters length'),
        typeDescription: yup
            .string()
            .oneOf(allowedUserRoles, "Invalid user role")
            .required('Select a valid user role'),
        client: yup
            .string()
            .oneOf(clients, "Invalid Client")
            .required('Select a valid client')
    };

    if (!editingMode) {
        validationShape.password = yup
            .string()
            .min(8, 'Password should be of minimum 8 characters length')
            .required('Password is required');
    }


    const validationSchema = yup.object().shape(validationShape);
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: user,
        validationSchema,
        onSubmit: values => { handleSubmit(values) }
    });


    return (
        <MainLayout>
            <Container maxWidth="lg">
                <Typography
                    component='span'
                    variant='h6'
                    color='primary'
                >
                    {
                        editingMode
                            ? `Edit User #${editingUser?.id}`
                            : 'New User'
                    }
                </Typography>
            </Container>
            <Paper
                elevation={3}
                sx={{
                    borderRadius: 4,
                    my: theme.spacing(3),
                    p: theme.spacing(5),
                }}
            >
                <form
                    onSubmit={formik.handleSubmit}
                    autoComplete="off"
                >
                    <Stack
                        direction='column'
                        spacing={4}
                    >
                        <TextField
                            autoComplete="new-name"
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            fullWidth
                            helperText={formik.touched.name && formik.errors.name}
                            id="name"
                            label="User name"
                            name="name"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.name}
                        />

                        <TextField
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            fullWidth
                            helperText={formik.touched.email && formik.errors.email}
                            id="email"
                            label="Email"
                            name="email"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.email}
                        />

                        <TextField
                            autoComplete="new-password"
                            error={formik.touched.password && Boolean(formik.errors.password)}
                            fullWidth
                            helperText={formik.touched.password && formik.errors.password}
                            id="password"
                            label="Password"
                            name="password"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            type="password"
                            value={formik.values.password}
                        />

                        <FormControl>
                            <InputLabel id="userTypeLabel">User role</InputLabel>
                            <Select
                                error={formik.touched.typeDescription && Boolean(formik.errors.typeDescription)}
                                id="typeDescription"
                                label="User Role"
                                labelId="userTypeLabel"
                                name="typeDescription"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.typeDescription}
                            >
                                <MenuItem value="">
                                    <em>Select</em>
                                </MenuItem>
                                {
                                    allowedUserRoles.map(role => (
                                        <MenuItem
                                            value={role}
                                            key={role}
                                        >
                                            {role}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>

                        <FormControl>
                            <InputLabel id="clientLabel">Client</InputLabel>
                            <Select
                                error={formik.touched.client && Boolean(formik.errors.client)}
                                id="client"
                                label="Client"
                                labelId="clientLabel"
                                name="client"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.client}
                            >
                                <MenuItem value="">
                                    <em>Select</em>
                                </MenuItem>
                                {
                                    clients.sort().map(client => (
                                        <MenuItem
                                            value={client}
                                            key={client}
                                        >
                                            {client}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>

                        <Button
                            color="primary"
                            type="submit"
                            variant="contained"
                        >
                            {editingMode ? 'Save' : 'Create'}
                        </Button>
                    </Stack>
                </form>
            </Paper>
        </MainLayout>
    );
})
