import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// mui
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';

// actions
import { partsUpdate } from "../../../../../../actions/parts.actions";
import { editorPartUpdate } from "../../../../../../actions/articles.actions";

import { Selector } from "../../../../../../interfaces/Selector.interface";
import { filterPossibleValues } from '../../../../../../helpers/emotionFilters';
import { possibleDegreesFile, possibleStylesFile } from "../../../../../../state/possibleEmotions";
import { ArticlePart } from '../../../../../../interfaces/article/ArticlePart.interface';



/**
 * @function
 * EmotionSelector component. Renders voice emotion styles and emotion degrees dropdown lists depending on the
 * currently selected TTS provider in the panel.
 * @returns JSX: two filtered dropdown lists. One to choose emotion styles and the other to choose emotion degrees.
 */
export default function EmotionSelector() {
    const dispatch = useDispatch();

    // state selector
    const parts = useSelector((state: Selector) => state.parts);
    const selectedPart = useSelector((state: Selector) => state.article.editor.selectedPart);
    const voices = useSelector((state: Selector) => state.voices);

    // Selected voice from voiceSelector.
    const selectedVoice = parts[selectedPart]?.voice;

    // Selected values in dropdown lists.
    const [style, setStyle] = useState('None');
    const [degree, setDegree] = useState('None');

    // Files with possible emotion styles and emotion degrees / intensities.
    const possibleStyles = possibleStylesFile;
    const possibleDegrees = possibleDegreesFile;

    // Mapping possible emotion styles and degrees / intensities according to the TTS
    // of the selected voice (i.e. voice from Microsoft or others.)
    const [stylesToBeRendered, setStylesToBeRendered] = useState([]);
    const [degreesToBeRendered, setDegreesTobeRendered] = useState([]);


    useEffect(() => {
        if (voices.voiceList.length > 0) {
            // Filter to keep only the selected voice and its attributes
            let selectedVoice = voices.voiceList.find(element => element?.name === parts[selectedPart]?.voice?.name)
            setStylesToBeRendered(filterPossibleValues(possibleStyles, parts[selectedPart]?.voice?.TTS, selectedVoice));

            let filteredByTtsStyles = possibleStyles.filter(
                element => element.availableTTS.find(nestedElement => nestedElement === parts[selectedPart]?.voice?.TTS)
            );
            let stylesValues = filteredByTtsStyles.map(a => a.value.toLowerCase());
            // Update emotion style

            if (
                parts[selectedPart] &&
                parts[selectedPart].emotion.emotionStyle.length > 0 &&
                stylesValues.includes(parts[selectedPart].emotion.emotionStyle)
            ) {
                setStyle(parts[selectedPart].emotion.emotionStyle);
                setDegree(parts[selectedPart].emotion.emotionDegree);
            } else {
                setStyle('None');
                setDegree('');
            }

            if (style !== '' && style !== 'None' && parts[selectedPart]?.voice?.TTS === "deepzen") {
                setDegreesTobeRendered(
                    filterPossibleValues(possibleDegrees, parts[selectedPart]?.voice?.TTS, selectedVoice)
                );
            }
            else {
                setDegreesTobeRendered([]);
                setDegree('');
            }
        }
    }, [possibleStyles, possibleDegrees, parts, selectedPart, voices, selectedVoice, style]);

    // Resetting dropdown lists whenever another voice gets selected for this 
    // particular article part.
    useEffect(() => {
        if (selectedVoice?.emotion) {
            setStyle(selectedVoice?.emotions[0].name);
            setDegree('');
        }
    }, [selectedVoice])


    const handleStyleChange = (e: any) => {
        const styleValue = e.target.value
        setStyle(styleValue);

        // Setting a default value for voice emotions when selected. This is mandatory
        // for TTS providers to put emotions in.
        let defaultDegreeValue = "None";
        if (parts[selectedPart]?.voice.TTS === "deepzen") {
            defaultDegreeValue = possibleDegrees[1]?.value;
            setDegree(defaultDegreeValue);
        }
        else if (degree && !styleValue) {
            setDegree(defaultDegreeValue);
        }
        else if (degree) {
            defaultDegreeValue = degree;
            setDegree(defaultDegreeValue);
        };


        const updatedParts: ArticlePart[] = parts.map(p => p.order === selectedPart
            ? {
                ...p,
                emotion: {
                    ...p.emotion,
                    emotionStyle: styleValue !== "None" ? styleValue : '',
                    emotionDegree: defaultDegreeValue
                }
            }
            : p
        )

        dispatch(partsUpdate(updatedParts));
        dispatch(editorPartUpdate(true));
    };

    const handleDegreeChange = (e: any) => {
        const degreeValue = e.target.value;
        setDegree(degreeValue);

        const updatedParts: ArticlePart[] = parts.map(p => p.order === selectedPart
            ? {
                ...p,
                emotion: {
                    ...p.emotion,
                    emotionDegree: degreeValue
                }
            }
            : p
        )

        dispatch(partsUpdate(updatedParts));
        dispatch(editorPartUpdate(true));
    };



    return (
        <>
            {
                stylesToBeRendered?.length > 0 &&
                <>
                    <Box sx={{ textAlign: 'left' }}>
                        <FormControl fullWidth>
                            <InputLabel id="voiceEmotionLabel">Emotion</InputLabel>
                            <Select
                                labelId="voiceEmotionLabel"
                                id="voiceEmotion"
                                value={style}
                                label="Emotion"
                                onChange={handleStyleChange}
                            >
                                {stylesToBeRendered}
                                {
                                    <MenuItem value={"None"} key={"None"}>
                                        <Typography component='div'>{"None"}</Typography>
                                    </MenuItem>
                                }
                            </Select>
                        </FormControl>
                    </Box>
                    {
                        (degreesToBeRendered?.length > 0) &&
                        <Box sx={{ textAlign: 'left', mt: '10px' }}>
                            <FormControl fullWidth>
                                <InputLabel id={"voiceIntensityLabel"}>{"Emotion level"}</InputLabel>
                                <Select
                                    labelId={"voiceIntensityLabel"}
                                    id={"voiceIntensity"}
                                    value={degree}
                                    label={"voice Intensity"}
                                    onChange={handleDegreeChange}
                                    key={"voiceIntensity"}
                                >
                                    {
                                        degreesToBeRendered
                                    }
                                </Select>
                            </FormControl>
                        </Box>
                    }

                </>
            }
        </>
    );
};
