import ReactPlayer from 'react-player'

// player handlers
import {
    handleBufferEnd, handleBuffering, handleDuration, handleEnded, handlePause, handlePlay, handleProgress
} from './playerHandlers';

// interfaces
import { PlayerState } from '../../../interfaces/PlayerState.interface';


interface Props {
    playerState: PlayerState,
    setPlayerState: React.Dispatch<React.SetStateAction<PlayerState>>,
    playerRef?: React.LegacyRef<ReactPlayer> | undefined
}

export const Player = ({ playerState, setPlayerState, playerRef = undefined }: Props) => {
    return (
        <ReactPlayer
            config={{ file: { forceAudio: true } }}
            controls={playerState.controls}
            height={0}
            light={playerState.light}
            loop={playerState.loop}
            muted={playerState.muted}
            onBuffer={() => handleBuffering(setPlayerState)}
            onBufferEnd={() => handleBufferEnd(setPlayerState)}
            onDuration={duration => handleDuration(setPlayerState, duration)}
            onEnded={() => handleEnded(setPlayerState)}
            onError={e => console.error('onError', e)}
            onPause={() => handlePause(setPlayerState)}
            onPlay={() => handlePlay(setPlayerState)}
            onProgress={state => handleProgress(playerState, setPlayerState, state)}
            onReady={() => { }}
            onStart={() => { }}
            playbackRate={playerState.playbackRate}
            playing={playerState.playing}
            progressInterval={500}
            ref={playerRef}
            style={{ display: 'none' }}
            url={playerState.url}
            volume={playerState.volume}
            width={'0'}
        />
    )
}
