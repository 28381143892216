
// mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// custom components
import { SettingsMenu } from "./settingsMenu/SettingsMenu";


export const AppStatusBar = () => {
    const theme = useTheme();

    return (
        <>
            <img
                alt='logo'
                data-test="appbar-img-logo"
                src={
                    theme.palette.mode === 'dark'
                        ? './assets/logo_odia_small_light.png'
                        : './assets/logo_odia_small.png'
                }
                style={{
                    maxHeight: '20px',
                    marginRight: '10px',
                }}
            />

            <Box sx={{ flexGrow: 1 }} />

            <SettingsMenu />
        </>
    )
}
