import { AnyAction } from 'redux';
import { types } from '../actions/actionTypes/actionTypes';
import { UserState } from '../interfaces/Selector.interface';
import { User } from '../interfaces/user/user.interface';

const initialState: UserState = {
    usersList: [],
    clientsList: []
}

export const usersReducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case types.usersRead:
            return {
                ...state,
                usersList: action.payload.data,
                activeUser: undefined
            } as UserState

        case types.usersReadClients:
            return {
                ...state,
                clientsList: action.payload.data,
            } as UserState

        case types.usersActive:
            return {
                ...state,
                usersList: state.usersList,
                activeUser: action.payload.data
            } as UserState

        case types.usersActiveClear:
            return {
                ...state,
                usersList: state.usersList,
                activeUser: undefined
            } as UserState

        case types.usersUpdate:
            return {
                ...state,
                usersList: state.usersList.map(
                    (user: User) => (user.id === action.payload.data.id) ? action.payload.data : user
                ),
                activeUser: undefined
            } as UserState

        case types.usersCreate:
            return {
                ...state,
                usersList: state.usersList,
                activeUser: undefined
            } as UserState

        case types.usersDelete:
            return {
                ...state,
                usersList: state.usersList.filter(
                    (user: User) => (user.id !== action.payload.data.id)
                ),
                activeUser: undefined
            } as UserState

        case types.usersLogout:
            return initialState

        default:
            return state
    }
}