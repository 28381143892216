import { useState, useEffect, useDeferredValue } from 'react';

// modules
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import Flag from 'react-world-flags';

// mui
import {
    Box,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Typography,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FemaleIcon from '@mui/icons-material/Female';
import MaleIcon from '@mui/icons-material/Male';

// styles
import "../../audioEditorPage/styles/country-flags.css";

// data
import { getFlagCode } from '../../../../../data/languageLabels';

// actions
import { loadVoices, thesaurusVoiceUpdate } from '../../../../../actions/voices.actions';

// interfaces
import { Voice } from '../../../../../interfaces/article/Voice.interface';
import { Selector } from "../../../../../interfaces/Selector.interface";


export default function ThesaurusVoiceSelector() {
    const dispatch = useDispatch();

    // state selector
    const parts = useSelector((state: Selector) => state.parts);
    const role = useSelector((state: Selector) => state.auth.role);
    const selectedLanguage = useSelector((state: Selector) => state.article.editor.selectedLanguage);
    const selectedPart = useSelector((state: Selector) => state.article.editor.selectedPart);
    const voiceList = useSelector((state: Selector) => state.voices.voiceList);
    const [loadingVoices, setLoadingVoices] = useState(false);
    const userId = useSelector((state: Selector) => state.auth.uid);
    const deferredUserId = useDeferredValue(userId);
    const [selectedVoice, setSelectedVoice] = useState(parts && parts.length > 0 ? parts.filter(part => part.order === selectedPart)[0].voice : voiceList[0]) as any;
    const [voices, setVoices]: [Voice[], any] = useState([]);

    // effect to load voices on user change
    useEffect(() => {
        if (!loadingVoices && voiceList.length === 0) setLoadingVoices(true);
        // eslint-disable-next-line
    }, [deferredUserId])

    useEffect(() => {
        if (loadingVoices) {
            loadVoices().then(action => {
                dispatch(action);
                setLoadingVoices(false);
            })
        }
        // eslint-disable-next-line
    }, [loadingVoices]);

    const handleVoiceChange = (e: any) => {
        let voiceName = e.target.value

        // get voice default parameters
        let filteredVoice = voiceList.filter((v: any) => v.name === voiceName)[0];

        if (filteredVoice) {
            setSelectedVoice(filteredVoice);
            dispatch(thesaurusVoiceUpdate(filteredVoice.id));
        }
    };


    const handleOptionClick = (voice: any) => {
        let voiceFilter = voiceList.filter((v: any) => v.name === voice.name);
        if (voiceFilter.length === 0) return;
        let {
            name,
            TTS,
            TTSvoice,
            ssmlGender,
            pitch,
            speakingRate,
            description
        } = voiceFilter[0]

        Swal.fire({
            title: name,
            icon: 'info',
            html: `<div style='text-align:left;'>
                <table>
                    ${role === "SuperAdmin" ? `<tr><td><b>TTS</b></td><td>${TTS.charAt(0)?.toUpperCase() + TTS.slice(1)}<td></tr>` : ""}
                    ${role === "SuperAdmin" ? `<tr><td><b>Voice ID</b></td><td>${TTSvoice}<td></tr>` : ""}
                    <tr><td><b>Gender</b></td><td>${ssmlGender}<td></tr>
                    <tr><td><b>Pitch</b></td><td>${pitch}<td></tr>
                    <tr><td><b>Speed rate</b></td><td>${speakingRate}<td></tr>
                    <tr><td style='padding-right:10px;white-space:nowrap'><b>Description</b></td><td>${description || 'No description'}<td></tr>
                </table>        
            </div>
            `,
            padding: '3em',
        })
    }

    useEffect(() => {
        let filteredVoices: Voice[] = voiceList.filter((v: any) => v.language === selectedLanguage && v.enabled);
        setVoices(filteredVoices);
    }, [voiceList, selectedLanguage])


    useEffect(() => {
        const selectedPartVoice = parts && parts.length > 0 ? parts.filter(part => part.order === selectedPart)[0].voice : voices[0];

        if (selectedPartVoice && selectedPartVoice.id !== selectedVoice.id) {
            setSelectedVoice(selectedPartVoice);
            dispatch(thesaurusVoiceUpdate(selectedVoice.id));
        }

    },
        // eslint-disable-next-line
        [dispatch, selectedPart, parts, selectedLanguage])


    return (
        <Box sx={{ textAlign: 'left' }}>
            <FormControl fullWidth>
                <InputLabel id="lexiconVoiceSelectorLabel">Voice</InputLabel>
                <Select
                    id="lexiconVoiceSelector"
                    data-test="lexiconvoice-selector"
                    label="Voices"
                    labelId="lexiconVoiceSelectorLabel"
                    onChange={handleVoiceChange}
                    value={selectedVoice?.name || ""}
                >
                    {
                        voices.map(voice => {
                            return <MenuItem value={voice.name} key={voice.name}>
                                <Grid container>
                                    <Grid item xs={10} sx={{
                                        display: 'flex',
                                        flexDirection: "row"
                                    }}>
                                        <Flag
                                            id="flag-img-voice"
                                            code={getFlagCode(voice.language)}
                                            fallback={<span></span>}
                                        />
                                        {
                                            voice.ssmlGender === "MALE"
                                                ? <MaleIcon sx={{
                                                    color: '#CCC',
                                                    marginRight: "5px"
                                                }} />
                                                : <FemaleIcon sx={{
                                                    color: '#CCC',
                                                    marginRight: "5px"
                                                }} />
                                        }
                                        <Typography
                                            component='div'
                                            sx={
                                                voice.language !== selectedLanguage
                                                    ? {
                                                        color: 'rgba(0, 0, 0, 0.5)',
                                                        fontStyle: 'italic',
                                                    }
                                                    : {}
                                            }>
                                            {voice.name}&nbsp;({voice.TTS?.toUpperCase().slice(0, 1)})
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <VisibilityIcon
                                            onClick={(e) => handleOptionClick(voice)}
                                            sx={{
                                                color: '#CCC',
                                                '&:hover': {
                                                    color: '#e91e63'
                                                }
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </MenuItem>
                        })
                    }
                </Select>
            </FormControl>
        </Box >
    );
}
