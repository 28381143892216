import { useState, useEffect } from 'react';

// modules
import { useDispatch, useSelector } from 'react-redux';

// mui
import { LoadingButton } from '@mui/lab';
import useMediaQuery from '@mui/material/useMediaQuery';
import AudioFileIcon from '@mui/icons-material/AudioFile';
import ButtonGroup from '@mui/material/ButtonGroup';

// actions
import { showNotPermanentErrorSnackBar, showPermanentSuccessSnackBar } from "../../../../../actions/snackBar.actions";

// helpers
import api from "../../../../../helpers/services/api";

// interfaces
import { Selector } from "../../../../../interfaces/Selector.interface";
import { VideoGenerationBody } from '../../../../../interfaces/services/api.interfaces';
import { newArticleVideoGenerated } from '../../../../../actions/socket.actions';
import { store } from '../../../../../store/store';


export const VideoActionButtons = () => {
    const dispatch = useDispatch();

    // state selector
    const activeArticle = useSelector((state: Selector) => state.article.activeArticle);
    const video = useSelector((state: Selector) => state.video);
    const socketVideoGenerated = useSelector((state: Selector) => state.socket.newArticleVideoGeneration);

    const { imageGenerator, imageStyle, imageCount, publisherName, publisherLogoUrl, imageUrlList } = video;

    const [isGenerating, setIsGenerating] = useState(false);
    const [generationOptionValid, setGenerationOptionValid] = useState(false);

    const matches = useMediaQuery("(min-width:1200px)");


    useEffect(() => {
        if (((imageGenerator && imageGenerator !== 'None' && imageCount > 0) || (imageUrlList.length > 0))
            && publisherName && publisherName !== '' && publisherLogoUrl && publisherLogoUrl !== '') {
            setGenerationOptionValid(true);
        }
        else {
            setGenerationOptionValid(false);
        }

        // eslint-disable-next-line
    }, [imageGenerator, imageCount, publisherName, publisherLogoUrl, imageUrlList])


    // effect to handle socket video generation notification
    useEffect(() => {
        if (socketVideoGenerated > 0 && socketVideoGenerated === activeArticle.id) {
            dispatch(showPermanentSuccessSnackBar("Video generated successfully"));
            setIsGenerating(false);

            // reset socket audio generation flag
            dispatch(newArticleVideoGenerated(-1));
        }

        // eslint-disable-next-line
    }, [socketVideoGenerated])


    // trigger video generation
    const handleGenerateVideo = async () => {
        setIsGenerating(true);

        let fileId = -1;
        if (activeArticle.id) fileId = activeArticle.id;

        const body: VideoGenerationBody = {
            publisherName: publisherName,
            publisherLogoUrl: publisherLogoUrl,
            nbImages: imageCount,
            imageGenerator: (!imageGenerator || imageGenerator === 'None') ? null : imageGenerator.toLowerCase(),
            style: imageStyle,
            illustrationList: imageUrlList
        }

        const video = await api.video.generateVideo(fileId, body);
        if (video.status >= 400) {
            setIsGenerating(false);
            const body = await video.json();
            store.dispatch(showNotPermanentErrorSnackBar(body.error))
            throw body.error;
        }
    };


    return (
        <ButtonGroup
            variant="outlined"
            aria-label="outlined primary button group"
            orientation={`${matches ? `horizontal` : `vertical`}`}
        >
            {
                activeArticle && activeArticle.id > -1 &&
                <LoadingButton
                    data-test="videoactionbuttons-btn-generate"
                    size="small"
                    startIcon={<AudioFileIcon fontSize="small" />}
                    onClick={handleGenerateVideo}
                    disabled={
                        !generationOptionValid ||
                        isGenerating
                    }
                    variant="outlined"
                    loading={isGenerating}
                    loadingPosition="start"
                >
                    {isGenerating ? "Generating..." : "Generate"}
                </LoadingButton>
            }

        </ButtonGroup>
    )
}
