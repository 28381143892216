
// mui
import { Box, SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";

interface Props {
    Icon: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
        muiName: string;
    },
    text: string
}

export const ParentItemLabel = ({ Icon, text }: Props) => {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Icon />
            &nbsp;
            {text}
        </Box>
    )
}
