import { AnyAction } from "redux";

// types
import { types } from "./actionTypes/actionTypes";

// action creator
import { actionCreator } from "./actionCreator/actionCreator";


// Permanent snackBars have no auto hide duration. 
// Others, have 3500 miliseconds.
// Information snackBars are blue. Warning snackBars are orange. 
// Error snackBars are red. Success snackBars are green.

export const showPermanentSuccessSnackBar = (message: string): AnyAction => {
    return actionCreator(types.snackBarShowPermanentSuccess, message)
};

export const showNotPermanentSuccessSnackBar = (message: string): AnyAction => {
    return actionCreator(types.snackBarShowNotPermanentSuccess, message)
};

export const showPermanentErrorSnackBar = (message: string): AnyAction => {
    return actionCreator(types.snackBarShowPermanentError, message)
};

export const showNotPermanentErrorSnackBar = (message: string): AnyAction => {
    return actionCreator(types.snackBarShowNotPermanentError, message)
};

export const showInformationSnackBar = (message: string): AnyAction => {
    return actionCreator(types.snackBarShowInformation, message)
};

export const showWarningSnackBar = (message: string): AnyAction => {
    return actionCreator(types.snackBarShowWarning, message)
};

export const hideSnackBar = (): AnyAction => {
    return actionCreator(types.snackBarHide)
};

export const snackBarLogout = (): AnyAction => {
    return actionCreator(types.snackBarLogout)
}
