import { StreamState } from '../interfaces/StreamState.interface';

export const getStreamDefaultState = (language: string) => {
    const defaultState: StreamState = {
        enabled: true,
        config: [],
        voices: [],
        parts: [],
        selectedPart: 1,
        selectedLanguage: language
    }
    return defaultState;
}
