import { useSelector } from "react-redux";
import { AudioActionButton } from "../AudioActionButton";
import { Selector } from "../../../../../../../interfaces/Selector.interface";
import DownloadIcon from "@mui/icons-material/Download";

export const AudioActionButtonDownload = () => {

    const article = useSelector((state: Selector) => state.article);

    const { activeArticle, editor } = article;

    return <AudioActionButton
        data_test="audioactionbuttons-btn-download"
        title={"Download the article"}
        icon={<DownloadIcon fontSize="small" />}
        href={editor.audioFile}
        download={`${activeArticle.fileName || 'article'}.mp3`}
        name={"Download"}
    />
}