/**
 * @function
 * Used to sanitize input lexicon values to fix reserved regEx characters (metacharacters)
 * 
 * @param {String} lexicon: Input lexicon value to be sanitized
 * @returns {String}: Sanitized text fixed for metacharacters: ".", "*", "+", "?", "^", "$", "(", ")", "[", "]", "{", "}", "|"
 */
export const sanitizeLexicon = (lexicon: string) : string => {
    // fix the reserved regEx characters adding a backslash
    // i.e. if lexicon is "tv+", the regex fix will be "tv\+"
    return lexicon.replace(/([.*+?^$()[\]{}|])/g, "\\$1");
}
