import { Socket, io } from 'socket.io-client';

// store
import { store } from '../../store/store';

// actions
import { articleInfosUpdated, newArticleAudioGenerated, newArticleVideoGenerated } from '../../actions/socket.actions';


const baseUrl: string | undefined = process.env.REACT_APP_API_URL;


const updateArticleInfos = (data: number) => {
    store.dispatch(articleInfosUpdated(data));
}

const updateArticleAudioGenerationStatus = (data: number) => {
    store.dispatch(newArticleAudioGenerated(data))
}

const updateArticleVideoGenerationStatus = (data: number) => {
    store.dispatch(newArticleVideoGenerated(data))
}


export const createSocketClient = (): Socket | undefined => {
    if (baseUrl) {
        const token = localStorage.getItem('token');
        const socket: Socket = io(baseUrl, {auth: {token}});

        socket?.on('articleInfosUpdated', updateArticleInfos);

        socket?.on('articleAudioGenerated', updateArticleAudioGenerationStatus);

        socket?.on('articleVideoGenerated', updateArticleVideoGenerationStatus);

        return socket;
    }
    return undefined;
};

export const updateSocketUser = (socket: Socket | undefined, token: string): Socket | undefined => {
    if (baseUrl) {
        socket?.disconnect();
        const newSocket = io(baseUrl, { auth: { token } });

        newSocket?.on('articleInfosUpdated', updateArticleInfos);

        newSocket?.on('articleAudioGenerated', updateArticleAudioGenerationStatus);

        newSocket?.on('articleVideoGenerated', updateArticleVideoGenerationStatus);

        return newSocket;
    }
    return undefined;
};


export const destroySocketClient = (socket: Socket) => {
    socket.disconnect();
};