// interfaces
import { ArticlePart, ArticleSectionType } from "../../interfaces/article/ArticlePart.interface"
import { Language } from "../../interfaces/article/Language.interface"
import { Voice } from "../../interfaces/article/Voice.interface"
import { defaultLanguage } from "../../state/defaultLanguage"

/**
 * Generate a new article part
 * @param id {number} - The index of the part
 * @param voice {Voice} - The voice of the part
 * @param content {string} - The content of the part
 * @param type {string} - The type of the part
 * @param language {Language} - The language of the part
 * @return {ArticlePart} - The generated article part
 */
export const generatePart = (id: number, voice: Voice, content: string, type: ArticleSectionType, language?: Language | undefined): ArticlePart => {
    return {
        id,
        content,
        subtitleText: null,
        contentHtml: content,
        contentHtmlOriginal: content,
        emotion: { emotionStyle: "", emotionDegree: "NONE" },
        hasSyntaxError: false,
        order: id,
        pauses: [],
        prosodyContour: '1',
        prosodyPitch: 1,
        prosodyRate: 1,
        prosodyVolume: 1,
        replacementTexts: [],
        voice,
        visible: true,
        type,
        language: language ? language : defaultLanguage
    }
}
