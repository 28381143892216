
// modules
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";

// components
import { AudioActionButton } from "../AudioActionButton";

// mui
import CancelScheduleSendIcon from "@mui/icons-material/CancelScheduleSend";

// interfaces
import { Article } from "../../../../../../../interfaces/article/Article.interface";
import { Selector } from "../../../../../../../interfaces/Selector.interface";

// helpers & actions 
import { unpublishArticle } from "../../../../../../../helpers/articles/unpublishArticle";
import { setActiveArticle } from "../../../../../../../actions/articles.actions";


export const AudioActionButtonUnpublish = () => {
    const dispatch = useDispatch();

    const article = useSelector((state: Selector) => state.article);
    const { activeArticle, editor } = article;


    const handleUnpublishArticle = () => {
        Swal.fire({
            title: 'Are you sure you want to unpublish this article?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel'
        }).then(result => {
            if (result.isConfirmed && editor.audioFile.length > 0) {
                const articleBeforeUpdate: Article = { ...activeArticle };
                const articleId = activeArticle.id || -1;
                const status = 'DRAFT';

                unpublishArticle(articleId)
                    .then(() => {
                        dispatch(setActiveArticle({
                            ...articleBeforeUpdate,
                            status
                        }));
                    })
                    .catch(error => {
                        console.error(error);
                    })
            }
        })
    }

    return <AudioActionButton
        data_test="audioactionbuttons-btn-unpublish"
        icon={<CancelScheduleSendIcon fontSize="small" />}
        onClick={handleUnpublishArticle}
        title={"Unpublish the article"}
        color="warning"
        name="Unpublish"
    />
}