// react
import React, { useState, useEffect } from "react";

// redux
import { useDispatch, useSelector } from 'react-redux';

// mui
import { Box, IconButton, Divider, TextField, Popover, useTheme, FormGroup, Typography } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

// interfaces
import { Selector } from "../../../../../interfaces/Selector.interface";
import { sidebarSetAudioFilesPublishedDays } from "../../../../../actions/sidebar.actions";
import { ThesaurusItem } from "./thesaurusItem/ThesaurusItem";
import { Article } from "../../../../../interfaces/article/Article.interface";


export const ThesaurusTab = React.memo(() => {
  const theme = useTheme();
  const dispatch = useDispatch();

  // state selector
  const audioFilesPublishedDaysOld = useSelector((state: Selector) => state.sidebar.audioFilesPublishedDaysOld);
  const files = useSelector((state: Selector) => state.files);

  const [draftArticlesList, setDraftArticlesList] = useState([] as Article[]);
  const [publishedArticlesList, setPublishedArticlesList] = useState([] as Article[]);
  const [publishedDaysOld, setPublishedDaysOld] = useState(audioFilesPublishedDaysOld);

  const [popoverAnchorEl, setPopoverAnchorEl] = useState<HTMLButtonElement | null>(null);


  const handlePublishedFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setPopoverAnchorEl(event.currentTarget);
  };

  const handleTextFieldChange = (event: any) => {
    setPublishedDaysOld(parseInt(event.target.value))
  }

  const handlePublishedFilterClose = () => {
    dispatch(sidebarSetAudioFilesPublishedDays(publishedDaysOld));
    setPopoverAnchorEl(null);
  };

  const openPopover = Boolean(popoverAnchorEl);

  useEffect(() => {
    const draftArticleList = files.filter(file => (file.status === 'PENDING' || file.status === 'DRAFT'));
    const publishedArticleList = files.filter(file => (file.status === 'PUBLISHED'));

    setDraftArticlesList(draftArticleList);
    setPublishedArticlesList(publishedArticleList);
  }, [files])


  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 2 }}>
        <Typography
          data-test="lexicontab-label-unpublished"
          variant="h6"
        >
          Unpublished articles
        </Typography>
      </Box>

      <Divider sx={{ my: theme.spacing(1) }} />

      <FormGroup>
        {
          draftArticlesList.map(item => (
            <ThesaurusItem key={`thesaurus-file-${item.id}`} item={item} showArticleName={false} />
          ))
        }
      </FormGroup>

      <Divider sx={{ my: theme.spacing(1) }} />

      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 2 }}>
        <Typography
          data-test="lexicontab-label-published"
          variant="h6"
        >
          Published articles
        </Typography>

        <IconButton
          aria-label="btn-filter-published"
          onClick={handlePublishedFilterClick}
        >
          <Typography variant="caption">Date filter</Typography>
          <FilterAltIcon />
        </IconButton>
      </Box>

      <Divider sx={{ my: theme.spacing(2) }} />

      <FormGroup>
        {
          publishedArticlesList.map(item => (
            <ThesaurusItem key={`thesaurus-file-${item.id}`} item={item} showArticleName={false} />
          ))
        }
      </FormGroup>

      <Popover
        open={openPopover}
        anchorEl={popoverAnchorEl}
        onClose={handlePublishedFilterClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box sx={{
          p: 2,
          display: "flex",
          flexDirection: "row",
          justifyContent: "center"
        }}>
          <TextField
            label="Filter published days old"
            variant="outlined"
            type="number"
            inputProps={{ min: 1 }}
            value={publishedDaysOld}
            onChange={handleTextFieldChange}
          />
          <IconButton onClick={handlePublishedFilterClose}>
            <CheckIcon />
          </IconButton>
        </Box>
      </Popover>

    </Box>
  )
})
