import React from 'react';

// modules
import Flag from "react-world-flags";
import { useDispatch, useSelector } from 'react-redux';

// components
import { ArticleSectionText } from './articleSectionText/ArticleSectionText';
import { ArticleSectionButtons } from './articleSectionMenu/ArticleSectionButtons';

// mui
import { useTheme } from '@mui/material/styles';
import { Grid, MenuItem, Select, Stack } from '@mui/material';

// interfaces
import { ArticlePart, ArticleSectionType } from '../../../../../interfaces/article/ArticlePart.interface'
import { Selector } from '../../../../../interfaces/Selector.interface';
import { PlayerState } from '../../../../../interfaces/PlayerState.interface';

// styles
import { PartGrid } from '../styles/EditorPageStyle'

// actions
import { partsUpdate } from '../../../../../actions/parts.actions';
import { editorSetActivePart } from '../../../../../actions/articles.actions';
import { capitalizeFirstLetter } from '../../../../../helpers/capitalizeFirstLetter';
import { getFlagCode } from '../../../../../data/languageLabels';
import { showPopupSectionLanguage } from '../../../../../actions/popup.action';


interface Props {
    part: ArticlePart,
    activePartState: number,
    streamEnabled: boolean,
    setPlayerState: React.Dispatch<React.SetStateAction<PlayerState>>
}

export const ArticleSection = ({ part, activePartState, streamEnabled, setPlayerState }: Props) => {
    const theme = useTheme();

    const dispatch = useDispatch();

    // state selector
    const articleParts = useSelector((state: Selector) => state.parts);
    const activeArticle = useSelector((state: Selector) => state.article.activeArticle);


    const handlePartTypeChange = (e: any, partIndex: number) => {
        let parts = [...articleParts];
        parts[partIndex] = {
            ...parts[partIndex],
            type: e.target.value?.toUpperCase()
        }
        dispatch(partsUpdate([...parts]));
    }

    const handleSelectArticlePart = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, order: number) => {
        if (activePartState !== order) {
            dispatch(editorSetActivePart(order));
        }
    }


    return (
        <Grid
            item
            key={`sectionstack-${part.order}`}
            data-test={`section-component-${part.order}`}
            xs={12}
            onClick={e => handleSelectArticlePart(e, part.order)}
        >
            <Grid container>
                <PartGrid
                    item
                    xs={1.5}
                    sx={{
                        backgroundColor:
                            (activePartState === part.order)
                                ? theme.palette.primary.light
                                : 'inherit',
                        color: theme.palette.primary.dark
                    }}
                >
                    <Grid container>
                        <Grid
                            item
                            data-test={`sectionnumber-label-${part.order}`}
                            xs={3}
                            sx={{ padding: 0, alignContent: 'start' }}
                        >
                            {part.order + 1}
                        </Grid>
                        <Grid
                            item
                            data-test={`sectionvoice-label-${part.order}`}
                            xs={9}
                        >
                            <Stack
                                direction='row'
                                sx={{ display: 'flex', justifyContent: 'space-between', alignContent: 'left' }}
                            >
                                {part?.voice?.name}
                                {
                                    part?.language.code !== activeArticle.language.code &&
                                    <Flag
                                        id="flag-img-language"
                                        data-test={`sectionvoice-langflag-${part.order}`}
                                        code={getFlagCode(part?.language.code)}
                                        style={{ marginRight: 5, cursor: 'pointer' }}
                                        onClick={() => { dispatch(showPopupSectionLanguage()); }}
                                    />
                                }
                            </Stack>
                        </Grid>
                    </Grid>

                </PartGrid>

                <PartGrid item xs={8}>
                    <ArticleSectionText
                        key={`section-${part.order}`}
                        part={part}
                        streamEnabled={streamEnabled}
                        setPlayerState={setPlayerState}
                    />
                </PartGrid>

                <PartGrid item xs={1.5}>
                    <Select
                        id="partTypeSelector"
                        key={`partTypeSelector-${part.order}`}
                        data-test={`sectiontype-select-${part.order}`}
                        label=""
                        labelId="PartTypeSelectorLabel"
                        value={part?.type?.toLowerCase()}
                        onChange={e => handlePartTypeChange(e, part.order)}
                        sx={{
                            fontSize: 12,
                            height: 28,
                            width: '100%',
                        }}
                    >
                        {
                            Object.values(ArticleSectionType).map(type => {
                                return <MenuItem
                                    data-test={`section-${part.order}-type-${type.toLowerCase()}`}
                                    value={type.toLowerCase()}
                                    key={type.toLowerCase()}
                                >
                                    {capitalizeFirstLetter(type.toLowerCase())}
                                </MenuItem>
                            })
                        }
                    </Select>
                </PartGrid>

                <PartGrid item xs={1} sx={{ pt: 0.2 }}>
                    <ArticleSectionButtons part={part} />
                </PartGrid>

            </Grid>
        </Grid>
    )
}
