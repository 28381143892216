// mui
import { Box, CircularProgress } from '@mui/material';
import MusicNoteIcon from '@mui/icons-material/MusicNote';

// interfaces
import { Article } from '../../../../../../../interfaces/article/Article.interface';


interface Props {
    item: Article
}

export const ItemIcon = ({ item }: Props) => {
    return (
        item.audioMediaFile?.generationStatus === 'GENERATING'
            ? <Box><CircularProgress size={12} color='inherit' /></Box>
            : item.status === 'DRAFT' || item.status === 'PUBLISHED'
                ? <MusicNoteIcon />
                : <></>
    )
}
