// components
import Header from '../../editorScreen/header/Header';
import { UserTable } from './userTable/UserTable';
import { UserButtonNew } from './userButtonNew/UserButtonNew';

// mui
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';


export const UserScreen = () => {
    return (
        <>
            <CssBaseline />
            <Container maxWidth="lg">
                <Header title="User management" />
                <UserTable />
                <UserButtonNew />
            </Container>
        </>
    )
}
