import { Link } from 'react-router-dom';

// mui
import { Box, Button } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';


export const UserButtonNew = () => {
    return (
        <Box textAlign='center'>
            <Button
                color="primary"
                component={Link}
                startIcon={<AddCircleOutlineIcon />}
                sx={{ my: 8 }}
                to={'/users/edit/0'}
                variant="outlined"
            >
                Create User
            </Button>
        </Box>
    )
}
