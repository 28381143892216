import { AnyAction } from "redux";

// helpers
import api from "../helpers/services/api";

// actions
import { types } from './actionTypes/actionTypes';
import { actionCreator } from "./actionCreator/actionCreator";

// interfaces
import { Voice } from "../interfaces/article/Voice.interface";


export const loadVoices = async (): Promise<AnyAction> => {
    try {
        const response = await api.voice.getAll();
        let voiceList: Voice[] = await response.json();

        // set visible param
        voiceList = voiceList.map(v => ({ ...v, visible: true }))

        // sort the voices by order
        voiceList.sort((voice1, voice2) => voice1.order - voice2.order);

        return actionCreator(types.voicesLoad, voiceList)

    } catch (error) {
        const errorMsg = 'Errors in loadVoices action'
        console.error(errorMsg, error)
        throw new Error(errorMsg)
    }
}

export const updateVoiceList = (voiceList: Voice[]): AnyAction => {
    return actionCreator(types.voicesUpdate, voiceList)
}


export const voicesLogout = (): AnyAction => {
    return actionCreator(types.voicesLogout)
}

export const thesaurusVoiceUpdate = (thesaurusVoiceId: number): AnyAction => {
    return actionCreator(types.thesaurusVoiceUpdate, thesaurusVoiceId)
}
