import { Article } from "../interfaces/article/Article.interface";
import { Language } from "../interfaces/article/Language.interface";

export class ArticleLanguage {
    article: Article;

    constructor(article: Article) {
        this.article = article;
    }

    getArticle(): Article {
        return this.article;
    }

    getLanguage(): Language | null {
        return this.article.language
    }
}

export class ArticleVersion {
    articleLanguages: ArticleLanguage[] = [];

    constructor(articleLanguages: Article[]) {
        this.articleLanguages = [] as ArticleLanguage[];
        articleLanguages.forEach((article) => {
            this.articleLanguages.push(new ArticleLanguage(article));
        });
    }

    getArticleLanguages(): ArticleLanguage[] {
        return this.articleLanguages;
    }
}

export class ArticleSet {
    articlesVersions: ArticleVersion[] = [];

    constructor(articles: Article[][]) {
        this.articlesVersions = articles.map(article => new ArticleVersion(article));
    }

    getArticlesByLanguage(isoCode: string): Article[] {
        const articlesByLanguage = [] as Article[];
        this.articlesVersions.forEach(articleVersion => {
            const found = articleVersion.articleLanguages.find(articleLanguage => articleLanguage.getLanguage()?.code === isoCode);
            if (found) {
                articlesByLanguage.push(found.article);
            }
        });
        return articlesByLanguage;
    }

    getFilename(): string | undefined {
        if (this.articlesVersions.length === 0 || this.articlesVersions[0].articleLanguages.length === 0)
            return undefined;
        return this.articlesVersions[0].articleLanguages[0].article.fileName;
    }

    getFileId(): number | undefined {
        if (this.articlesVersions.length === 0 || this.articlesVersions[0].articleLanguages.length === 0)
            return undefined;
        return this.articlesVersions[0].articleLanguages[0].article.id;
    }

    getFirstArticle(): Article | undefined {
        if (this.articlesVersions.length === 0 || this.articlesVersions[0].articleLanguages.length === 0)
            return undefined;
        return this.articlesVersions[0].articleLanguages[0].article;
    }

    getNumberOfLanguages(): number {
        if (this.articlesVersions.length > 0) {
            let languages: string[] = [];
            this.articlesVersions.forEach(articleVersion => {
                let articleVersionLanguages = articleVersion.getArticleLanguages();
                for (let articleLanguage of articleVersionLanguages) {
                    const languageCode = articleLanguage.getLanguage()?.code || '';
                    if (!languages.includes(languageCode)) languages.push(languageCode)
                }
            })
            return languages.length;
        }
        return 0;
    }
}

const groupArticleByNames = (articleList: Article[]): Article[][] => {
    if (articleList.length === 0)
        return [[]];

    let articleMapByVersion: Map<string, Article[]> = new Map<string, Article[]>();
    for (let article of articleList) {
        if (!articleMapByVersion.has(article.fileName)) {
            articleMapByVersion.set(article.fileName, [])
        }

        articleMapByVersion.get(article.fileName)?.push(article);
    }

    let articlesByVersion: Article[][] = [];
    articleMapByVersion.forEach((articleList: Article[]) => {
        articlesByVersion.push(articleList);
    });

    return articlesByVersion;
}

// return a language list of version list of articles
// In other words : articles[article][version][language]
const groupArticlesByVersions = (list: Article[][]) => {
    if (list.length === 0 || list[0].length === 0)
        return [[[]]] as Article[][][];

    let articlesByVersions: Article[][][] = [];
    for (let articleList of list) {
        let articleMapByOriginalId: Map<number, Article[]> = new Map<number, Article[]>();
        for (let article of articleList) {
            if (article.originalArticleId && article.originalArticleId > 0) {
                if (!articleMapByOriginalId.has(article.originalArticleId)) {
                    articleMapByOriginalId.set(article.originalArticleId, [])
                }
                articleMapByOriginalId.get(article.originalArticleId)?.push(article);
            }
            else {
                if (!articleMapByOriginalId.has(article.id)) {
                    articleMapByOriginalId.set(article.id, [])
                }
                articleMapByOriginalId.get(article.id)?.push(article);
            }
        }

        let articlesByVersion: Article[][] = [];
        articleMapByOriginalId.forEach((articleList: Article[]) => articlesByVersion.push(articleList));

        articlesByVersions.push(articlesByVersion)
    }

    return articlesByVersions;
}

export function getArticlesAsSets(articles: Article[]): ArticleSet[] {
    const articlesWithLanguages = groupArticleByNames(articles);
    const articlesWithVersions = groupArticlesByVersions(articlesWithLanguages);

    const articleSets = [] as ArticleSet[];
    articlesWithVersions.forEach(arr => articleSets.push(new ArticleSet(arr)));

    return articleSets;
}