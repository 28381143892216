// modules
import { useDispatch, useSelector } from 'react-redux';

// mui
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

// components

// interfaces
import { Selector } from '../../../../../interfaces/Selector.interface';
import { Button, CircularProgress, Grid, IconButton, Input, Stack, Switch, TextField } from "@mui/material";
import { FormControlLabelSwitch } from "../../../editorScreen/audioEditorPage/styles/EditorPageStyle";
import { useEffect, useState } from "react";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import {
    deleteBackground,
    loadConfig,
    saveBackground
} from "../../../../../actions/config.actions";
import Swal from "sweetalert2";
import 'sweetalert2/dist/sweetalert2.min.css'
import '../../../../../theme/swal2.css'
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import {
    handlePlayPauseBackgrounds,
} from "../../../../generics/player/playerHandlers";
import { getStreamDefaultState } from "../../../../../state/streamDefaultState";
import { playerDefaultState } from "../../../../../state/playerDefaultState";
import { Player } from "../../../../generics/player/Player";
import api from "../../../../../helpers/services/api";
import { Background } from "../../../../../models/background.model";
import { loadSoundEffects } from "../../../../../actions/soundEffects.actions";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 5,
    borderRadius: 10,
    overflowY: "auto",
    maxHeight: "90%",
};

interface Props {
    modalState: boolean,
    handleModalClose: () => void,
    modalSelectedItem: number
}

export const BackgroundModal = ({ modalState, handleModalClose, modalSelectedItem }: Props) => {
    const dispatch = useDispatch();

    // state selector
    const config = useSelector((state: Selector) => state.config.config);
    const editor = useSelector((state: Selector) => state.article.editor);

    const [backgroundsStateList, setBackgroundsStateList] = useState<Background[]>(
        config.filter(c => c.name.toLowerCase() === 'backgrounds')[0].data as Background[]
    );
    const [selectedBackground, setSelectedBackground] = useState<Background | undefined>(
        backgroundsStateList.filter(l => l.id === modalSelectedItem)[0]
    );
    const [backgroundName, setBackgroundName] = useState<string>(selectedBackground?.name || '');
    const [backgroundDefaultVolume, setBackgroundDefaultVolume] = useState<number>(selectedBackground?.defaultVolume || 1);
    const [backgroundFadeOut, setBackgroundFadeOut] = useState<boolean>(selectedBackground?.fadeOut || false);
    const [backgroundUrl, setBackgroundUrl] = useState<string>(selectedBackground?.url || '');
    const [backgroundFile, setBackgroundFile] = useState<File | undefined>(undefined);
    const [Playing, setPlaying] = useState(false);
    const [streamState] = useState(getStreamDefaultState(editor.selectedLanguage));
    const [playerState, setPlayerState] = useState({ ...playerDefaultState });

    useEffect((() => {
        setBackgroundsStateList(config?.filter(element => element.name === "Backgrounds")[0]?.data as Background[]);
    }), [config])

    useEffect((() => {
        const background = backgroundsStateList.filter(l => l.id === modalSelectedItem)[0];
        setSelectedBackground(background);
        setBackgroundName(background?.name || '');
        setBackgroundDefaultVolume(background?.defaultVolume || 1);
        setBackgroundFadeOut(background?.fadeOut || false);
        setBackgroundUrl(background?.url || '');
    }), [backgroundsStateList, modalSelectedItem])

    const handleSaveBackground = async () => {
        const background: Background = {
            id: selectedBackground?.id || 0,
            name: backgroundName,
            defaultVolume: backgroundDefaultVolume,
            fadeOut: backgroundFadeOut,
            url: backgroundUrl,
        }

        if (background.name === '') {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Background name cannot be empty!',
            })
        } else {
            if (backgroundFile) {
                const bodyFormData = new FormData();
                bodyFormData.append('file', backgroundFile);
                const result = await api.background.upload(bodyFormData)
                background.url = await result.text();
            }

            saveBackground(background).then(result => {
                dispatch(result)
                loadConfig().then(action => dispatch(action));
                loadSoundEffects().then(action => dispatch(action));
                handleModalClose();
            })
        }
    }

    const handleDeleteBackground = () => {
        const background: Background = backgroundsStateList.filter(l => l.id === modalSelectedItem)[0];

        if (background) {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                confirmButtonText: 'Delete',
                showCancelButton: true,

            }).then((result) => {
                if (result.isConfirmed) {
                    deleteBackground(background.id).then(result => {
                        dispatch(result)
                        loadConfig().then(action => dispatch(action));
                        handleModalClose();
                    })
                }
            })
        }
    }

    const handlePlayPauseBackground = () => {
        handlePlayPauseBackgrounds(backgroundUrl, streamState, setPlayerState)
        setPlaying(true);
    }

    const changeBackground = (audioFile: File) => {
        setBackgroundFile(audioFile);
        const blob = new Blob([audioFile], { type: 'audio/mp3' });
        const audioUrl = URL.createObjectURL(blob);
        setBackgroundUrl(audioUrl);
    }

    return (
        <Modal
            open={modalState}
            onClose={handleModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                {
                    <>
                        <Player playerState={playerState} setPlayerState={setPlayerState} />
                        <form encType="multipart/form-data">
                            <Grid item xs={12} sx={{ mt: 2 }}>
                                <Stack direction='column' spacing={1}
                                    sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <TextField
                                        fullWidth
                                        label="Backgrounde Name"
                                        size="small"
                                        variant="standard"
                                        id="background_name"
                                        name={"background name"}
                                        value={backgroundName}
                                        onChange={(e: any) => setBackgroundName(e.target.value)} />

                                    <TextField
                                        fullWidth
                                        label="Background Volume"
                                        size="small"
                                        variant="standard"
                                        id="background_volume"
                                        name={"background volume"}
                                        value={backgroundDefaultVolume}
                                        onChange={(e: any) => setBackgroundDefaultVolume(e.target.value)} />

                                    <FormControlLabelSwitch
                                        control={<Switch
                                            checked={backgroundFadeOut}
                                            onChange={() => setBackgroundFadeOut(!backgroundFadeOut)}
                                            inputProps={{ 'aria-label': 'controlled' }} />}
                                        label="Fade Out"
                                        labelPlacement="end" />

                                    <Stack direction='row' spacing={1} sx={{ display: 'flex', justifyContent: 'space-between' }}>

                                        <Input
                                            type="file"
                                            id="background_file"
                                            name="background_file"
                                            onChange={res => {
                                                const target = res.target as HTMLInputElement;
                                                const file: File = (target.files as FileList)[0];
                                                changeBackground(file);
                                            }}
                                        />

                                        <IconButton
                                            aria-label="startPause"
                                            onClick={() =>
                                                handlePlayPauseBackground()
                                            }
                                            color='primary'
                                            disabled={
                                                (backgroundUrl === '')
                                                ||
                                                (
                                                    playerState.playing &&
                                                    (!playerState.duration || playerState.isBuffering))
                                            }
                                            sx={{ fontSize: 32, mt: 1 }}
                                        >
                                            {
                                                Playing && playerState.playing &&
                                                    (!playerState.duration || playerState.isBuffering)
                                                    ?
                                                    <CircularProgress color='secondary' size={32} />
                                                    :
                                                    Playing && playerState.playing
                                                        ? <PauseCircleIcon fontSize='inherit' />
                                                        : <PlayCircleFilledWhiteIcon fontSize='inherit' />
                                            }
                                        </IconButton>
                                    </Stack>
                                </Stack>
                            </Grid>
                        </form>
                        <Grid item xs={12} sx={{ marginTop: '15px' }}>
                            <Button
                                size="medium"
                                startIcon={<SaveIcon fontSize="medium" />}
                                onClick={handleSaveBackground}
                            >
                                Save
                            </Button>
                            <Button
                                size="medium"
                                startIcon={<DeleteIcon fontSize="medium" />}
                                onClick={handleDeleteBackground}
                            >
                                Delete
                            </Button>
                        </Grid>
                    </>
                }
            </Box>
        </Modal>
    );
}
