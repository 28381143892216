import { types } from '../actions/actionTypes/actionTypes'

const initialState = {
    checking: true,
    role: 'USER'
}

export const authReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case types.authLogin:
            return {
                ...state,
                ...action.payload.data,
                checking: false
            }

        case types.authCheckingFinish:
            return {
                ...state,
                checking: false
            }

        case types.authLogout:
            return {
                checking: false
            }

        default:
            return state
    }
}