import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import api from '../../../../../helpers/services/api';

// components
import Swal from 'sweetalert2';
import { AudioActionButtons } from './audioActionButtons/AudioActionButtons'
import { ArticleInfo } from './articleInfo/ArticleInfo'

// mui
import { Button, Grid, Stack, Switch } from '@mui/material'
import SaveIcon from '@mui/icons-material/Save';

// interfaces
import { PlayerState } from '../../../../../interfaces/PlayerState.interface'
import { ArticlePart } from '../../../../../interfaces/article/ArticlePart.interface';
import { Selector } from '../../../../../interfaces/Selector.interface';

// styles
import { ButtonsGrid, FormControlLabelSwitch } from '../styles/EditorPageStyle'
import { saveArticle } from '../../../../../helpers/articles/saveArticle';
import { RightPanelOpen } from './rightPanelButtons/RightPanelOpen';



interface Props {
    streamEnabled: boolean,
    streamSwitchEnabled: boolean,
    playerState: PlayerState,
    setPlayerState: React.Dispatch<React.SetStateAction<PlayerState>>,
    setStreamEnabled: React.Dispatch<React.SetStateAction<boolean>>,
    drawerOpen: boolean,
    handleDrawerOpen: () => void
}

export const AudioEditorHeader = (
    {
        streamEnabled,
        streamSwitchEnabled,
        playerState,
        setPlayerState,
        setStreamEnabled,
        drawerOpen,
        handleDrawerOpen
    }: Props
) => {
    const userData = useSelector((state: Selector) => state.auth);
    const parts: ArticlePart[] = useSelector((state: Selector) => state.parts);
    const selectedPart: number = useSelector((state: Selector) => state.article.editor.selectedPart);
    const selectedArticle = useSelector((state: Selector) => state.article.activeArticle);
    const { id: selectedArticleId, fileName } = selectedArticle;

    const [uploadDisabled, setUploadDisabled] = useState(true);

    useEffect(() => {
        setUploadDisabled(playerState.url === undefined || playerState.url.length === 0);
    }, [playerState.url])

    useEffect(() => {
        setUploadDisabled(true);
    }, [selectedPart, selectedArticleId])

    const handleSaveStreamedAudioSection = async () => {

        fetch(playerState.url)
            .then(response => response.blob())
            .then(blob => {
                // Save article first, to avoid the changes to regenerate the section
                const articleData = {
                    fileName,
                    fileId: selectedArticleId,
                    articleParts: [...parts],
                    activeArticle: selectedArticle
                }

                // save article
                saveArticle(userData, articleData, true).then(async (resp: any) => {
                    const audioSectionId: number = parts[selectedPart].id;

                    // Upload MP3 section file to server
                    const fileToUpload: File = new File([blob], audioSectionId + ".mp3");

                    const bodyFormData = new FormData();
                    bodyFormData.append('file', fileToUpload);

                    api.audio.uploadAudioSection(audioSectionId, bodyFormData)
                        .then(result => {
                            if (result?.status === 201) {
                                Swal.fire({
                                    icon: 'success',
                                    title: 'Success',
                                    text: 'The audio section file was successfully uploaded',
                                });
                            } else {
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Error',
                                    text: 'The audio sectionfile could not be uploaded',
                                });
                            }
                        });
                })
            });
    }

    return (
        <ButtonsGrid container spacing={1}>
            <Grid item xs={12}>
                <Stack direction='row' justifyContent='space-between'>
                    <AudioActionButtons
                        setPlayerState={setPlayerState}
                        setStreamEnabled={setStreamEnabled}
                    />
                    <RightPanelOpen
                        open={drawerOpen}
                        handleDrawerOpen={handleDrawerOpen}
                    />
                </Stack>
            </Grid>

            <Grid item xs={12}>
                <Stack
                    direction='row'
                    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                >
                    <ArticleInfo />

                    <Stack
                        direction='row'
                        spacing={2}
                    >
                        {
                            streamEnabled &&
                            <Button
                                data-test="audiostream-btn-save"
                                size="small"
                                variant="outlined"
                                disabled={uploadDisabled}
                                onClick={handleSaveStreamedAudioSection}
                                sx={{
                                    maxWidth: '35px',
                                    maxHeight: '35px',
                                    minWidth: '35px',
                                    minHeight: '35px',
                                    cursor: "pointer"
                                }}
                                title="Save streamed section"
                            >
                                <SaveIcon />
                            </Button>
                        }

                        <FormControlLabelSwitch
                            control={
                                <Switch
                                    data-test="audiostream-switch"
                                    checked={streamEnabled}
                                    disabled={streamSwitchEnabled}
                                    onChange={() => setStreamEnabled(switchState => !switchState)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            }
                            label="Audio stream"
                            labelPlacement="start"
                        />
                    </Stack>
                </Stack>

            </Grid>

        </ButtonsGrid>
    )
}
