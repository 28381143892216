import { AnyAction } from 'redux';

// custom action creator
import { actionCreator } from './actionCreator/actionCreator';

// types
import { types } from './actionTypes/actionTypes';

// helpers
import api from '../helpers/services/api';


export const loadSoundEffects = async (): Promise<AnyAction> => {

    // load jingles
    const jinglesResp = await api.jingle.getAll();

    let jingles;
    if (jinglesResp.ok) {
        jingles = await jinglesResp.json();
    }
    else {
        jingles = [];
    }

    // load background musics
    const backgroundMusicResp = await api.background.getAll();

    let backgrounds;
    if (backgroundMusicResp.ok) {
        backgrounds = await backgroundMusicResp.json();
    }
    else {
        backgrounds = [];
    }

    const soundEffects = {
        jingles,
        backgrounds
    }

    try {
        return actionCreator(types.soundEffectsLoad, soundEffects);

    } catch (error) {
        const errorMsg = 'Errors in sound effects load action';
        console.error(errorMsg, error)
        throw new Error(errorMsg);
    }
}

export const soundEffectsLogout = (): AnyAction => {
    return actionCreator(types.soundEffectsLogout);
}
