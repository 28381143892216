import { AnyAction } from 'redux';
import Swal from 'sweetalert2';
import { Socket } from 'socket.io-client';

// custom action creator
import { actionCreator } from './actionCreator/actionCreator';

// types
import { types } from './actionTypes/actionTypes';

// helpers
import api from "../helpers/services/api";
import { createSocketClient, updateSocketUser, destroySocketClient } from "../helpers/services/socketclient";



// Register to socket to listen events from the backend
let socket: Socket | undefined = createSocketClient();

interface LoginParams {
    uid: number,
    name: string,
    role: string,
    token: string,
    videoAuthorization: boolean
    impersonator: boolean
    impersonatedBy: number
}

export const startLogin = async (name: string, password: string): Promise<AnyAction> => {
    const resp = await api.auth.login(name, password);
    const body = await resp.json();

    if (resp.status === 201) {
        const loginParams: LoginParams = {
            uid: body.uid,
            name: body.name,
            role: body.role,
            token: body.token,
            videoAuthorization: body.videoAuthorisation,
            impersonator: body.impersonator,
            impersonatedBy: body.impersonatedBy,
        }

        localStorage.setItem('token', loginParams.token);
        localStorage.setItem('token-init-date', new Date().getTime().toString());

        // impersonator users: save special token for impersonated sessions
        if (loginParams.impersonator) {
            localStorage.setItem('token-imp', loginParams.token);
        }

        // Update websocket according to new login
        socket = updateSocketUser(socket, loginParams.token);

        return actionCreator(
            types.authLogin,
            {
                uid: loginParams.uid,
                name: loginParams.name,
                role: loginParams.role,
                videoAuthorisation: loginParams.videoAuthorization,
                impersonator: loginParams.impersonator,
                impersonatedBy: loginParams.impersonatedBy,
            }
        )

    } else {
        const errorMsg = body?.message || '';
        Swal.fire('Login Failed', errorMsg, 'error');
        throw new Error(errorMsg);
    }
}

export const startChecking = async (): Promise<AnyAction> => {
    try {
        const impersonation = localStorage.getItem('token-imp') || '';

        let resp = await api.auth.renew();
        let body = await resp.json();

        if (resp.status === 200) {
            localStorage.setItem('token', body.token);
            localStorage.setItem('token-init-date', new Date().getTime().toString());

            // update websocket according to new token
            socket = updateSocketUser(socket, body.token);

            // impersonation ongoing, refresh login
            if (impersonation.length > 0) {
                resp = await api.auth.impersonate(body.uid);
                body = await resp.json();
            }

            return actionCreator(
                types.authLogin,
                {
                    uid: body.uid,
                    name: body.name,
                    role: body.role,
                    videoAuthorisation: body.videoAuthorisation,
                    impersonator: body.impersonator,
                    impersonatedBy: body.impersonatedBy !== body.uid ? body.impersonatedBy : -1
                }
            )
        } else {
            return actionCreator(types.authCheckingFinish);
        }
    } catch (error) {
        const errorMsg = `Error in startChecking action: ${error}`
        console.error(errorMsg);
        throw new Error(errorMsg);
    }
}

export const startImpersonate = async (userId: number): Promise<AnyAction> => {
    const resp = await api.auth.impersonate(userId);

    const body = await resp.json();

    if (resp.status === 201) {
        const loginParams: LoginParams = {
            uid: body.uid,
            name: body.name,
            role: body.role,
            token: body.token,
            videoAuthorization: body.videoAuthorisation,
            impersonator: body.impersonator,
            impersonatedBy: body.impersonatedBy,
        }

        localStorage.setItem('token', loginParams.token);
        localStorage.setItem('token-init-date', new Date().getTime().toString());

        // Update websocket according to new login
        socket = updateSocketUser(socket, loginParams.token);

        return actionCreator(
            types.authLogin,
            {
                uid: loginParams.uid,
                name: loginParams.name,
                role: loginParams.role,
                videoAuthorisation: loginParams.videoAuthorization,
                impersonator: loginParams.impersonator,
                impersonatedBy: loginParams.impersonatedBy,
            }
        )

    } else {
        const errorMsg = body?.message || '';
        Swal.fire('Login (impersonating) Failed', errorMsg, 'error');
        throw new Error(errorMsg);
    }
}

export const startLogout = (): AnyAction => {
    localStorage.clear();

    if (socket) {
        destroySocketClient(socket);
    }
    return actionCreator(types.authLogout);
}
