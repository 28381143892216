import React from 'react';

// mui
import {
    Box,
    Grid,
    Typography,
} from '@mui/material';

// components
import { ImageGeneratorSelector } from './imageGeneratorSelector/ImageGeneratorSelector';
import { ImageUrlSelector } from './imageUrlSelector/ImageUrlSelector';

// styled
import {
    RightPanelGrid
} from '../../audioEditorPage/styles/EditorPageStyle'

import { ImageGeneratorSettingsGrid, ImageUrlSettingsGrid, PublisherSelectorSettingsGrid } from '../../audioEditorPage/styles/RightPanelStyle';
import { PublisherSelector } from './publisherSelector/PublisherSelector';

interface TabPanelProps {
    children: any,
    index: number,
    value: number
}


const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <span
            role="tabpanel"
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            <Box component='div' sx={{ px: 3, py: 1.5 }}>
                <Typography component='div'>{children}</Typography>
            </Box>
        </span>
    );
}


export const VideoRightPanel = React.memo(() => {

    return (
        <RightPanelGrid item xs={3}>

            <TabPanel value={0} index={0}>
                <ImageGeneratorSettingsGrid container>
                    <Grid item xs={12}>
                        <ImageGeneratorSelector />
                    </Grid>
                </ImageGeneratorSettingsGrid>
            </TabPanel>

            <TabPanel value={1} index={1}>
                <ImageUrlSettingsGrid container>
                    <Grid item xs={12}>
                        <ImageUrlSelector />
                    </Grid>
                </ImageUrlSettingsGrid>
            </TabPanel>

            <TabPanel value={2} index={2}>
                <PublisherSelectorSettingsGrid container>
                    <Grid item xs={12}>
                        <PublisherSelector />
                    </Grid>
                </PublisherSelectorSettingsGrid>
            </TabPanel>

        </RightPanelGrid >
    )
})
