import { types } from "../actions/actionTypes/actionTypes";
import { VideoState } from "../interfaces/Selector.interface";

const initialState: VideoState = { 
    videoUrl: "" ,
    imageGenerator: 'None' ,
    imageStyle: "" ,
    imageCount: 5 ,
    imageUrlList: [] ,
    publisherName: "" ,
    publisherLogoUrl: "" ,
    selectedSubtitlePartId: -1,
    selectedSubtitleText: ""
}

export const videoReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case types.setVideoUrl:
            return {
                ...state,
                videoUrl: action.payload.data
            };
        case types.setImageGenerator:
            return {
                ...state,
                imageGenerator: action.payload.data
            };
        case types.setImageStyle:
            return {
                ...state,
                imageStyle: action.payload.data
            };
        case types.setImageCount:
            return {
                ...state,
                imageCount: action.payload.data
            };
        case types.setImageUrlList:
            return {
                ...state,
                imageUrlList: action.payload.data
            };
        case types.setVideoPublisherName:
            return {
                ...state,
                publisherName: action.payload.data
            };
        case types.setVideoPublisherLogoUrl:
            return {
                ...state,
                publisherLogoUrl: action.payload.data
            };
        case types.setSelectedSubtitlePartId:
            return {
                ...state,
                selectedSubtitlePartId: action.payload.data
            };
        case types.setSelectedSubtitleText:
            return {
                ...state,
                selectedSubtitleText: action.payload.data
            };
        case types.resetAudioState:
            return {
                ...initialState
            }

        default:
            return state
    }
}