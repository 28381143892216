import React, { useEffect, useState, useDeferredValue } from 'react';

// modules
import { useDispatch, useSelector } from 'react-redux';

// components
import { ArticleSection } from './articleSection/ArticleSection';
import { AudioEditorHeader } from './audioEditorHeader/AudioEditorHeader';
import { AudioEditorPlayer } from './audioEditorPlayer/AudioEditorPlayer';
import { RightPanel } from './rightPanel/RightPanel';

// mui
import { styled, useTheme } from '@mui/material/styles';
import { Box, CssBaseline, Drawer, Grid } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';

// actions
import {
    editorHasPartWithSymbolsOnly,
    editorLoadAudioFile,
    editorSetActivePart,
    editorSetLastModifiedBy,
    editorSetLastModifiedDate,
    setActiveArticle,
} from '../../../../actions/articles.actions';
import { partsUpdate } from '../../../../actions/parts.actions';
import { extendArticlePart } from "../../../../helpers/articles/extendArticle";
import {loadConfig, setConfigFile} from '../../../../actions/config.actions';

// styles
import { ContentGrid } from './styles/EditorPageStyle';
import "./styles/style.css"

// helpers
import { doesPartHasSymbolsOnly } from '../../../../helpers/articles/runtimeProcessArticle/partsSymbols';
import { flagArticleParts } from '../../../../helpers/articles/runtimeProcessArticle/flagParts';
import { generatePart } from '../../../../helpers/articles/generatePart';
import { getArticleParts } from '../../../../helpers/articles/getArticleParts';
import { validateArticleParts } from '../../../../helpers/articles/validateArticleParts';
import filterFileByName from '../../../../helpers/filterFileFromRedux';

// default states
import { playerDefaultState } from '../../../../state/playerDefaultState';
import { getStreamDefaultState } from '../../../../state/streamDefaultState';

// interfaces
import { ArticlePart, ArticleSectionType } from '../../../../interfaces/article/ArticlePart.interface';
import { LexiconEntry } from '../../../../models/lexiconEntry.model';
import { Selector } from "../../../../interfaces/Selector.interface";
import { ConfigSyntaxErrors } from '../../../../interfaces/config/Config.interface';


const drawerWidth = 340;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
    open?: boolean;
}>(({ theme, open }) => ({
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    }),
    position: 'relative',
}));



export const AudioEditorPage = () => {
    const theme = useTheme();
    const dispatch = useDispatch();

    // state selector
    const [loadingConfig, setLoadingConfig] = useState(false);
    const article = useSelector((state: Selector) => state.article);
    const articleParts = useSelector((state: Selector) => state.parts);
    const audioInitialState = useSelector((state: Selector) => state.audio.initialState);
    const config = useSelector((state: Selector) => state.config.config);
    const files = useSelector((state: Selector) => state.files);
    const voiceList = useSelector((state: Selector) => state.voices.voiceList);
    const userId = useSelector((state: Selector) => state.auth.uid);

    const { activeArticle, editor } = article;

    const [activePartState, setActivePartState] = useState(1);
    const [activeArticleId, setActiveArticleId] = useState(activeArticle.id);
    const [playerState, setPlayerState] = useState(playerDefaultState);

    const [streamEnabled, setStreamEnabled] = useState(true);
    const [streamSwitchEnabled, setStreamSwitchEnabled] = useState(true);
    const [streamState, setStreamState] = useState(getStreamDefaultState(editor.selectedLanguage));
    const deferredUserId = useDeferredValue(userId);

    const [openRightPanel, setOpenRightPanel] = useState(true);

    const handleDrawerOpen = () => {
        setOpenRightPanel(true);
    };

    const handleDrawerClose = () => {
        setOpenRightPanel(false);
    };


    const handleAddClick = () => {
        let newParts: ArticlePart[] = [...articleParts];

        const selectedData = newParts[editor.selectedPart];
        const { order } = selectedData;

        // add the new part after the selected index
        newParts.splice(
            order + 1,
            0,
            generatePart(order + 1, newParts[editor.selectedPart].voice, 'Content text', ArticleSectionType.CONTENT, newParts[editor.selectedPart]?.language)
        );

        for (let i = 0; i < newParts.length; i++) {
            if (i > order) {
                newParts[i] = {
                    ...newParts[i],
                    order: i
                }
            }
        }
        dispatch(partsUpdate([...newParts]));
        dispatch(editorSetActivePart(order));
    }


    const processArticleParts = async () => {
        // lexicon entries from the state
        const lexicon = config?.filter(element => element.name.toLowerCase() === "lexicon")[0]?.data as LexiconEntry[];

        // syntax errors that will be flagged
        const syntaxErrors: ConfigSyntaxErrors = config?.filter(
            element => element.name === "syntaxErrors"
        )[0]?.data as ConfigSyntaxErrors || [];

        // get article parts
        const parts: ArticlePart[] = await getArticleParts(activeArticle, articleParts, voiceList);

        // verify parts integrity: remove sections that don't include readable characters
        const validatedParts: ArticlePart[] = validateArticleParts(parts);

        const flaggedParts: ArticlePart[] = flagArticleParts(validatedParts, syntaxErrors, lexicon);

        dispatch(partsUpdate([...flaggedParts]));

        return flaggedParts;
    }

    // effect to set the config file to None when the component is mounted
    useEffect(() => {
        dispatch(setConfigFile('None'));
    }, [dispatch]);


    // effect to load config on user change
    useEffect(() => {
        if (!loadingConfig) setLoadingConfig(true);
        // eslint-disable-next-line
    }, [deferredUserId])

    useEffect(() => {
        if (loadingConfig) {
            loadConfig().then(action => {
                dispatch(action);
                setLoadingConfig(false);
            })
        }
        // eslint-disable-next-line
    }, [loadingConfig]);


    // effect to reset the player state when the selected article changed
    useEffect(() => {
        setPlayerState({ ...playerDefaultState })
        // eslint-disable-next-line
    }, [activeArticle.id])

    // effect to get properties in the state for the active article
    useEffect(() => {
        let chosenFile;
        const currentFileName = activeArticle?.fileName;

        if (files?.length) {
            chosenFile = filterFileByName(currentFileName, files);

            if (chosenFile?.length === 1) {
                if (chosenFile[0]?.generationStatus !== activeArticle?.audioMediaFile?.generationStatus) {
                    const spreadingProperties = {
                        fileProcessed: chosenFile[0]?.fileProcessed,
                        generationStatus: chosenFile[0]?.generationStatus,
                        generationStart: chosenFile[0]?.generationStart,
                        generationEnd: chosenFile[0]?.generationEnd,
                        generationLog: chosenFile[0]?.generationLog,
                        hasNewAcronyms: chosenFile[0]?.hasNewAcronyms,
                        newAcronyms: chosenFile[0]?.newAcronyms,
                        audioName: chosenFile[0]?.audioName,
                        status: chosenFile[0]?.status,
                        language: chosenFile[0]?.language
                    };

                    let articlePushingProperties = {
                        ...activeArticle,
                        ...spreadingProperties
                    };

                    dispatch(setActiveArticle(articlePushingProperties));
                };
            };
        };
        // eslint-disable-next-line
    }, [activeArticle.id]);


    // effect to process the parts when a new article is selected
    useEffect(() => {
        const article = { ...activeArticle };
        if (activeArticleId !== article.id || article.id === -1) {
            setActiveArticleId(article.id)
            if (article?.fileName?.length > 0) {
                processArticleParts();
                dispatch(editorSetActivePart(0));

                let lastModifiedDate = article?.lastModifiedDate
                dispatch(editorSetLastModifiedDate(lastModifiedDate));

                let lastModifiedBy = article?.lastModifiedBy
                dispatch(editorSetLastModifiedBy(lastModifiedBy));
            }
            else {
                let initialParts = [];
                const languageVoices = voiceList.filter(voice => voice.language === 'fr-FR');
                if (languageVoices.length > 1) {
                    initialParts.push(
                        extendArticlePart(generatePart(0, languageVoices[0], 'Title text', ArticleSectionType.TITLE))
                    );
                    initialParts.push(
                        extendArticlePart(generatePart(1, languageVoices[1], 'Content part', ArticleSectionType.CONTENT))
                    );
                    dispatch(partsUpdate([...initialParts]));
                }
            }
        }

        // eslint-disable-next-line
    }, [activeArticle.id, voiceList, dispatch])


    // effect to handle the load file props
    useEffect(() => {
        if (editor?.loadAudioFile && editor?.audioFile?.length > 0) {
            setPlayerState(state => ({
                ...state,
                url: editor.audioFile,
                played: 0,
                loaded: 0,
                loadAgain: false,
            }));
            dispatch(editorLoadAudioFile(false));

        } else if (editor?.loadAudioFile && editor?.audioFile === '') {
            setPlayerState(state => ({
                ...state,
                url: '',
                played: 0,
                loaded: 0,
                loadedSeconds: 0,
                duration: 0,
                loadAgain: true,
            }));
            dispatch(editorLoadAudioFile(false));
            setStreamEnabled(true);
        }

    }, [editor?.loadAudioFile, editor.audioFile, dispatch])

    // effect to check if any part is empty or only with symbols
    useEffect(() => {
        let isPartWithSymbolsOnly = false;
        let partIndex = 0;
        while (!isPartWithSymbolsOnly && partIndex < articleParts.length) {
            isPartWithSymbolsOnly = doesPartHasSymbolsOnly(articleParts[partIndex].contentHtml);
            partIndex++;
        }

        if (editor.hasPartWithSymbolsOnly !== isPartWithSymbolsOnly) {
            dispatch(editorHasPartWithSymbolsOnly(isPartWithSymbolsOnly));
        }
        // eslint-disable-next-line
    }, [dispatch, articleParts])

    // effect to set the full audio into the player when the user switches the stream mode
    useEffect(() => {
        if (streamEnabled) {
            setPlayerState(state => ({
                ...state,
                url: '',
                duration: 0,
                played: 0,
                loaded: 0,
                loadedSeconds: 0,
                loadAgain: true,
                playing: false
            }));
        } else {
            setPlayerState(state => ({
                ...state,
                url: audioInitialState,
                played: 0,
                loaded: 0,
                loadAgain: false
            }));
        }

        setStreamState(state => ({
            ...state,
            enabled: streamEnabled
        }));

    }, [audioInitialState, streamEnabled])

    // effect to set the stream switch
    useEffect(() => {
        if (audioInitialState) setStreamSwitchEnabled(false)
        else {
            setStreamSwitchEnabled(true)
            setStreamEnabled(true)
        }
    }, [audioInitialState])

    // effect to set the stream switch
    useEffect(() => {
        if (Object.keys(activeArticle).length === 0) {
            setStreamSwitchEnabled(true)
        }
    }, [activeArticle])

    // effect to set the streaming to load the audio again when the selected part changed
    useEffect(() => {
        if (editor.isModified) {

            setPlayerState(state => {
                return {
                    ...state,
                    loadAgain: true
                }
            });
        }
    }, [editor.isModified])


    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <Main open={openRightPanel}>
                <Grid container>
                    <Grid item xs={12}>

                        <AudioEditorHeader
                            streamEnabled={streamEnabled}
                            streamSwitchEnabled={streamSwitchEnabled}
                            playerState={playerState}
                            setPlayerState={setPlayerState}
                            setStreamEnabled={setStreamEnabled}
                            drawerOpen={openRightPanel}
                            handleDrawerOpen={handleDrawerOpen}
                        />

                        <AudioEditorPlayer
                            playerState={playerState}
                            streamEnabled={streamEnabled}
                            setPlayerState={setPlayerState}
                            activePartState={activePartState}
                            setActivePartState={setActivePartState}
                            streamState={streamState}
                            setStreamState={setStreamState}
                        />

                        <ContentGrid container>
                            {
                                articleParts?.length > 0 &&
                                articleParts?.map(part =>
                                    <ArticleSection
                                        key={`article-section-component-${part.order}`}
                                        part={part}
                                        activePartState={activePartState}
                                        streamEnabled={streamEnabled}
                                        setPlayerState={setPlayerState}
                                    />)
                            }
                        </ContentGrid>

                        <Grid container sx={{ position: 'sticky', bottom: '0px' }}>
                            <Grid item xs={12} sx={{ textAlign: "center" }}>
                                <AddCircleIcon
                                    data-test="section-btn-add"
                                    htmlColor={theme.palette.secondary.main}
                                    onClick={handleAddClick}
                                    sx={{ cursor: 'pointer', fontSize: '40px' }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>

            </Main>

            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                    },
                }}
                variant="persistent"
                anchor="right"
                open={openRightPanel}
            >
                <RightPanel open={openRightPanel} handleDrawerClose={handleDrawerClose} />
            </Drawer>
        </Box>
    );
}