// modules
import { useSelector } from 'react-redux';

// mui
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

// components
import { ThesaurusPanel } from '../../../editorScreen/thesaurusPanel/ThesaurusPanel';

// interfaces
import { Selector } from '../../../../../interfaces/Selector.interface';
import { LexiconEntry } from '../../../../../models/lexiconEntry.model';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 5,
    borderRadius: 10,
    overflowY: "auto",
    maxHeight: "90%"
};

interface Props {
    modalState: boolean,
    handleModalClose: () => void,
    modalSelectedItem: number
    language: string
}

export const LexiconModal = ({ modalState, handleModalClose, modalSelectedItem, language }: Props) => {
    // state selector
    const config = useSelector((state: Selector) => state.config.config);

    const lexiconStateList = config.filter(c => c.name.toLowerCase() === 'lexicon')[0].data as LexiconEntry[];
    const selectedLexicon = lexiconStateList.filter(l => l.id === modalSelectedItem);


    return (
        <Modal
            open={modalState}
            onClose={handleModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                {
                    (selectedLexicon.length > 0)
                        ? <ThesaurusPanel lexiconEntryEditing={selectedLexicon[0]} />
                        : <ThesaurusPanel language={language} />
                }
            </Box>
        </Modal>
    );
}
