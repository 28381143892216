// mui
import PlaylistPlayIcon from '@mui/icons-material/PlaylistPlay';

// interfaces
import {Playlist} from "../../../../../../../interfaces/Playlist.interface";
import {Box, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";


interface Props {
    item: Playlist
}

export const ItemIcon = ({ item }: Props) => {
    return (
        <Box>
            <Stack
                direction='row'
                spacing={1}
                sx={{ alignItems: 'center', display: 'flex', }}
            >
            <PlaylistPlayIcon />
            <Typography>{item.articles.length}</Typography>
            </Stack>
        </Box>
    )
}
