import { AnyAction } from "redux";

// action creator
import { actionCreator } from "./actionCreator/actionCreator";

// types
import { types } from './actionTypes/actionTypes';


export const sidebarTabChange = (tab: any): AnyAction => {
    return actionCreator(types.sidebarTabChange, tab);
}

export const sidebarSetAudioFilesPublishedDays = (days: number): AnyAction => {
    return actionCreator(types.sidebarSetAudioFilesPublishedDays, days);
}
