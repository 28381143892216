import React, { useEffect, useMemo, useState } from 'react';

// modules
import { useDispatch, useSelector } from 'react-redux';
import Flag from 'react-world-flags';

// mui
import {
    Box,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Typography,
} from '@mui/material'

// styles
import "../../styles/country-flags.css";

// data
import { getFlagCode, getLabel } from '../../../../../../data/languageLabels';

// actions
import { editorSetSelectedLanguage, setActiveArticleLanguage } from '../../../../../../actions/articles.actions';
import { updateVoiceList } from '../../../../../../actions/voices.actions';

// interfaces
import { Selector } from "../../../../../../interfaces/Selector.interface";


export const LanguageSelector = React.memo(() => {
    const dispatch = useDispatch();

    // state selector
    const languageList = useSelector((state: Selector) => state.languages.languageList);
    const selectedLanguage = useSelector((state: Selector) => state.article.editor.selectedLanguage);
    const voiceList = useSelector((state: Selector) => state.voices.voiceList);

    const enabledVoices = useMemo(() => voiceList.filter(v => v.enabled), [voiceList])
    const languages: string[] = useMemo(() => Array.from(new Set(enabledVoices.map(v => v.language))), [enabledVoices])

    const [currentStateLanguage, setCurrentStateLanguage] = useState("");

    // effect to apply selected language to voice list
    useEffect(() => {
        if (currentStateLanguage !== selectedLanguage) setCurrentStateLanguage(selectedLanguage);
        // eslint-disable-next-line
    }, [selectedLanguage])

    useEffect(() => {
        const languageVoices = voiceList.map(v => ({ ...v, visible: (v.language === currentStateLanguage) }))
        dispatch(updateVoiceList(languageVoices))
        // eslint-disable-next-line
    }, [currentStateLanguage])
    


    const handleLanguageChange = (e: SelectChangeEvent<string>) => {
        const languageName = e.target.value;
        if (currentStateLanguage !== languageName) {
            setCurrentStateLanguage(selectedLanguage);

            dispatch(editorSetSelectedLanguage(languageName));

            const language = languageList.filter(l => l.code === languageName)[0];
            dispatch(setActiveArticleLanguage(language));
        }
    };


    return (
        <Box sx={{ textAlign: 'left' }}>
            <FormControl fullWidth>
                <InputLabel id="languageSelectorLabel">Language</InputLabel>
                <Select
                    labelId="languageSelectorLabel"
                    id="languageSelector"
                    data-test="language-selector"
                    value={languages.includes(currentStateLanguage) ? currentStateLanguage : ''}
                    label="Language"
                    onChange={handleLanguageChange}
                >
                    {
                        languages.sort().map(lang => {
                            return <MenuItem
                                data-test={`languageselector-${lang}`}
                                key={lang}
                                value={lang}
                            >
                                <Grid container>
                                    <Grid item xs={10} sx={{
                                        display: 'flex',
                                        flexDirection: "row"
                                    }}>
                                        <Flag id="flag-img-language" code={getFlagCode(lang)} />
                                        <Typography component='div' sx={{ ml: "7px" }}>
                                            {getLabel(languageList, lang)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </MenuItem>
                        })
                    }
                </Select>
            </FormControl>
        </Box>
    );
})
