import { AnyAction } from "redux";

// types
import { types } from './actionTypes/actionTypes';

// action creator
import { actionCreator } from "./actionCreator/actionCreator";


export const setFullVideoUrl = (url: string): AnyAction => {
    return actionCreator(types.setVideoUrl, url);
}

export const setImageGenerator = (generatorName: string | undefined): AnyAction => {
    return actionCreator(types.setImageGenerator, generatorName);
}

export const setImageCount = (count: number): AnyAction => {
    return actionCreator(types.setImageCount, count);
}

export const setImageStyle = (style: string): AnyAction => {
    return actionCreator(types.setImageStyle, style);
}

export const setImageUrlList = (urls: string[]): AnyAction => {
    return actionCreator(types.setImageUrlList, urls);
}

export const setVideoPublisherName = (publisherName: string): AnyAction => {
    return actionCreator(types.setVideoPublisherName, publisherName);
}

export const setVideoPublisherLogoUrl = (logoUrl: string): AnyAction => {
    return actionCreator(types.setVideoPublisherLogoUrl, logoUrl);
}

export const setSelectedSubtitlePartId = (partId: number): AnyAction => {
    return actionCreator(types.setSelectedSubtitlePartId, partId);
}

export const setSelectedSubtitleText = (subtitleText: string): AnyAction => {
    return actionCreator(types.setSelectedSubtitleText, subtitleText);
}

export const resetAudioState = (): AnyAction => {
    return actionCreator(types.resetAudioState);
}
