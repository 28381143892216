import { store } from '../store/store';
import { showNotPermanentErrorSnackBar } from '../actions/snackBar.actions';

/**
 * Sends an error snackBar message that informs user that his session has already expired. Then,
 * he/she is logged out.
 * @returns {Function} timeOut that clears localStorage (so as to delete user already expired token) and
 * reloads browser.
 */
export const sessionExpired = () => {
    store.dispatch(showNotPermanentErrorSnackBar("Session expired. Please, log in again."))
    return setTimeout(() => {
        localStorage.clear();
        window.location.reload();
    }, 3500);
};
