import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AudioActionButton} from "../AudioActionButton";
import {Article} from "../../../../../../../interfaces/article/Article.interface";
import {Selector} from "../../../../../../../interfaces/Selector.interface";
import TranslateIcon from "@mui/icons-material/Translate";
import {ArticleVersion} from "../../../../../../../helpers/articleSet";
import {showPopupTranslation} from "../../../../../../../actions/popup.action";

interface Props {
    loadMP3: boolean;
}

export const AudioActionButtonTranslate = ({loadMP3}: Props) => {
    const dispatch = useDispatch();

    const article = useSelector((state: Selector) => state.article);
    const files = useSelector((state: Selector) => state.files);

    const { activeArticle} = article;

    const [isTranslating, setIsTranslating] = useState(false);

    const handleTranslate = async () => {
        setIsTranslating(true);

        const currentArticles: Article[] = files.filter(
            file => file.id === activeArticle.id || file.originalArticleId === activeArticle.id
        );
        const currentArticleVersion = new ArticleVersion(currentArticles)
        dispatch(showPopupTranslation(currentArticleVersion));

        setIsTranslating(false);
    }

    return <AudioActionButton
        data_test="audioactionbuttons-btn-translate"
        title={"Translate the article"}
        icon={<TranslateIcon fontSize="small" />}
        onClick={handleTranslate}
        isLoading={isTranslating}
        isLoadingButton={true}
        name={loadMP3 ? "Translating..." : "Translate"}
    />
}