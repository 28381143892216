import { types } from "../actions/actionTypes/actionTypes";
import { ThesaurusState } from "../interfaces/Selector.interface";

const initialState: ThesaurusState = {
    selectedArticleIds: [],
    articleExtracts: []
}

export const thesaurusReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case types.thesaurusArticleChecked:
            return {
                ...state,
                selectedArticleIds: action.payload.data
            };
        case types.thesaurusArticleExtractsUpdated:
            return {
                ...state,
                articleExtracts: action.payload.data
            };
        default:
            return state
    }
}