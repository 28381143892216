import { useState } from 'react';

// modules
import { useDispatch, useSelector } from 'react-redux';

// mui
import { LoadingButton } from '@mui/lab';
import useMediaQuery from '@mui/material/useMediaQuery';
import AudioFileIcon from '@mui/icons-material/AudioFile';
import ButtonGroup from '@mui/material/ButtonGroup';

// actions
import { showPermanentSuccessSnackBar, showPermanentErrorSnackBar } from '../../../../../actions/snackBar.actions';
import { setThesaurusArticleExtracts } from "../../../../../actions/thesaurus.actions";

// helpers
import api from "../../../../../helpers/services/api";

// interfaces
import { Selector } from '../../../../../interfaces/Selector.interface';


export const ThesaurusActionButtons = () => {
    const dispatch = useDispatch();

    const [isDetecting, setDetecting] = useState(false);

    // state selector
    const selectedArticleIds = useSelector((state: Selector) => state.thesaurus.selectedArticleIds);

    const matches = useMediaQuery("(min-width:1200px)");

    // trigger video generation
    const handleFindThesaurusCandidates = async () => {
        setDetecting(true);

        const result = await api.lexicon.buildThesaurusData(selectedArticleIds);

        if (result?.status === 200) {
            dispatch(showPermanentSuccessSnackBar("Thesaurus candidates detection done"));
            const body = await result.json();
            dispatch(setThesaurusArticleExtracts(body));
            setDetecting(false);
        } else {
            dispatch(showPermanentErrorSnackBar("Thesaurus candidates detection failed"));
            setDetecting(false);
        }
    };


    return (
        <ButtonGroup
            variant="outlined"
            aria-label="outlined primary button group"
            orientation={`${matches ? `horizontal` : `vertical`}`}
        >
            <LoadingButton
                data-test="lexiconactionbuttons-btn-detect"
                size="small"
                startIcon={<AudioFileIcon fontSize="small" />}
                onClick={handleFindThesaurusCandidates}
                disabled={isDetecting}
                variant="outlined"
                loading={isDetecting}
                loadingPosition="start"
            >
                {isDetecting ? "Detecting..." : "Detect candidates"}
            </LoadingButton>
        </ButtonGroup>
    )
}
