import { AnyAction } from 'redux';
import Swal from 'sweetalert2';

// types
import { types } from './actionTypes/actionTypes';

// helpers
import api from "../helpers/services/api";

// action creator
import { actionCreator } from './actionCreator/actionCreator';

// interfaces
import { User } from '../interfaces/user/user.interface';
import { UserAddBody, UserUpdateBody } from '../interfaces/services/api.interfaces';


const getUserRoleId = (roleLabel: string) => {
    switch (roleLabel) {
        case 'User':
            return 1;
        case 'Admin':
            return 2;
        case 'SuperAdmin':
            return 3;
        default:
            return 1;
    }
}


export const readUsers = async (userRole: string, isImpersonated: boolean = false): Promise<AnyAction> => {
        const userRoleId = getUserRoleId(userRole);

        let resp: Response;
        if (userRoleId === 3) {
            if (isImpersonated) {
                resp = await api.user.getAllImpersonated(true);
            } else {
                resp = await api.user.getAll(true);
            }

        } else {
            resp = await api.user.getAllByClient(true);
        }

        if (resp.status === 200) {
            const body: User[] = await resp.json();
            body.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1)
            return actionCreator(types.usersRead, body);

        } else {
            return actionCreator(types.usersRead, {});
        }
}

export const readClients = async (): Promise<AnyAction> => {
    let resp: Response;
    resp = await api.client.getAll();

    if (resp.status === 200) {
        const body = await resp.json();
        return actionCreator(types.usersReadClients, body);

    } else {
        throw new Error('Errors in readClients action');
    }
}

export const setActiveUser = (user: User): AnyAction => {
    try {
        return actionCreator(types.usersActive, user);

    } catch (error) {
        const errorMsg = 'Errors in getActiveUser action';
        console.error(errorMsg, error);
        throw new Error(errorMsg);
    }
}


export const clearActiveUser = (): AnyAction => {
    return actionCreator(types.usersActiveClear);
}

export const updateUser = async (user: User): Promise<AnyAction> => {
    try {
        const reqBody: UserUpdateBody = {
            name: user.name,
            email: user.email,
            type: getUserRoleId(user.typeDescription),
            client: user.client,
        }

        const resp = await api.user.update(user.id, reqBody);
        const body = await resp.json();

        if (resp.status === 200) {
            const win: Window = window;

            Swal
                .fire('Success', 'User was saved', 'success')
                .then(() => { win.location = "/users" })

            return actionCreator(types.usersActiveClear, user);

        } else {
            Swal.fire('Error', body.msg, 'error');
            throw new Error(body.msg);
        }

    } catch (error) {
        const errorMsg = 'Errors in updateUser action';
        console.error(errorMsg, error);
        throw new Error(errorMsg);
    }
}

export const createUser = async (user: User): Promise<AnyAction> => {
    try {
        const reqBody: UserAddBody = {
            name: user.name,
            email: user.email,
            password: user.password,
            type: getUserRoleId(user.typeDescription),
            client: user.client,
            videoAuthorisation: user.videoAuthorisation,
            impersonator: user.impersonator,
            canBeImpersonated: user.canBeImpersonated,
        }

        const resp = await api.user.add(reqBody);
        const body = await resp.json();

        if (resp.status === 201) {
            const win: Window = window;

            Swal
                .fire('Success', 'User was created', 'success')
                .then(() => { win.location = "/users" })

            return actionCreator(types.usersCreate, user);

        } else {
            Swal.fire('Error', body.msg, 'error');
            throw new Error(body.msg);
        }

    } catch (error) {
        const errorMsg = 'Errors in createUser action';
        console.error(errorMsg, error);
        throw new Error(errorMsg);
    }
}

export const deleteUser = async (userID: number): Promise<AnyAction> => {
    try {
        const resp = await api.user.delete(userID);
        const body = await resp.json();

        if (resp.status === 200) {
            Swal.fire('Success', 'User was deleted', 'success');
            return actionCreator(types.usersDelete, { id: userID });

        } else {
            Swal.fire('Error', body.msg, 'error');
            throw new Error(body.msg);
        }

    } catch (error) {
        const errorMsg = 'Errors in deleteUser action';
        console.error(errorMsg, error);
        throw new Error(errorMsg);
    }
}


export const startLogoutUser = (): AnyAction => {
    return actionCreator(types.usersLogout);
}
