import { AnyAction } from "redux";
import { createAction } from "redux-actions";

interface ActionBody {
    type: string,
    data?: any
}

// returns a redux action given a type and an optional payload
export const actionCreator = (actionType: string, payload?: any): AnyAction => {
    const action = createAction<AnyAction>(actionType);

    let actionBody: ActionBody = { type: actionType }

    // check if undefined explicitly to make it pass when payload is the 0 integer value
    if (typeof payload !== 'undefined') { actionBody.data = payload }

    return action(actionBody);
}
