import { useState } from 'react';

// modules
import { useDispatch, useSelector } from 'react-redux';

// mui
import { Box, InputLabel, MenuItem, FormControl, Select } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';

// interfaces
import { Selector } from '../../../../../../../interfaces/Selector.interface';
import { startChecking, startImpersonate } from '../../../../../../../actions/auth.actions';
import { startLogoutUser } from '../../../../../../../actions/users.actions';
import { articlesLogout } from '../../../../../../../actions/articles.actions';
import { configLogout } from '../../../../../../../actions/config.actions';
import { partsLogout } from '../../../../../../../actions/parts.actions';
import { soundEffectsLogout } from '../../../../../../../actions/soundEffects.actions';
import { voicesLogout } from '../../../../../../../actions/voices.actions';
import { snackBarLogout } from '../../../../../../../actions/snackBar.actions';


export const ImpersonateSelector = () => {
    const dispatch = useDispatch();

    // state selector
    const uid = useSelector((state: Selector) => state.auth.uid);
    const usersList = useSelector((state: Selector) => state.users.usersList);

    const [impersonatedUser, setImpersonatedUser] = useState('');

    const handleImpersonateChange = (event: SelectChangeEvent) => {
        setImpersonatedUser(event.target.value as string);

        handleLogout();

        if (event.target.value === 'none') {
            // no user to impersonate: set impersonator token as the regular token and refresh login
            const tokenImp = localStorage.getItem('token-imp') || '';
            localStorage.removeItem('token');
            localStorage.setItem('token', tokenImp);
            startChecking().then(action => dispatch(action))
            setImpersonatedUser('');
        } else {
            startImpersonate(Number(event.target.value)).then(action => dispatch(action));
        }
    };

    const handleLogout = () => {
        dispatch(startLogoutUser());
        dispatch(articlesLogout());
        dispatch(configLogout());
        dispatch(partsLogout());
        dispatch(soundEffectsLogout());
        dispatch(voicesLogout());
        dispatch(snackBarLogout());
    }

    return (
        <Box sx={{ mx: 1 }}>
            <FormControl fullWidth>
                <InputLabel id="impersonator-select-label">Impersonate User</InputLabel>
                <Select
                    labelId="impersonator-select-label"
                    data-test="impersonator-select"
                    id="impersonator-select"
                    value={impersonatedUser}
                    label="Impersonate User"
                    onChange={handleImpersonateChange}
                >
                    <MenuItem
                        data-test={`impersonator-select-none`}
                        key={`impersonate-user-none`}
                        value="none"
                    >
                        <i>None</i>
                    </MenuItem>
                    {
                        usersList.map(user => user.id !== uid && user.canBeImpersonated &&
                            <MenuItem
                                data-test={`impersonator-select-${user.name}`}
                                key={`impersonate-user-${user.id}`}
                                value={user.id}
                            >
                                {`${user.name} (${user.typeDescription})`}
                            </MenuItem>
                        )
                    }
                </Select>
            </FormControl>
        </Box>
    )
}
