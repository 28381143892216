
import React, { useState, useEffect } from 'react';

// modules
import { AnyAction } from 'redux';
import { useDispatch, useSelector } from 'react-redux';

// actions
import { filterFiles, sortFiles } from '../../../../actions/files.actions';

// mui
import {
    FormControl, IconButton, InputBase, InputLabel, MenuItem, Paper, Select, Stack, Toolbar
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import ReorderIcon from '@mui/icons-material/Reorder';

// interfaces
import { Selector } from "../../../../interfaces/Selector.interface";



export const FileSearch = React.memo(() => {
    const theme = useTheme();
    const dispatch = useDispatch();

    // state selector
    const files = useSelector((state: Selector) => state.files);

    const [inputSearch, setInputSearch] = useState('');
    const [filter, setFilter] = useState('');


    const handleInputSearch = (e: any) => {
        setInputSearch(e.target.value);
    }

    const handleCleanSearch = () => {
        setInputSearch('');
    }

    const handleOrderFilter = (e: AnyAction) => {
        switch (e.target.value) {
            case "created_up":
                setFilter(e.target.value);
                dispatch(sortFiles("createdup", files));
                break;
            case "created_down":
                setFilter(e.target.value);
                dispatch(sortFiles("createddown", files));
                break;
            case "updated_up":
                setFilter(e.target.value);
                dispatch(sortFiles("updatedup", files));
                break;
            case "updated_down":
                setFilter(e.target.value);
                dispatch(sortFiles("updateddown", files));
                break;
            case "nameaz":
                setFilter(e.target.value);
                dispatch(sortFiles("nameaz", files));
                break;
            case "nameza":
                setFilter(e.target.value);
                dispatch(sortFiles("nameza", files))
                break;
            case "published_up":
                setFilter(e.target.value);
                dispatch(sortFiles("publishedup", files));
                break;
            case "published_down":
                setFilter(e.target.value);
                dispatch(sortFiles("publisheddown", files));
                break;
            default:
                break;
        }
    }


    useEffect(() => {
        dispatch(filterFiles(inputSearch, files));
        // eslint-disable-next-line
    }, [inputSearch]);


    return (
        <Toolbar
            sx={{
                mb: theme.spacing(2),
                display: 'flex',
                alignContent: 'center',
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            <Stack
                direction='row'
                spacing={4}
            >
                <Paper
                    component="form"
                    sx={{ p: '4px', display: 'flex', alignItems: 'left', width: 300 }}
                >
                    <IconButton
                        aria-label="search"
                        sx={{ p: 0, cursor: 'unset' }}
                    >
                        <SearchIcon />
                    </IconButton>
                    <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Search..."
                        inputProps={{ 'aria-label': 'search' }}
                        onKeyUp={handleInputSearch}
                        value={inputSearch}
                        onChange={handleInputSearch}
                    />
                    <IconButton
                        aria-label="clean"
                        size='small'
                        sx={{ p: '0px', cursor: 'unset', width: '10px' }}
                        onClick={handleCleanSearch}
                    >
                        <CloseIcon
                            fontSize="small"
                            sx={{ color: '#9A9A9A' }}
                        />
                    </IconButton>
                </Paper>

                <Paper
                    component="form"
                    sx={{ p: '4px 4px', display: 'flex', alignItems: 'left', width: 300 }}
                >
                    <IconButton
                        aria-label="filter"
                        sx={{ p: '0px', cursor: 'unset' }}
                    >
                        <ReorderIcon />
                    </IconButton>
                    <FormControl fullWidth>
                        <InputLabel id="select-order-label">Order</InputLabel>
                        <Select
                            labelId="select-order"
                            id="select-order"
                            value={filter}
                            label="Order"
                            onChange={handleOrderFilter}
                        >
                            <MenuItem sx={{ width: '100%' }} value={"created_up"}>Created (latest)</MenuItem>
                            <MenuItem sx={{ width: '100%' }} value={"created_down"}>Created (oldest)</MenuItem>
                            <MenuItem sx={{ width: '100%' }} value={"updated_up"}>Updated (latest)</MenuItem>
                            <MenuItem sx={{ width: '100%' }} value={"updated_down"}>Updated (oldest)</MenuItem>
                            <MenuItem sx={{ width: '100%' }} value={"nameaz"}>Name (a-z)</MenuItem>
                            <MenuItem sx={{ width: '100%' }} value={"nameza"}>Name (z-a)</MenuItem>
                        </Select>
                    </FormControl>
                </Paper>

            </Stack>
        </Toolbar>
    )
})