import { useState } from "react";

// modules
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import Flag from "react-world-flags";
import { useSelector } from "react-redux";

// mui
import { useTheme } from '@mui/material/styles';
import { Box, Button, Select, Stack, Typography } from '@mui/material'
import MenuItem from "@mui/material/MenuItem";

// helpers
import { translate } from "../../helpers/translation";
import { getFlagCode } from "../../data/languageLabels";
import { ArticleVersion } from "../../helpers/articleSet";

// interfaces
import { LanguageLabel } from "../../interfaces/LanguageLabel.interface";
import { Selector } from "../../interfaces/Selector.interface";

interface Parameters {
    handleModalClose: () => void;
    articles: ArticleVersion | null;
}

export const TranslateArticlePopup = ({ handleModalClose, articles }: Parameters) => {
    const theme = useTheme();

    // state selector
    const languages = useSelector((state: Selector) => state.languages);

    const getAvailableLanguages = () => {
        let availableLanguages: LanguageLabel[] = languages.languageList.map(item => ({ isoCode: item.code, label: item.name }));
        if (articles) {
            for (let articlesLanguage of articles.getArticleLanguages()) {
                availableLanguages = availableLanguages.filter((l: LanguageLabel) => l.isoCode !== articlesLanguage.getLanguage()?.code);
            }
        }
        availableLanguages.sort((a, b) => (a.isoCode > b.isoCode) ? 1 : -1)
        return availableLanguages;
    }

    const [selectedLanguage, setSelectedLanguage] = useState<string>(getAvailableLanguages()[0].isoCode);
    const [selectedTranslator, setSelectedTranslator] = useState<string>("DEEPL");

    const initialFormValues = {
        translationLanguage: '',
        translator: '',
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialFormValues,
        onSubmit: async (values: any) => {
            await handleSubmit(values);
        },
    });

    const handleSubmit = async (values: any) => {
        handleModalClose();
        const translationParameters = {
            translationLanguage: selectedLanguage,
            translator: selectedTranslator
        }
        if (articles && articles.getArticleLanguages()[0])
            await translate(articles.getArticleLanguages()[0].getArticle().id, translationParameters.translationLanguage, translationParameters.translator)
        else {
            await Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
            })
        }
    }

    const handleTranslatorChange = (e: any) => {
        setSelectedTranslator(e.target.value);
    }

    const handleLanguageTranslationChange = (e: any) => {
        setSelectedLanguage(e.target.value);
    }

    return (
        <Box
            component='div'
            sx={{ minWidth: '90%' }}
        >
            <form onSubmit={formik.handleSubmit} autoComplete="off">
                <Stack spacing={2}>
                    <Typography
                        color={theme.palette.primary.main}
                        component='div'
                        variant='h6'
                    >
                        Translate an article in another language
                    </Typography>

                    <Select
                        labelId="languageTranslationLabel"
                        id="languageTranslationSelector"
                        value={selectedLanguage}
                        label="Language"
                        onChange={handleLanguageTranslationChange}
                    >
                        {getAvailableLanguages().map((l: LanguageLabel) => (
                            <MenuItem value={l.isoCode} key={l.isoCode}>
                                <Flag id="flag-img-language" code={getFlagCode(l.isoCode)} />
                                {l.label}
                            </MenuItem>
                        ))}
                    </Select>

                    <Select
                        labelId="translatorLabel"
                        id="translatorSelector"
                        value={selectedTranslator}
                        label="Translator"
                        onChange={handleTranslatorChange}
                    >
                        <MenuItem value={'DEEPL'}>Deepl</MenuItem>
                        <MenuItem value={'CHAT_GPT'}>ChatGPT</MenuItem>
                    </Select>

                    <Stack
                        alignItems="center"
                        direction="row"
                        justifyContent="center"
                        spacing={{ xs: 1, sm: 2, md: 4 }}
                    >
                        <Button
                            variant="contained"
                            type="submit"
                            disabled={formik.isSubmitting}
                        >
                            Translate
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={handleModalClose}
                            disabled={formik.isSubmitting}
                        >
                            Cancel
                        </Button>
                    </Stack>
                </Stack>
            </form>

        </Box>
    )
}
