import React from 'react';

// modules
import { useSelector } from 'react-redux';

// components
import { HeaderCell } from '../../../../theme/styled/HeaderCell';

// mui
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import ClearIcon from '@mui/icons-material/Clear';

// helpers
import { formatDateToString } from '../../../../helpers/formatDateToString';

// interfaces
import { Selector } from '../../../../interfaces/Selector.interface';


const HeaderCellCustom = styled(HeaderCell)(() => ({
    body: { fontSize: 14 }
}));


export const FilesTable = React.memo(() => {
    const theme = useTheme();

    // state selector
    const files = useSelector((state: Selector) => state.files);

    return (
        <TableContainer
            component={Paper}
            sx={{
                overflowX: 'unset',
                mt: 4,
                mb: 10,
                boxShadow: 'none'
            }}
        >
            <Table
                component={Paper}
                stickyHeader
                aria-label="files-table"
            >
                <TableHead>
                    <TableRow>
                        <HeaderCellCustom>ID</HeaderCellCustom>
                        <HeaderCellCustom>File Name</HeaderCellCustom>
                        <HeaderCellCustom>Publisher</HeaderCellCustom>
                        <HeaderCellCustom>Status</HeaderCellCustom>
                        <HeaderCellCustom>Created At</HeaderCellCustom>
                        <HeaderCellCustom>Updated At</HeaderCellCustom>
                        <HeaderCellCustom>Language</HeaderCellCustom>
                        <HeaderCellCustom>Topic</HeaderCellCustom>
                        <HeaderCellCustom>Tone</HeaderCellCustom>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {
                        files.map(file => (
                            file.visible &&
                            <TableRow
                                hover
                                key={file.id}
                            >
                                <TableCell component="th" scope="row">
                                    {file.id}
                                </TableCell>
                                <TableCell>{file.fileName}</TableCell>
                                <TableCell>{file.publisher}</TableCell>

                                <TableCell
                                    sx={
                                        (file.status === 'Disabled' || file.status === 'DELETED')
                                            ? ({ color: theme.palette.success.main })
                                            : (file.status === 'CREATING' || file.status === 'PENDING')
                                                ? ({ color: theme.palette.warning.light })
                                                : ({ color: theme.palette.success.main })
                                    }
                                >
                                    {file.status}
                                </TableCell>

                                <TableCell>{formatDateToString(file.createdAt)}</TableCell>

                                <TableCell>{formatDateToString(file.updatedAt)}</TableCell>

                                <TableCell>{file.language.name}</TableCell>

                                <TableCell>
                                    {file.topic ? file.topic : <ClearIcon />}
                                </TableCell>

                                <TableCell>
                                    {file.tone ? file.tone.name : <ClearIcon />}
                                </TableCell>

                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </TableContainer >
    );
})
