import React, { useEffect, useState } from "react";

// modules
import { useSelector } from 'react-redux';

// mui
import {
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";

// interfaces
import { Selector } from "../../../../interfaces/Selector.interface";

// styles
import { HeaderCell } from '../../../../theme/styled/HeaderCell';

// helpers
import api from "../../../../helpers/services/api";
import { Consumption } from "../../../../models/consumption.model";

export const ConsumptionsTable = React.memo(() => {

    interface ConsumptionRow {
        tts: string,
        characterStreamed: number,
        characterGenerated: number,
        characterNumber: number,
    }
    const { config } = useSelector((state: Selector) => state.config);

    const [articleGenerationCount, setArticleGenerationCount] = useState(0 as number);
    const [longArticleGenerationCount, setLongArticleGenerationCount] = useState(0 as number);
    const [totalCharacterStreamed, setTotalCharacterStreamed] = useState(0 as number);
    const [totalCharacterGenerated, setTotalCharacterGenerated] = useState(0 as number);
    const [totalCharacterNumber, setTotalCharacterNumber] = useState(0 as number);
    const [tableRows, setTableRows] = useState([] as ConsumptionRow[]);

    useEffect(() => {
        const consumptionConfig = config.filter(c => c.name.toLowerCase() === "consumptions")[0].data as Consumption;
        const consumptionRows: ConsumptionRow[] = [];

        let totalCharStreamed = 0;
        let totalCharGenerated = 0;

        for (let ttsConsumption of consumptionConfig.ttsConsumptions) {
            consumptionRows.push({ ...ttsConsumption });

            totalCharStreamed += ttsConsumption.characterStreamed;
            totalCharGenerated += ttsConsumption.characterGenerated;
        }

        setTotalCharacterStreamed(totalCharStreamed);
        setTotalCharacterGenerated(totalCharGenerated);
        setTotalCharacterNumber(totalCharStreamed + totalCharGenerated);

        setArticleGenerationCount(consumptionConfig.articleGenerationCount);
        setLongArticleGenerationCount(consumptionConfig.longArticleGenerationCount);
        setTableRows(consumptionRows);
    }, [config]);


    // function to handle the change of the date picker
    const handleDateChange = async (newStartDate: any, newEndDate: any) => {
        // call the api to get the new data
        const responseGenerated = await api.consumption.getConsumptionGenerated(newStartDate, newEndDate)
        const responseStreamed = await api.consumption.getConsumptionStreamed(newStartDate, newEndDate)
        const responseArticleGenerationCount = await api.consumption.getArticleGenerationCount(newStartDate, newEndDate)
        const responseLongArticleGenerationCount = await api.consumption.getLongArticleGenerationCount(10000, newStartDate, newEndDate)
        let newConsumptionGeneratedData = await responseGenerated.json();
        let newConsumptionStreamedData = await responseStreamed.json();
        let articleGenerationCount: number = await responseArticleGenerationCount.json();
        let longArticleGenerationCount: number = await responseLongArticleGenerationCount.json();

        // update the state with the new data 
        const ttsList: string[] = [];

        let totalCharStreamed = 0;
        let totalCharGenerated = 0;

        const consumptionGenerationByTTs: Map<string, number> = new Map<string, number>();
        for (let consumptionGeneration of newConsumptionGeneratedData) {
            if (!consumptionGenerationByTTs.has(consumptionGeneration.tts)) {
                consumptionGenerationByTTs.set(consumptionGeneration.tts, 0);
                ttsList.push(consumptionGeneration.tts);
            }
            consumptionGenerationByTTs.set(consumptionGeneration.tts, consumptionGenerationByTTs.get(consumptionGeneration.tts) + consumptionGeneration.characterConsumption);

        }

        const consumptionStreamingByTTs: Map<string, number> = new Map<string, number>();
        for (let consumptionStreaming of newConsumptionStreamedData) {
            if (!consumptionStreamingByTTs.has(consumptionStreaming.tts)) {
                consumptionStreamingByTTs.set(consumptionStreaming.tts, 0);
                if (!ttsList.includes(consumptionStreaming.tts)) {
                    ttsList.push(consumptionStreaming.tts);
                }
            }
            consumptionStreamingByTTs.set(consumptionStreaming.tts, consumptionStreamingByTTs.get(consumptionStreaming.tts) + consumptionStreaming.characterConsumption);
        }

        const consumptionRows: ConsumptionRow[] = [];
        for (let tts of ttsList) {

            const newConsumptionGenerated: number = consumptionGenerationByTTs.get(tts) ?? 0;
            const newConsumptionStreamed: number = consumptionStreamingByTTs.get(tts) ?? 0;

            consumptionRows.push({
                tts: tts,
                characterStreamed: newConsumptionStreamed,
                characterGenerated: newConsumptionGenerated,
                characterNumber: newConsumptionStreamed + newConsumptionGenerated
            });

            totalCharStreamed += newConsumptionStreamed;
            totalCharGenerated += newConsumptionGenerated;
        }

        setTotalCharacterStreamed(totalCharStreamed);
        setTotalCharacterGenerated(totalCharGenerated);
        setTotalCharacterNumber(totalCharStreamed + totalCharGenerated);

        setArticleGenerationCount(articleGenerationCount);
        setLongArticleGenerationCount(longArticleGenerationCount);
        setTableRows(consumptionRows);
    }

    return (
        <>
            <Stack
                direction='row'
                spacing={3}
                display='flex'
                justifyContent='center'
                alignItems='center'
                sx={{ mb: 2 }}
            >
                <TextField
                    id="startDate"
                    label="Start date"
                    type="date"
                    defaultValue={new Date(new Date().getFullYear(), new Date().getMonth(), 2).toISOString().slice(0, 10)}
                    InputLabelProps={{ shrink: true, }}
                />
                <TextField
                    id="endDate"
                    label="End date"
                    type="date"
                    defaultValue={new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1).toISOString().slice(0, 10)}
                    InputLabelProps={{ shrink: true, }}
                />
                <Button
                    id="validate"
                    defaultValue={"Validate"}
                    variant="outlined"
                    className="button"
                    name={"button validate"}
                    sx={{ fontWeight: 'bold' }}
                    //call the handleValidate function with the value of the input
                    onClick={() => {
                        handleDateChange(
                            (document.getElementById('startDate') as HTMLInputElement).value,
                            (document.getElementById('endDate') as HTMLInputElement).value)
                    }}
                >
                    Validate
                </Button>
            </Stack>

            <TableContainer component={Paper}>
                <Table
                    aria-label="Consumptions-table"
                    stickyHeader
                >
                    <TableHead>
                        <TableRow>
                            <HeaderCell>TTS</HeaderCell>
                            <HeaderCell>Streamed Characters</HeaderCell>
                            <HeaderCell>Generated Characters</HeaderCell>
                            <HeaderCell>Total Character Volume</HeaderCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            tableRows.map(item => {
                                return (
                                    <TableRow key={`consumption-table-${item.tts}`}>
                                        <TableCell sx={{ fontWeight: 'bold' }}>{String(item.tts)}</TableCell>
                                        <TableCell>{(item.characterStreamed).toLocaleString('fr')}</TableCell>
                                        <TableCell>{(item.characterGenerated).toLocaleString('fr')}</TableCell>
                                        <TableCell>{(item.characterNumber).toLocaleString('fr')}</TableCell>
                                    </TableRow>
                                )
                            })
                        }
                        <TableRow key={`consumption-table-total.tts`}>
                            <TableCell sx={{ fontWeight: 'bold' }}>Total</TableCell>
                            <TableCell sx={{ fontWeight: 'bold' }}>{(totalCharacterStreamed).toLocaleString('fr')}</TableCell>
                            <TableCell sx={{ fontWeight: 'bold' }}>{(totalCharacterGenerated).toLocaleString('fr')}</TableCell>
                            <TableCell sx={{ fontWeight: 'bold' }}>{(totalCharacterNumber).toLocaleString('fr')}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <Stack
                // direction='row'
                spacing={0}
                display='flex'
                // justifyContent='center'
                // alignItems='center'
                sx={{ mb: 0 }}
            >
                <Box maxWidth="lg" m={2} pt={3}>
                    <Typography
                        component='span'
                        variant='h6'
                        color='primary'
                    >
                        Number of articles generated:&nbsp;
                    </Typography>

                    <Typography
                        component='span'
                        variant='h6'
                        color='secondary'
                    >
                        {String(articleGenerationCount)}
                    </Typography>
                </Box>
                <Box maxWidth="lg" ml={2}>
                    <Typography
                        component='span'
                        variant='h6'
                        color='primary'
                    >
                        Including long articles (+10k characters):&nbsp;
                    </Typography>

                    <Typography
                        component='span'
                        variant='h6'
                        color='secondary'
                    >
                        {String(longArticleGenerationCount)}
                    </Typography>
                </Box>
            </Stack>
        </>
    );
});
