// components
import { Logo } from './logo/Logo';
import { BackButton } from './backButton/BackButton';
import { LogoutButton } from './logoutButton/LogoutButton';

// mui
import { useTheme } from '@mui/material/styles';
import { Toolbar, Typography } from '@mui/material';


/**
 * the Header component for the app
 * @param title the title of the page
 * @constructor
 */
export default function Header({ title, showBackButton = true }: { title: string, showBackButton?: boolean }) {
    const theme = useTheme();

    return (
        <>
            <Logo />
            <Toolbar
                sx={{
                    borderBottom: `1px solid ${theme.palette.divider}`,
                    marginBottom: theme.spacing(3)
                }}
            >
                {showBackButton &&
                    <BackButton />
                }

                <Typography
                    align="left"
                    color="primary"
                    component="h2"
                    noWrap
                    sx={{ flex: 1 }}
                    variant="h5"
                >
                    {title}
                </Typography>

                <LogoutButton />
            </Toolbar>
        </>
    );
}
