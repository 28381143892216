import { types } from '../actions/actionTypes/actionTypes';

const initialState: any = [];

export const filesReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case types.filesRead:
            return [
                ...action.payload.data,
            ]
        case types.allFiles:
            return [
                ...action.payload.data,
            ]
        case types.filterFiles:
            return [
                ...action.payload.data,
            ]
        case types.sortFiles:
            return [
                ...action.payload.data,
            ]
        case types.updateFiles:
            return [
                ...action.payload.data,
            ]
        case types.cleanFilesList:
            return initialState

        default:
            return state;
    }
}