/**
 * @function
 * This function is intended to search for a file from the
 * file redux state, and filter it by name to retrieve its properties.
 * @param {String} nameOfFile: filename used to filter in files redux state.
 * @param {Array} filesReduxState: filesScreen redux state.
 * @returns desired file object from file redux array.
 */

export default function filterFileByName(nameOfFile: any, filesReduxState: any): any {
    const fileToBeFiltered = filesReduxState.filter((file: any) => {
        const fName = file?.fileName.split(".xml")[0];
        if (fName === nameOfFile) return file;
        else return false;
    });

    return fileToBeFiltered;
};
