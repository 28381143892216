import { htmlToDTOPart } from "../../../helpers/articles/saveArticle/processEditorArticle";
import { StreamState } from "../../../interfaces/StreamState.interface";


/**
 * @function createStream
 * @description Creates an audio stream from a text part
 * @param streamState {Object} - The stream state
 */
export const createStream = async (streamState: StreamState) => {
    const { parts, selectedPart } = streamState;
    const data = parts[selectedPart];

    let sendData = { ...data };

    // parse content as ssml (remove span tags)
    let { contentHtml } = sendData;

    // process output: change span tags for ssml
    //let newContent = preprocessPart(content, data.voice.TTS);
    return htmlToDTOPart(contentHtml, parts[selectedPart]);
}
