import { AnyAction } from "redux";

// types
import { types } from './actionTypes/actionTypes';

// action creator
import { actionCreator } from "./actionCreator/actionCreator";


export const setFullAudioUrl = (url: string): AnyAction => {
    return actionCreator(types.loadAudioMP3, url);
}

export const setAudioUrl = (url: string): AnyAction => {
    return actionCreator(types.setAudioUrl, url);
}

export const resetAudioState = (): AnyAction => {
    return actionCreator(types.resetAudioState);
}
