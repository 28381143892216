import React, { useState, useEffect, useDeferredValue } from 'react';

// modules
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import Flag from 'react-world-flags';

// mui
import {
    Box,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Typography,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FemaleIcon from '@mui/icons-material/Female';
import MaleIcon from '@mui/icons-material/Male';

// styles
import "../../styles/country-flags.css";

// data
import { getFlagCode } from '../../../../../../data/languageLabels';

// interfaces
import { Selector } from "../../../../../../interfaces/Selector.interface";
import { Voice } from '../../../../../../interfaces/article/Voice.interface';

// actions
import { editorPartUpdate } from "../../../../../../actions/articles.actions";
import { partsUpdate } from "../../../../../../actions/parts.actions";
import { loadVoices } from "../../../../../../actions/voices.actions";


export const VoiceSelector = React.memo(() => {
    const dispatch = useDispatch();

    // state selector
    const languages = useSelector((state: Selector) => state.languages.languageList);
    const parts = useSelector((state: Selector) => state.parts);
    const selectedLanguage = useSelector((state: Selector) => state.article.editor.selectedLanguage);
    const selectedPart = useSelector((state: Selector) => state.article.editor.selectedPart);
    const userId = useSelector((state: Selector) => state.auth.uid);
    const userRole = useSelector((state: Selector) => state.auth.role);
    const voiceList = useSelector((state: Selector) => state.voices.voiceList);

    const [selectedVoice, setSelectedVoice] = useState(voiceList[0]);
    const [voices, setVoices] = useState([] as Voice[]);
    const deferredVoices = useDeferredValue(voices);

    const [loadingVoices, setLoadingVoices] = useState(false);
    const deferredUserId = useDeferredValue(userId);

    // effect to load voices on user change
    useEffect(() => {
        if (!loadingVoices && voiceList.length === 0) setLoadingVoices(true);
        // eslint-disable-next-line
    }, [deferredUserId])

    useEffect(() => {
        if (loadingVoices) {
            loadVoices().then(action => {
                dispatch(action);
                setLoadingVoices(false);
            })
        }
        // eslint-disable-next-line
    }, [loadingVoices]);

    useEffect(() => {
        if (!selectedLanguage) return;
        if (selectedLanguage === selectedVoice?.language) {
            let filteredVoices = voiceList.filter(v => v.enabled && v.visible && v.language === selectedLanguage);
            filteredVoices = addSelectedVoice(selectedVoice, filteredVoices);
            setVoices(filteredVoices);
        } else {
            let filteredVoices = voiceList.filter(v => v.language === selectedLanguage);
            filteredVoices = addSelectedVoice(selectedVoice, filteredVoices);
            setVoices(filteredVoices);
        }
        // eslint-disable-next-line
    }, [voiceList, selectedVoice, selectedLanguage])

    useEffect(() => {
        if (typeof parts[selectedPart] !== 'undefined') {
            const selectedPartVoice = parts[selectedPart].voice;
            if (selectedPartVoice && selectedPartVoice !== selectedVoice) {
                setSelectedVoice(selectedPartVoice);
            }
        }
        // eslint-disable-next-line
    }, [dispatch, parts, selectedPart, selectedVoice])


    const handleVoiceChange = (e: SelectChangeEvent<string>) => {
        const voiceName = e.target.value;

        // get voice default parameters
        const filteredVoice = voiceList.filter(v => v.name === voiceName)[0];
        const currentLanguage = languages.filter(l => l.code === selectedLanguage)[0];

        if (filteredVoice) {
            setSelectedVoice(filteredVoice);

            const newParts = parts.map(p => p.order === selectedPart
                ? {
                    ...p,
                    prosodyPitch: 1,
                    prosodyRate: 1,
                    prosodyVolume: 1,
                    voice: filteredVoice,
                    language: currentLanguage
                }
                : p
            )

            dispatch(partsUpdate(newParts));
            dispatch(editorPartUpdate(true));
        }
    };

    const handleBulkVoiceChange = (e: SelectChangeEvent<string>) => {
        let voiceName = e.target.value

        // get voice default parameters
        let filteredVoice = voiceList.filter(v => v.name === voiceName)[0];

        if (filteredVoice) {
            const newParts = parts.map(p => p.voice.name === selectedVoice.name
                ? {
                    ...p,
                    voice: filteredVoice,
                }
                : p
            )

            setSelectedVoice(filteredVoice);
            dispatch(partsUpdate(newParts));
        }
    };

    const handleOptionClick = (voice: Voice) => {
        let voiceFilter = voiceList.filter(v => v.name === voice.name);
        if (voiceFilter.length === 0) return;
        let {
            name,
            TTS,
            TTSvoice,
            ssmlGender,
            pitch,
            speakingRate,
            description
        } = voiceFilter[0]

        Swal.fire({
            title: name,
            icon: 'info',
            html: `<div style='text-align:left;'>
                <table>
                    ${userRole === "SuperAdmin" ? `<tr><td><b>TTS</b></td><td>${TTS.charAt(0)?.toUpperCase() + TTS.slice(1)}<td></tr>` : ""}
                    ${userRole === "SuperAdmin" ? `<tr><td><b>Voice ID</b></td><td>${TTSvoice}<td></tr>` : ""}
                    <tr><td><b>Gender</b></td><td>${ssmlGender}<td></tr>
                    <tr><td><b>Pitch</b></td><td>${pitch}<td></tr>
                    <tr><td><b>Speed rate</b></td><td>${speakingRate}<td></tr>
                    <tr><td style='padding-right:10px;white-space:nowrap'><b>Description</b></td><td>${description || 'No description'}<td></tr>
                </table>        
            </div>
            `,
            padding: '3em',
        })
    }

    const addSelectedVoice = (selectedVoice: Voice, filteredVoices: Voice[]) => {
        if (!selectedVoice) return filteredVoices;
        const newVoiceList = [...filteredVoices]
        if (selectedVoice) {
            const selectedMatch = filteredVoices.filter(v => v.name === selectedVoice?.name);
            if (selectedMatch.length === 0) newVoiceList.push({ ...selectedVoice, visible: true });
        }
        return newVoiceList;
    }


    return (
        <Box sx={{ textAlign: 'left' }}>
            <FormControl fullWidth>
                <InputLabel id="voiceSelectorLabel">Voice</InputLabel>
                <Select
                    id="voiceSelector"
                    data-test="voice-selector"
                    label="Voices"
                    labelId="voiceSelectorLabel"
                    onChange={handleVoiceChange}
                    value={(voices.length > 0) ? selectedVoice?.name || "" : ""}
                >
                    {
                        voices.map(voice => {
                            return voice?.visible &&
                                <MenuItem
                                    key={`voice-${voice.id}-${voice.name}-${voice.language}`}
                                    data-test={`voiceselector-${voice.name.toLowerCase()}`}
                                    value={voice.name}
                                >
                                    <Grid container>
                                        <Grid item xs={10} sx={{
                                            display: 'flex',
                                            flexDirection: "row"
                                        }}>
                                            <Flag
                                                id="flag-img-voice"
                                                code={getFlagCode(voice.language)}
                                                fallback={<span></span>}
                                            />
                                            {
                                                voice.ssmlGender === "MALE"
                                                    ? <MaleIcon sx={{
                                                        color: '#CCC',
                                                        marginRight: "5px"
                                                    }} />
                                                    : <FemaleIcon sx={{
                                                        color: '#CCC',
                                                        marginRight: "5px"
                                                    }} />
                                            }
                                            <Typography
                                                component='div'
                                                sx={
                                                    voice.language !== selectedLanguage
                                                        ? {
                                                            color: 'rgba(0, 0, 0, 0.5)',
                                                            fontStyle: 'italic',
                                                        }
                                                        : {}
                                                }>
                                                {voice.name}&nbsp;({voice.TTS?.toUpperCase().slice(0, 1)})
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <VisibilityIcon
                                                onClick={(e) => handleOptionClick(voice)}
                                                sx={{
                                                    color: '#CCC',
                                                    '&:hover': {
                                                        color: '#e91e63'
                                                    }
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </MenuItem>
                        })
                    }
                </Select>
            </FormControl>

            <FormControl
                fullWidth
                sx={{ mt: 2 }}
            >
                <InputLabel id="bulkVoiceSelectorLabel">Bulk Voice Replacement</InputLabel>
                <Select
                    id="bulkVoiceSelector"
                    data-test="voicebulk-selector"
                    label="Bulk Voice Replacement"
                    labelId="bulkVoiceSelectorLabel"
                    onChange={handleBulkVoiceChange}
                    value={(deferredVoices.length > 0) ? selectedVoice?.name || "" : ""}
                >
                    {
                        deferredVoices.map(voice => {
                            return voice?.visible &&
                                <MenuItem
                                    key={`bulk-${voice.id}-${voice.name}-${voice.language}`}
                                    data-test={`voicebulkselector-${voice.name.toLowerCase()}`}
                                    value={voice.name}
                                >
                                    <Grid container>
                                        <Grid item xs={10} sx={{
                                            display: 'flex',
                                            flexDirection: "row"
                                        }}>
                                            <Flag
                                                id="flag-img-voice"
                                                code={getFlagCode(voice.language)}
                                                fallback={<span></span>}
                                            />
                                            {
                                                voice.ssmlGender === "MALE"
                                                    ? <MaleIcon sx={{
                                                        color: '#CCC',
                                                        marginRight: "5px"
                                                    }} />
                                                    : <FemaleIcon sx={{
                                                        color: '#CCC',
                                                        marginRight: "5px"
                                                    }} />
                                            }
                                            <Typography
                                                component='div'
                                                sx={
                                                    voice.language !== selectedLanguage
                                                        ? {
                                                            color: 'rgba(0, 0, 0, 0.5)',
                                                            fontStyle: 'italic',
                                                        }
                                                        : {}
                                                }>
                                                {voice.name}&nbsp;({voice.TTS?.toUpperCase().slice(0, 1)})
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <VisibilityIcon
                                                onClick={() => handleOptionClick(voice)}
                                                sx={{
                                                    color: '#CCC',
                                                    '&:hover': { color: '#e91e63' }
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </MenuItem>
                        })
                    }
                </Select>
            </FormControl>
        </Box >
    );
});
