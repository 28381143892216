import { AnyAction } from 'redux';
import { types } from '../actions/actionTypes/actionTypes';
import { VoicesState } from '../interfaces/Selector.interface';

const initialState: VoicesState = {
    voiceList: [],
    thesaurusVoiceId: -1
}

export const voicesReducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case types.voicesLoad:
            return {
                ...state,
                voiceList: action.payload.data
            } as VoicesState

        case types.voicesUpdate:
            return {
                ...state,
                voiceList: action.payload.data
            } as VoicesState

        case types.voicesLogout:
            return initialState;

        case types.thesaurusVoiceUpdate:
            return {
                ...state,
                thesaurusVoiceId: action.payload.data
            } as VoicesState

        default:
            return state
    }
}
