// components
import Header from '../editorScreen/header/Header';
import { FilesTable } from './filesTable/FilesTable';
import { FileSearch } from './filesSearch/FilesSearch';

// mui
import { Container, CssBaseline } from '@mui/material';


export const FileScreen = () => {
    return (
        <Container maxWidth={false}>
            <CssBaseline />
            <Container maxWidth="lg">
                <Header title='File Management' />
            </Container>

            <FileSearch />

            <FilesTable />

        </Container>
    )
}
