import { useHistory, useLocation } from 'react-router-dom';

// mui
import Button from '@mui/material/Button';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';


export const BackButton = () => {
    const history = useHistory();
    const location = useLocation();

    const handleReturn = () => {
        if (location?.pathname === '/users') {
            history.push('/');
        } else {
            if (history.length <= 2) {
                history.push('/');
            } else {
                history.goBack();
            }
        }
    }

    return (
        <Button
            color="primary"
            onClick={handleReturn}
            size="small"
            startIcon={<ArrowBackIosIcon />}
            sx={{ height: 30, maxWidth: 100, marginRight: 10 }}
            variant="outlined"
        >
            Back
        </Button>
    )
}
