export const textTags = [
    {
        "id": "break-05",
        "html": ' <span name="break-0" class="break-tag" contenteditable="false">0.5s</span> ',
        "tagValue": '0.5s',
    },
    {
        "id": "break-10",
        "html": ' <span name="break-1" class="break-tag" contenteditable="false">1.0s</span> ',
        "tagValue": '1s',
    },
    {
        "id": "bad-syntax",
        "html": '<span name="bad-syntax" class="bad-syntax" contenteditable="false">PLACEHOLDER</span>',
        "tagValue": 'badSyntax',
    },
    {
        "id": "flag-word",
        "html": '<span name="flag-word" class="flag-word">PLACEHOLDER</span>',
        "tagValue": 'flagWord',
    },
    {
        "id": "flag-lexicon",
        "html": '<span name="flag-lexicon" title="TOOLTIP" class="flag-lexicon">PLACEHOLDER</span>',
        "tagValue": 'flagLexicon',
    },
    {
        "id": "flag-liaison",
        "html": '<span name="flag-liaison" class="flag-liaison">PLACEHOLDER</span>',
        "tagValue": 'flagLiaison',
    },
]