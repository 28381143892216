import Swal from 'sweetalert2';

// helpers
import { htmlToDTOArticle } from "./saveArticle/processEditorArticle";
import api from "../services/api";

// interfaces
import { Article } from '../../interfaces/article/Article.interface';
import { ArticlePart } from '../../interfaces/article/ArticlePart.interface';
import { ArticleSaveBody } from '../../interfaces/services/api.interfaces';


const defaultLanguageCode = process.env.DEFAULT_LANGUAGE || 'fr-FR';

/**
 * Save article to database
 */
interface ArticleProps {
    fileName: string;
    fileId: number;
    articleParts: ArticlePart[];
    activeArticle: Article;
}

export const saveArticle = async (userData: any, articleProps: ArticleProps, externallyCalled = false): Promise<Response> => {
    const { uid: userId } = userData;
    const { fileName, fileId, articleParts, activeArticle } = articleProps;
    const articleData: any = htmlToDTOArticle(fileName, articleParts, userId);
    if (fileName.length === 0) throw new Error('error_length');

    const introMusicId = activeArticle.introMusicEffect?.id;
    const introMusicVolume = activeArticle.introMusicEffect?.volume;
    const introMusicEffect = {
        id: introMusicId,
        volume: introMusicVolume
    };

    const titleMusicId = activeArticle.titleMusicEffect?.id;
    const titleMusicVolume = activeArticle.titleMusicEffect?.volume;
    const titleMusicEffect = {
        id: titleMusicId,
        volume: titleMusicVolume
    };

    const outroMusicId = activeArticle.outroMusicEffect?.id;
    const outroMusicVolume = activeArticle.outroMusicEffect?.volume;
    const outroMusicEffect = {
        id: outroMusicId,
        volume: outroMusicVolume
    };

    const backgroundMusicId = activeArticle.backgroundMusicEffect?.id;
    const backgroundMusicVolume = activeArticle.backgroundMusicEffect?.volume;
    const backgroundEffect = {
        id: backgroundMusicId,
        volume: backgroundMusicVolume
    };

    const body: ArticleSaveBody = {
        id: fileId,
        name: fileName,
        parts: articleData.parts,
        language: defaultLanguageCode,
        introMusicEffect: introMusicEffect.id > 0 ? introMusicEffect : null,
        titleMusicEffect: titleMusicEffect.id > 0 ? titleMusicEffect : null,
        outroMusicEffect: outroMusicEffect.id > 0 ? outroMusicEffect : null,
        backgroundMusicEffect: backgroundEffect.id > 0 ? backgroundEffect : null,
        introActivated: activeArticle.introActivated,
        imageUrl: activeArticle.imageUrl
    }

    const result = await api.article.save(body);

    if (!externallyCalled && result.status < 400) {
        Swal.fire('Success', 'The file has been succesfully saved.', 'success');
    }

    return result;
}
