import React from 'react';
import { useFormik } from 'formik';

import Swal from 'sweetalert2';
import * as yup from 'yup';

// modules
import { useDispatch, useSelector } from 'react-redux';

// mui
import { useTheme } from '@mui/material/styles';
import { Box, Button, Checkbox, FormControlLabel, Stack, TextField, Typography } from '@mui/material'

// helpers
import { createNewPlaylist } from '../../helpers/createNewPlaylist';
import { Selector } from "../../interfaces/Selector.interface";
import { loadAllFiles } from "../../actions/files.actions";
import { useEffect } from "react";
import { PlaylistToCreate } from "../../interfaces/services/api.interfaces";
import { loadPlaylists } from '../../actions/playlist.action';


interface Props {
    handleModalClose: () => void
}

export const NewPlaylistPopup = ({ handleModalClose }: Props) => {
    const theme = useTheme();
    const dispatch = useDispatch();

    // state selector
    const files = useSelector((state: Selector) => state.files);

    const initialFormValues: PlaylistToCreate = {
        name: '',
        articleIds: [],
    }

    const validationShape = {
        name: yup
            .string()
            .min(1)
            .required('Playlist name is required'),
        articleIds: yup
            .array()
            .min(1)
            .required('Add articles to your playlist')
    }

    const validationSchema = yup.object().shape(validationShape);

    const formik = useFormik({
        initialValues: initialFormValues,
        validationSchema,
        onSubmit: (values: any) => {
            handleSubmit(values);
        },
    });

    const handleSubmit = (values: any) => {
        const newPlaylist = {
            name: values.name,
            articleIds: values.articleIds,
        }

        createNewPlaylist(newPlaylist).then(resp => {
            handleModalClose()
            if (resp.status === 200 || resp.status === 201) {
                Swal.fire('Success', 'Playlist created successfully', 'success');
                loadPlaylists().then(action => dispatch(action))
            }
            else if (resp.status === 409){
                Swal.fire('Error', 'Playlist with the name <strong>' + newPlaylist.name +  '</strong> already exists', 'error');
            }
            else {
                Swal.fire('Error', `There was a problem trying to create the playlist`, 'error');
            }
        })
    }

    // Update the selected article ID list
    const handleSelectedArticle = (id: number) => {
        if (formik.values.articleIds.includes(id)) {
            const index = formik.values.articleIds.indexOf(id);
            formik.values.articleIds.splice(index, 1);
        }
        else {
            formik.values.articleIds.push(id);
        }
    }

    useEffect(() => {
        loadAllFiles().then(action => dispatch(action))
    }, [dispatch]);

    return (
        <Box
            data-test="popup-playlist"
            component='div'
            sx={{ minWidth: '90%' }}
        >
            <form onSubmit={formik.handleSubmit} autoComplete="off">
                <Stack spacing={2}>
                    <Typography
                        data-test="popup-playlist-label"
                        color={theme.palette.primary.main}
                        component='div'
                        variant='h6'
                    >
                        Create a new playlist and add files to it
                    </Typography>

                    <TextField
                        data-test="popup-playlist-text-name"
                        id="name"
                        name="name"
                        label="Playlist name"
                        variant="outlined"
                        autoComplete="off"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                    />

                    <Stack
                        alignItems="center"
                        direction="column"
                        justifyContent="center"
                        spacing={{ xs: 1, sm: 2, md: 4 }}
                    >
                        {files.map((file => (
                            (file.audioMediaFile != null)
                                ? (<FormControlLabel
                                    data-test={`popup-playlist-item-${file.fileName}`}
                                    key={file.id}
                                    control={
                                        <Checkbox
                                            data-test={`popup-playlist-check-${file.fileName}`}
                                            key={`checkbox-${file.id}`}
                                            id={`${file.id}`}
                                            onChange={() => handleSelectedArticle(file.id)}
                                        />
                                    }
                                    label={
                                        <Box sx={{ display: 'inline' }}>
                                            <Typography sx={{ fontSize: '14px', fontWeight: 'bold', display: 'inline' }}>
                                                {file.fileName}
                                            </Typography>
                                            <Typography sx={{ fontSize: '14px', display: 'inline' }}>
                                                {' - ' + file.publisher}
                                            </Typography>
                                        </Box>
                                    }
                                />)
                                : <React.Fragment key={file.id}></React.Fragment>
                        )))
                        }
                    </Stack>

                    <Stack
                        alignItems="center"
                        direction="row"
                        justifyContent="center"
                        spacing={{ xs: 1, sm: 2, md: 4 }}
                    >
                        <Button
                            data-test="popup-playlist-btn-save"
                            variant="contained"
                            type="submit"
                            disabled={formik.isSubmitting}
                        >
                            Save
                        </Button>
                        <Button
                            data-test="popup-playlist-btn-cancel"
                            variant="outlined"
                            onClick={handleModalClose}
                            disabled={formik.isSubmitting}
                        >
                            Cancel
                        </Button>
                    </Stack>
                </Stack>
            </form>

        </Box>
    )
}