/**
 * @function
 * Used to check if an article part has symbols only. Also checks if article part is EMPTY or contains &nbsp; only.
 * @param {String} text: article part text.
 * @returns {Boolean} Returns true in case an article part must be deleted. False otherwise.
 */
export const doesPartHasSymbolsOnly = (text: string): boolean => {
    try {
        // Had to provide an early return for cases where text consisted of only a space or several spaces
        // given the fact that the <ContentEditable /> component escapes whitespaces as "&nbsp;".
        if (text === "&nbsp;" || text?.length <= 0) return true;

        const onlyWhiteSpacesRegExp = /&nbsp;/g;

        /**
         * RegExp to determine presence of symbols.
         */
        const specialCharactersRegExp = /[*|\":<>[\]{}`\\()';@&$\.\,\?\¿\!\¡\ \-\#/]/; //eslint-disable-line
        /**
         * RegExp to determine presence of alphanumeric characters.
         */
        const nonSpecialCharactersRegExp = /([A-Za-z0-9])/g; //eslint-disable-line
        /**
         * RegExp to determine presence of russian alphabet characters.
         */
        const cyrillicPattern = /\p{Script=Cyrillic}+/u;

        let textToBeChecked = `${text}`;

        // Transforming &nbsp; into " "
        if (onlyWhiteSpacesRegExp.test(textToBeChecked)) {
            textToBeChecked = textToBeChecked.replace(onlyWhiteSpacesRegExp, " ");
        };

        if (
            specialCharactersRegExp.test(textToBeChecked) &&
            !nonSpecialCharactersRegExp.test(textToBeChecked) &&
            !cyrillicPattern.test(textToBeChecked)
        ) {
            return true;
        }
        else {
            return false;
        };

    }
    catch (error) {
        console.error(`doesPartHasSymbolsOnly: Could not check symbols. Error: ${error}`);
        return false;
    };
};
