// redux
import { useDispatch } from 'react-redux';

// mui
import { Box, Stack } from '@mui/material';

// actions

// interfaces
import {ItemIcon} from "./itemIcon/ItemIcon";
import {Playlist} from "../../../../../../interfaces/Playlist.interface";
import {ItemTitle} from "./itemTitle/ItemTitle";
import {setActivePlaylist} from "../../../../../../actions/playlist.action";



interface Props {
    item: Playlist,
}

export const PlaylistItem = ({ item }: Props) => {

    const dispatch = useDispatch();

    const handleActivePlaylist = (playlistId: number) => {
        dispatch(setActivePlaylist(playlistId));
    }

    return (
        <Box>
            <Stack
                direction='row'
                spacing={1}
                sx={{ alignItems: 'center', display: 'flex', cursor: 'pointer' }}
                onClick={() => handleActivePlaylist(item.id)}
            >
                <ItemIcon item={item} />
                <ItemTitle name={item.name}/>
            </Stack>
        </Box>
    )
}
