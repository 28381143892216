import React, { useEffect, useRef, useState } from 'react';

// modules
import ReactPlayer from 'react-player';
import { useSelector } from 'react-redux';

// components
import { Duration } from './duration/Duration'
import { SpeedRateSelectorMenu } from './speedRateSelector/SpeedRateSelectorMenu'
import { Player } from '../../../../generics/player/Player'

// mui
import { useTheme } from '@mui/material/styles';
import { CircularProgress, Grid, IconButton, Typography } from '@mui/material';
import Forward10Icon from '@mui/icons-material/Forward10';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import Replay10Icon from '@mui/icons-material/Replay10';

// interfaces
import { PlayerState } from '../../../../../interfaces/PlayerState.interface'
import { Selector } from '../../../../../interfaces/Selector.interface';
import { StreamState } from '../../../../../interfaces/StreamState.interface';

// player handlers
import {
    handlePlayPauseEditor,
    handleSeekChange,
    handleSeekMouseDown,
    handleSeekMouseUp,
    handleSeekTo,
} from '../../../../generics/player/playerHandlers';


interface Props {
    playerState: PlayerState,
    streamEnabled: boolean,
    setPlayerState: React.Dispatch<React.SetStateAction<PlayerState>>,
    activePartState: number,
    setActivePartState: React.Dispatch<React.SetStateAction<number>>,
    streamState: StreamState,
    setStreamState: React.Dispatch<React.SetStateAction<StreamState>>
}

export const AudioEditorPlayer = React.memo((
    {
        playerState,
        streamEnabled,
        setPlayerState,
        activePartState,
        setActivePartState,
        streamState,
        setStreamState
    }: Props
) => {
    const theme = useTheme();

    const playerRef = useRef<ReactPlayer>(null);

    // state selector
    const articleParts = useSelector((state: Selector) => state.parts);
    const config = useSelector((state: Selector) => state.config.config);
    const editor = useSelector((state: Selector) => state.article.editor);
    const voiceList = useSelector((state: Selector) => state.voices.voiceList);

    const [position, setPosition] = useState(0);


    // effect to set the played sections
    useEffect(() => {
        setPosition(playerState.playedSeconds);
    }, [playerState.playedSeconds])

    // effect to set update stream state
    useEffect(() => {
        setStreamState({
            enabled: true,
            config,
            voices: voiceList,
            parts: articleParts,
            selectedPart: editor.selectedPart,
            selectedLanguage: editor.selectedLanguage
        })

        let newActivePart = editor?.selectedPart || 0;
        setActivePartState(newActivePart);
        if (streamEnabled) {
            setPlayerState(state => ({
                ...state,
                loadAgain: true,
            }))
        }
    }, [
        config, voiceList, articleParts, editor.selectedPart, editor.selectedLanguage,
        streamEnabled, setActivePartState, setPlayerState, setStreamState
    ])


    return (
        <Grid
            container
            flexWrap='nowrap'
            position='sticky'
            sx={{ backgroundColor: 'background.paper' }}
            top='48px'
            zIndex={1}
        >
            <Grid
                item
                alignContent={'center'}
                paddingLeft={2}
                sx={{ '&': { maxWidth: '100%' } }}
                xs={2}
            >
                <Grid display={'flex'} flexWrap={'wrap'} container>
                    <Grid flexGrow={'1'} item>
                        <Player
                            playerState={playerState}
                            setPlayerState={setPlayerState}
                            playerRef={playerRef}
                        />
                    </Grid>
                    <Grid sx={{
                        '&': {
                            flexWrap: 'nowrap',
                            display: 'flex',
                            flexGrow: '1'
                        }
                    }} item>

                        <IconButton
                            aria-label="replay10"
                            onClick={() => {
                                if (playerRef.current) handleSeekTo(position, playerRef.current, -10)
                            }}
                        >
                            <Replay10Icon />
                        </IconButton>

                        <IconButton
                            aria-label="startPause"
                            data-test={`audioplayer-btn-${playerState.playing ? 'pause' : 'play'}`}
                            onClick={() => handlePlayPauseEditor(streamState, playerState, setPlayerState)}
                            color='secondary'
                            disabled={
                                !!(articleParts?.length > 0 && articleParts[activePartState]?.hasSyntaxError)
                                || (playerState.playing && (!playerState.duration || playerState.isBuffering))
                            }
                            sx={{ fontSize: 32 }}
                        >
                            {
                                playerState.playing && (!playerState.duration || playerState.isBuffering)
                                    ? <CircularProgress color='secondary' size={32} />
                                    : playerState.playing
                                        ? <PauseCircleIcon fontSize='inherit' />
                                        : <PlayCircleFilledWhiteIcon fontSize='inherit' />
                            }
                        </IconButton>

                        <IconButton
                            aria-label="forward10"
                            onClick={() => {
                                if (playerRef.current) handleSeekTo(position, playerRef.current, 10)
                            }}
                        >
                            <Forward10Icon />
                        </IconButton>

                        <SpeedRateSelectorMenu
                            playbackRate={playerState.playbackRate}
                            playbackRateLabel={playerState.playbackRateLabel}
                            setPlayerState={setPlayerState}
                        />
                    </Grid>
                </Grid>
            </Grid>

            <Grid
                item
                xs={10}
                sx={{
                    mt: 1.5,
                    '&': { maxWidth: "100%" }
                }}
            >
                <Grid
                    container
                    display='flex'
                    justifyContent="center"
                >
                    <Grid
                        item
                        xs={1}
                        pt={'4px'}
                        sx={{ textAlign: 'center' }}
                    >
                        <Typography
                            data-test="audioplayer-label-played"
                            component='div'
                            color={theme.palette.grey[400]}
                            fontWeight='bold'
                        >
                            <Duration seconds={playerState.duration * playerState.played} />
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={10}
                        sx={{ textAlign: 'center' }}
                    >
                        <input
                            type='range'
                            min={0}
                            max={0.999999}
                            step='any'
                            value={playerState.played}
                            onChange={e => handleSeekChange(parseFloat(e.currentTarget.value), setPlayerState)}
                            onMouseDown={() => handleSeekMouseDown(setPlayerState)}
                            onMouseUp={e => {
                                if (playerRef.current) {
                                    handleSeekMouseUp(
                                        parseFloat(e.currentTarget.value), playerRef.current, setPlayerState
                                    )
                                }
                            }}
                            style={{
                                height: '5px',
                                width: '100%',
                            }}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={1}
                        pt={'4px'}
                        sx={{ textAlign: 'center' }}
                    >
                        <Typography
                            data-test="audioplayer-label-duration"
                            component='div'
                            color={theme.palette.grey[400]}
                            fontWeight='bold'
                        >
                            <Duration seconds={playerState.duration} />
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
})