import React from 'react';

// modules
import { useSelector } from 'react-redux';

// components
import { UserTableButtons } from './UserTableButtons';

// mui
import {
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';

// styled
import { HeaderCell } from '../../../../../theme/styled/HeaderCell';
import { BodyCell } from '../../../../../theme/styled/BodyCell';

import { Selector } from '../../../../../interfaces/Selector.interface';


export const UserTable = React.memo(() => {

    // state selector
    const role = useSelector((state: Selector) => state.auth.role);
    const usersList = useSelector((state: Selector) => state.users.usersList);


    return (
        <TableContainer component={Paper} sx={{ overflowX: "initial" }}>
            <Table aria-label="users-table">
                <TableHead>
                    <TableRow>
                        <HeaderCell>Id</HeaderCell>
                        <HeaderCell>User</HeaderCell>
                        <HeaderCell>Email</HeaderCell>
                        <HeaderCell>Role</HeaderCell>
                        {role === 'SuperAdmin' && <HeaderCell>Client</HeaderCell>}
                        {role === 'SuperAdmin' && <HeaderCell>Generation mode</HeaderCell>}
                        <HeaderCell align="center"></HeaderCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {
                        usersList.map(user =>
                            <TableRow key={user.id}>
                                <BodyCell>{user.id}</BodyCell>
                                <BodyCell>{user.name}</BodyCell>
                                <BodyCell>{user.email}</BodyCell>
                                <BodyCell>{user.typeDescription}</BodyCell>
                                {role === 'SuperAdmin' && <BodyCell>{user.client}</BodyCell>}
                                {role === 'SuperAdmin' && <BodyCell>{user.generationMode}</BodyCell>}
                                <BodyCell align="center">
                                    <UserTableButtons userId={user.id} />
                                </BodyCell>
                            </TableRow>
                        )
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
})
