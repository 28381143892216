// modules
import { Link } from 'react-router-dom';

// mui
import { useTheme } from '@mui/material/styles';
import { MenuItem, Stack, SvgIconTypeMap, Typography } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent';


interface Props {
    label: string,
    Icon: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
        muiName: string
    },
    handleClose: () => void
}

export const MenuElement = ({ label, Icon, handleClose }: Props) => {
    const theme = useTheme();

    return (
        <MenuItem
            component={Link}
            to={`/${label.toLowerCase()}`}
            key={`config-menu-${label.toLowerCase()}`}
            onClick={handleClose}
            sx={{ pl: 3, py: 1.5 }}
        >
            <Stack direction='row' spacing={2}>
                <Icon htmlColor={theme.palette.text.secondary} />
                <Typography textAlign="center" color={theme.palette.text.secondary}>{label}</Typography>
            </Stack>
        </MenuItem>
    )
}
