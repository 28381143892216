import { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';

// components
import Header from '../editorScreen/header/Header';

// actions
import { loadConfig } from '../../../actions/config.actions';

// mui
import { CssBaseline, Container } from '@mui/material';

// interfaces
import { Selector } from "../../../interfaces/Selector.interface";
import { LexiconTable } from './lexiconTable/LexiconTable';



export const ThesaurusConfigScreen = () => {

    const dispatch = useDispatch();

    // state selector
    const selectedConfig = useSelector((state: Selector) => state.config.selectedConfig);
    const [loadingConfig, setLoadingConfig] = useState(false);

    // effect to load config on user change
    useEffect(() => {
        if (!loadingConfig) setLoadingConfig(true);
        // eslint-disable-next-line
    }, [selectedConfig])

    useEffect(() => {
        if (loadingConfig) {
            loadConfig().then(action => {
                dispatch(action);
                setLoadingConfig(false);
            })
        }
    }, [dispatch, loadingConfig, selectedConfig]);

    return (
        <Container maxWidth={false} >
            <CssBaseline />
            <Container maxWidth="lg">
                <Header title='Thesaurus Editor' showBackButton={false} />
            </Container>

            <LexiconTable />
        </Container>
    )
}
