import React, { useEffect, useState } from "react";

// modules
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Swal, { SweetAlertResult } from 'sweetalert2';
import Flag from "react-world-flags";

// components
import { ConfigButtons } from '../configButtons/ConfigButtons';

// mui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Collapse,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    List,
    ListItem,
    ListItemText,
    MenuItem,
    Paper,
    Select,
    SelectChangeEvent,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LanguageIcon from '@mui/icons-material/Language';
import FilterListIcon from '@mui/icons-material/FilterList';

// actions
import { deleteVoice } from "../../../../actions/config.actions";

// styled
import { HeaderCell } from '../../../../theme/styled/HeaderCell';
import { Selector } from "../../../../interfaces/Selector.interface";

// helpers
import { getFlagCode } from "../../../../data/languageLabels";

// interfaces
import { ConfigVoices } from "../../../../interfaces/config/Config.interface";
import { Voice } from "../../../../interfaces/article/Voice.interface";
import { Language } from "../../../../interfaces/article/Language.interface";


const headerColumns = [
    { name: 'Language', userRole: 'User' },
    { name: 'Voice Name', userRole: 'User' },
    { name: 'TTS', userRole: 'SuperAdmin' },
    { name: 'TTS Voice', userRole: 'SuperAdmin' },
    { name: 'Gender', userRole: 'User' },
    { name: 'Pitch', userRole: 'User' },
    { name: 'Rate', userRole: 'User' },
    { name: 'Volume', userRole: 'User' },
    { name: 'Status', userRole: 'User' },
    { name: 'Description', userRole: 'User' },
    { name: 'Automatic Version', userRole: 'SuperAdmin' },
    { name: 'Emotion', userRole: 'User' },
    { name: 'Order', userRole: 'User' },
    { name: 'Scope', userRole: 'User' },
    { name: 'Quality', userRole: 'User' },
    { name: '', userRole: 'User' },
]

export const VoicesTable = React.memo(() => {
    const theme = useTheme();
    const dispatch = useDispatch();

    // state selector
    const config = useSelector((state: Selector) => state.config.config);
    const selectedConfig = useSelector((state: Selector) => state.config.selectedConfig);
    const uid = useSelector((state: Selector) => state.auth.uid);
    const role = useSelector((state: Selector) => state.auth.role);
    const languageList = useSelector((state: Selector) => state.languages.languageList);

    const [tableRows, setTableRows] = useState<ConfigVoices>({});
    const [open, setOpen] = useState(false);
    const [openRow, setOpenRow] = useState("");
    const [languages, setLanguages] = useState<Language[]>([...languageList]);

    const [languageFilter, setLanguageFilter] = useState('');
    const [nameFilter, setNameFilter] = useState('');
    const [statusFilter, setStatusFilter] = useState('');
    const [scopeFilter, setScopeFilter] = useState('');


    useEffect(() => {
        const configVoices = config.filter(c => c.name?.toLowerCase() === 'voices')[0]?.data as ConfigVoices;

        const voiceEntries = Object.entries(configVoices).map(([key, value]) => [key, { ...value, visible: true }])

        // fix visibility when config changes
        const resetVoices = Object.fromEntries(voiceEntries);

        setTableRows(resetVoices);

    }, [config, selectedConfig]);

    useEffect(() => {
        setLanguages([...languageList]);
    }, [languageList])


    const handleDelete = (key: string) => {
        Swal.fire({
            title: `Are you sure you want to delete '${key}'?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel'
        }).then(async (result: SweetAlertResult<any>) => {

            const list = { ...tableRows };
            const voiceId = list[key].id;

            return new Promise((resolve, reject) => {
                if (result.isConfirmed) {
                    delete list[key];

                    const body = {
                        name: selectedConfig,
                        file: list,
                        uid
                    }
                    setTableRows(list);
                    resolve(body);
                }
            }).then((list: any) => {
                let voiceList = [];
                for (let voice in list.file) {
                    voiceList.push(list.file[voice]);
                }
                deleteVoice(voiceId).then(action => dispatch(action))
            })
        })
    }

    const handleOpen = (e: any, key: string) => {
        setOpenRow(key);
        setOpen(!open);
    }

    const handleLanguageSelectChange = (event: SelectChangeEvent) => {
        const filterValue = event.target.value as string;
        setLanguageFilter(filterValue);
        handleSearch(filterValue, nameFilter, filterValue, scopeFilter);
    };

    const handleNameTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const filterValue = event.target.value as string;
        setNameFilter(filterValue);
        handleSearch(languageFilter, filterValue, statusFilter, scopeFilter);
    }

    const handleStatusSelectChange = (event: SelectChangeEvent) => {
        const filterValue = event.target.value as string;
        setStatusFilter(filterValue);
        handleSearch(languageFilter, nameFilter, filterValue, scopeFilter);
    };

    const handleScopeSelectChange = (event: SelectChangeEvent) => {
        const filterValue = event.target.value as string;
        setScopeFilter(filterValue);
        handleSearch(languageFilter, nameFilter, statusFilter, filterValue);
    };

    const handleSearch = (language: string, name: string, status: string, scope: string) => {
        if (language.length === 0 && name.length === 0 && status.length === 0 && scope.length === 0) {
            setTableRows(rows => {
                for (const key in rows) {
                    const value = rows[key];
                    rows[key] = { ...value, visible: true }
                }
                return rows;
            });
            return;
        }

        let filteredRows: ConfigVoices = { ...tableRows };
        for (const key in filteredRows) {
            const value = filteredRows[key];
            filteredRows[key] = { ...value, visible: true }
        }

        if (language.length > 0) {
            for (const key in filteredRows) {
                const value = filteredRows[key];
                filteredRows[key] = { ...value, visible: (value.visible && value.language === language) ? true : false }
            }
        }

        if (name.length > 0) {
            for (const key in filteredRows) {
                const value = filteredRows[key];
                filteredRows[key] = {
                    ...value,
                    visible: (
                        value.visible && value.name.toLowerCase().includes(name.toLowerCase())
                    ) ? true : false
                }
            }
        }

        if (status.length > 0) {
            switch (status) {
                case "enabled":
                    for (const key in filteredRows) {
                        const value = filteredRows[key];
                        filteredRows[key] = { ...value, visible: (value.visible && value.enabled) ? true : false }
                    }
                    break;
                case "disabled":
                    for (const key in filteredRows) {
                        const value = filteredRows[key];
                        filteredRows[key] = { ...value, visible: (value.visible && !value.enabled) ? true : false }
                    }
                    break;
                default:
                    break;
            }
        }

        if (scope.length > 0) {
            switch (scope) {
                case "custom":
                    for (const key in filteredRows) {
                        const value = filteredRows[key];
                        const hasClient = typeof value.client?.name !== 'undefined';
                        filteredRows[key] = { ...value, visible: (value.visible && hasClient) ? true : false }
                    }
                    break;
                case "generic":
                    for (const key in filteredRows) {
                        const value = filteredRows[key];
                        const hasClient = typeof value.client?.name !== 'undefined';
                        filteredRows[key] = { ...value, visible: (value.visible && !hasClient) ? true : false }
                    }
                    break;
                default:
                    break;
            }
        }

        setTableRows(filteredRows);
    }


    return (
        <>
            <Stack
                direction='row'
                spacing={3}
                display='flex'
                justifyContent='center'
                alignItems='center'
                sx={{ mb: 2 }}
            >
                <FilterListIcon htmlColor={theme.palette.secondary.dark} />

                <Box sx={{ minWidth: 250 }}>
                    <FormControl fullWidth>
                        <InputLabel id="filter-voice-language-select-label">Language</InputLabel>
                        <Select
                            labelId="filter-voice-language-select-label"
                            id="filter-voice-language-select"
                            value={languageFilter}
                            label="Language"
                            onChange={handleLanguageSelectChange}
                        >
                            <MenuItem value=''>All</MenuItem>
                            {
                                languages?.length > 0 &&
                                languages
                                    .sort((a, b) => a.name < b.name ? -1 : 1)
                                    .map(l =>
                                        <MenuItem
                                            key={`filter-voice-menu-${l.code}`}
                                            value={l.code}
                                        >
                                            <Stack direction='row'>
                                                <Flag id="flag-img-language" code={getFlagCode(l.code)} />
                                                &nbsp;&nbsp;
                                                {`${l.name} : ${l.code}`}
                                            </Stack>
                                        </MenuItem>
                                    )
                            }
                        </Select>
                    </FormControl>
                </Box>

                <TextField
                    id="filter-voice-name-text-field"
                    label="Voice name"
                    variant="outlined"
                    value={nameFilter}
                    onChange={handleNameTextChange}
                    sx={{ maxWidth: 150 }}
                />

                <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                        <InputLabel id="filter-voice-status-select-label">Status</InputLabel>
                        <Select
                            labelId="filter-voice-status-select-label"
                            id="filter-voice-status-select"
                            value={statusFilter}
                            label="Status"
                            onChange={handleStatusSelectChange}
                        >
                            <MenuItem value=''>All</MenuItem>
                            <MenuItem value='enabled'>Enabled</MenuItem>
                            <MenuItem value='disabled'>Disabled</MenuItem>
                        </Select>
                    </FormControl>
                </Box>

                <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                        <InputLabel id="filter-voice-scope-select-label">Scope</InputLabel>
                        <Select
                            labelId="filter-voice-scope-select-label"
                            id="filter-voice-scope-select"
                            value={scopeFilter}
                            label="Scope"
                            onChange={handleScopeSelectChange}
                        >
                            <MenuItem value=''>All</MenuItem>
                            <MenuItem value='custom'>Custom</MenuItem>
                            <MenuItem value='generic'>Generic</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </Stack>

            <TableContainer component={Paper} sx={{ overflowX: "initial" }}>
                <Table
                    aria-label="voices-table"
                    stickyHeader
                >
                    <TableHead>
                        <TableRow>
                            {headerColumns.map(column =>
                                (role === 'SuperAdmin' || column.userRole === 'User') &&
                                <HeaderCell key={`voices-table-header-${column.name}`}>
                                    {column.name}
                                </HeaderCell>
                            )
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.entries(tableRows).map(([key, item]: [key: string, item: Voice]) => {
                            return (
                                item.visible &&
                                <TableRow hover key={`voice-${item.id}-${item.language}`}>
                                    <Tooltip title={item.language}>
                                        <TableCell>
                                            {item.language === 'multilingual'
                                                ? <LanguageIcon id="flag-img-voice" sx={{ color: '#333' }} />
                                                : <Flag id="flag-img-language" code={getFlagCode(item.language)} />
                                            }
                                        </TableCell>
                                    </Tooltip>
                                    <TableCell>{key}</TableCell>

                                    {role === 'SuperAdmin' && <TableCell>{item.TTS}</TableCell>}
                                    {role === 'SuperAdmin' && <TableCell>{item.TTSvoice}</TableCell>}

                                    <TableCell>{item.ssmlGender}</TableCell>
                                    <TableCell>{item.pitch}</TableCell>
                                    <TableCell>{item.speakingRate}</TableCell>
                                    <TableCell>{item.volume}</TableCell>
                                    {
                                        item.enabled
                                            ? <TableCell sx={{ color: theme.palette.success.main }}>Enabled</TableCell>
                                            : <TableCell sx={{ color: theme.palette.secondary.main }}>Disabled</TableCell>
                                    }
                                    <TableCell>{item.description}</TableCell>
                                    {
                                        role === 'SuperAdmin' &&
                                        (
                                            item.automaticVersionEnabled
                                                ? <TableCell sx={{ color: theme.palette.success.main }}>Enabled</TableCell>
                                                : <TableCell sx={{ color: theme.palette.secondary.main }}>Disabled</TableCell>
                                        )
                                    }
                                    {
                                        item.emotions?.length > 0
                                            ? <>
                                                <TableCell onClick={(e) => handleOpen(e, key)}>
                                                    <List dense={true} disablePadding >
                                                        <ListItem
                                                            sx={{
                                                                padding: 0,
                                                                color: theme.palette.success.main
                                                            }}
                                                        >
                                                            <ListItemText primary={'Enabled'} />
                                                            {open && openRow === key ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                                        </ListItem>
                                                    </List>
                                                    <Collapse in={open && openRow === key} timeout="auto" unmountOnExit>
                                                        <List dense={true} component="ul" disablePadding>
                                                            {item.emotions.map(emotion => (
                                                                <ListItem
                                                                    key={`voice-${item.id}-${item.language}-${emotion.id}`}
                                                                    sx={{ p: 0 }}
                                                                >
                                                                    <ListItemText primary={emotion.name} />
                                                                </ListItem>
                                                            ))}
                                                        </List>
                                                    </Collapse>
                                                </TableCell>
                                            </>
                                            : <TableCell sx={{ color: theme.palette.secondary.main }}>
                                                Disabled
                                            </TableCell>
                                    }
                                    <TableCell>{item.order}</TableCell>
                                    <TableCell>{item?.client?.name ? `Custom: ${item?.client?.name}` : 'Generic'}</TableCell>
                                    <TableCell>{item.voiceQuality}</TableCell>
                                    <TableCell align="center">
                                        {
                                            (role === "SuperAdmin" || (role === "Admin" && item?.client))
                                            &&
                                            <ConfigButtons
                                                element={key}
                                                configName={selectedConfig}
                                                handleDelete={handleDelete}
                                            />
                                        }
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            {
                role === "SuperAdmin" &&
                <Grid
                    container
                    sx={{
                        bottom: 0,
                        position: 'sticky',
                    }}
                >
                    <Grid
                        item
                        xs={12}
                        sx={{ textAlign: "center" }}
                    >
                        <IconButton
                            component={Link}
                            to={`/voice-form/new`}
                            sx={{ "&:hover": { backgroundColor: "transparent" } }}
                        >
                            <AddCircleIcon
                                htmlColor={theme.palette.secondary.main}
                                sx={{
                                    cursor: 'pointer',
                                    fontSize: '40px',
                                }}
                            />
                        </IconButton>
                    </Grid>
                </Grid>
            }
        </>
    );
})
