// mui
import { useTheme } from '@mui/material/styles';
import { Toolbar, Typography } from '@mui/material';

// img
import logo from './img/logo_odia.png';
import logo_light from './img/logo_odia_light.png';


export const Logo = () => {
    const theme = useTheme();

    return (
        <Toolbar sx={{
            borderBottom: `1px solid ${theme.palette.divider}`
        }}>
            <Typography
                align="center"
                color="inherit"
                component="h2"
                noWrap
                sx={{ flex: 1 }}
                variant="h5"
            >
                <img
                    src={theme.palette.mode === 'dark' ? logo_light : logo}
                    alt='logo'
                    style={{ maxHeight: 35 }}
                />
            </Typography>
        </Toolbar>
    );
}
