
// mui
import { useTheme } from '@mui/material/styles';
import { Box, Stack, Typography } from '@mui/material';
import VerifiedIcon from '@mui/icons-material/Verified';

// helpers
import { formatDateToString } from '../../../../../../../helpers/formatDateToString';


interface Props {
    title: string,
    creationDate: number,
    isParentArticle: boolean,
    selectedVersion: boolean,
}

export const ItemTitle = ({ title, creationDate, isParentArticle, selectedVersion }: Props) => {
    const theme = useTheme();
    const { palette } = theme;

    return (
        <Box>
            <Stack direction='column'>
                <Stack
                    direction='row'
                    spacing={1}
                    sx={{ alignItems: 'center', display: 'flex', }}
                >
                    <Typography variant={isParentArticle ? 'inherit' : 'subtitle2'}>
                        {title}
                    </Typography>
                    {selectedVersion && !isParentArticle && <VerifiedIcon sx={{ fontSize: 16 }} />}
                </Stack>
                {
                    isParentArticle &&
                    <Typography
                        variant='caption'
                        sx={{ color: palette.secondary.dark }}
                    >
                        {formatDateToString(creationDate)}
                    </Typography>
                }
            </Stack>
        </Box>
    )
}
