import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// mui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    FormControl,
    Grid,
    TextField,
    Typography
} from '@mui/material'

import { setVideoPublisherName, setVideoPublisherLogoUrl } from '../../../../../../actions/video.actions';
import { Selector } from '../../../../../../interfaces/Selector.interface';


export const PublisherSelector = React.memo(() => {
    const theme = useTheme();
    const dispatch = useDispatch();

    // state selector
    const { publisherName, publisherLogoUrl } = useSelector((state: Selector) => state.video);

    // Action handlers with dispatchers
    const handlePublisherNameChange = (event: any) => {
        dispatch(setVideoPublisherName(event.target.value));
    };

    const handlePublisherLogoUrlChange = (event: any) => {
        dispatch(setVideoPublisherLogoUrl(event.target.value));
    };

    return (
        <Box sx={{ textAlign: 'left' }}>
            <Typography
                data-test="publisherparams-label"
                sx={{ color: theme.palette.primary.main }}
            >
                Publisher parameters
            </Typography>

            <FormControl fullWidth>

                <Grid item xs sx={{ mt: 2 }}>
                    <TextField
                        data-test="publisherparams-text-name"
                        fullWidth
                        size="small"
                        label="Publisher Name"
                        id="source"
                        value={publisherName}
                        onChange={handlePublisherNameChange}
                    />
                </Grid>

                <Grid item xs sx={{ mt: 2 }}>
                    <TextField
                        data-test="publisherparams-text-logo"
                        fullWidth
                        size="small"
                        label="Publisher Logo URL"
                        id="source"
                        value={publisherLogoUrl}
                        onChange={handlePublisherLogoUrlChange}
                    />
                </Grid>

            </FormControl>
        </Box>
    );
})
