import { formatDateToString } from "../formatDateToString";

export const setFileNameLabel = (
    fileName: string,
    creationDate: number,
    showArticleName: boolean
): string => {
    if (showArticleName) {
        return fileName.replace(/\.xml$/, "");
    } else {
        return formatDateToString(creationDate);
    }
}
