import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

// components
import { AudioEditorPage } from '../../audioEditorPage/AudioEditorPage';
import { VideoEditorPage } from '../../videoEditorPage/VideoEditorPage';
import { ThesaurusDetectionPage } from '../../thesaurusDetectionPage/ThesaurusDetectionPage';
import { PlaylistPage } from "../../playlistPage/PlaylistPage";

// mui
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Selector } from "../../../../../interfaces/Selector.interface";


export const ContentPage = () => {
    const theme = useTheme();

    // status selector
    const role = useSelector((state: Selector) => state.auth.role);
    const selectedTabName = useSelector((state: Selector) => state.sidebar.selectedTabName);

    const [selectedTab, setSelectedTab] = useState('Audio');


    useEffect(() => {
        setSelectedTab(selectedTabName)
    }, [selectedTabName, setSelectedTab])


    return (
        <Box
            sx={{ marginTop: theme.spacing(6) }}
        >
            {
                selectedTab === 'Audio' && <AudioEditorPage />
            }
            {
                selectedTab === 'Video' && <VideoEditorPage />
            }
            {
                (role === "SuperAdmin" && selectedTab === 'Lexicon') &&
                <ThesaurusDetectionPage />
            }
            {
                (role === "SuperAdmin" && selectedTab === 'Playlist') &&
                <PlaylistPage />
            }
        </Box>
    )
}
