import { Redirect, Route } from 'react-router-dom';

export const PublicRoute = ({
    isAuthenticated,
    role,
    component: Component,
    ...rest
}: any) => {
    return (
        <Route {...rest}
            component={(props: any) => (
                (isAuthenticated)
                    ? ((!role.toLowerCase().includes("thesaurus")) ? <Redirect to="/" /> : <Redirect to="/thesaurus" />)
                    : (<Component {...props} />)
            )}
        />
    )
}
