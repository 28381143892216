import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AudioActionButton } from "../AudioActionButton";
import {
    editorSetAudioFile,
    editorSetIsDownloadable,
} from "../../../../../../../actions/articles.actions";
import { Selector } from "../../../../../../../interfaces/Selector.interface";
import PlayLessonIcon from "@mui/icons-material/PlayLesson";
import { resetAudioState, setAudioUrl, setFullAudioUrl } from "../../../../../../../actions/audio.actions";
import { showInformationSnackBar, showWarningSnackBar } from "../../../../../../../actions/snackBar.actions";
import { sessionExpired } from "../../../../../../../helpers/sessionExpired";
import api from "../../../../../../../helpers/services/api";
import { handleLoadAudio } from "../../../../../../generics/player/playerHandlers";
import { PlayerState } from "../../../../../../../interfaces/PlayerState.interface";

interface Props {
    loadMP3: boolean;
    setLoadMP3: (loadMP3: boolean) => void;
    setStreamEnabled: (streamEnabled: boolean) => void;
    setPlayerState: React.Dispatch<React.SetStateAction<PlayerState>>;
}

export const AudioActionButtonLoadMP3 = ({ loadMP3, setLoadMP3, setStreamEnabled, setPlayerState }: Props) => {
    const dispatch = useDispatch();

    const article = useSelector((state: Selector) => state.article);

    const { activeArticle } = article;

    const loadAudio = async (articleId: number) => {
        // Initial fetch to retrieve audio.
        const audioFile = await api.audio.getFullAudio(articleId);

        if (audioFile.status >= 400) {
            dispatch(showWarningSnackBar(`Audio not loaded: Error ${audioFile.status} - ${audioFile.statusText}`));
            setLoadMP3(false);
            setStreamEnabled(true);
            return;
        }

        const audioBlob: Blob = await audioFile.blob();

        // create url for this blob.
        const audioBlobURL = URL.createObjectURL(audioBlob);

        // send blob url to React Player and set playing to false
        handleLoadAudio(setPlayerState, audioBlobURL, false);

        // set audio state from redux, to store the url for the full mp3 audio
        dispatch(setFullAudioUrl(audioBlobURL));
        dispatch(setAudioUrl(audioBlobURL));
        dispatch(editorSetAudioFile(audioBlobURL));
        dispatch(editorSetIsDownloadable(true));

        setStreamEnabled(false);

        // display success message
        dispatch(showInformationSnackBar("MP3 loaded successfully"));
    }

    /**
     * @function
     * Function wrapped inside a useCallback to make it work in a useEffect, besides in the Load MP3 button
     * within ActionButtons.js.
     *
     * It will query the BE using user id and file id as queryStrings.
     *
     * Endpoint is /api/file/userAudioFile (get).
     * @returns audio file fetch. If everything is ok, then it will be loaded into EditorPage.js react player.
     *
     * If not, a corresponding snackbar will be shown portraying the error that comes from the BE.
     *
     * NOTES: it is a self invoked function expression due to the fact that it has to be run when useCallback
     * is called.
     */
    const handleLoadFullAudio = useCallback(() => {
        (async () => {
            try {
                // disable Load MP3 button
                setLoadMP3(true);

                // retrieve file extension type
                const fileName = activeArticle.fileName;
                if (!fileName) throw new Error("couldn not load MP3: No file has been selected");

                await loadAudio(activeArticle.id);

                setLoadMP3(false);

            } catch (error: any) {
                console.error(error);
                if (error?.status === 401) {
                    sessionExpired();
                } else {
                    dispatch(showWarningSnackBar(error.message));
                }
                //reset audio state from redux
                dispatch(resetAudioState());
                setLoadMP3(false);
            };
        })();
    },
        // Disabled next line because it asks for props.loadPlayer and uid.
        // If I enable props.loadPlayer, this function enters in a loop since the player's state always changes
        // after an audio file has been loaded. Besides this, it makes no sense to watch for user id changes.
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [activeArticle.audioMediaFile?.url, activeArticle.id],
    );

    // effect to load mp3 audio files automatically everytime a new file has been chosen.
    // It has the activeArticle.audioName validation for when the user enters for the first
    // time in the page in order not to show a "Couldn't load MP3 warning".
    useEffect(() => {
        if (
            (
                activeArticle.status === 'DRAFT' ||
                activeArticle.status === 'PUBLISHED'
            ) &&
            activeArticle.audioMediaFile?.url &&
            activeArticle.audioMediaFile?.url?.length > 4
        ) {
            handleLoadFullAudio();
        }
        // eslint-disable-next-line
    }, [activeArticle.id, handleLoadFullAudio]);

    return <AudioActionButton
        data_test="audioactionbuttons-btn-loadmp3"
        icon={<PlayLessonIcon fontSize="small" />}
        onClick={handleLoadFullAudio}
        disabled={loadMP3}
        title={"Load the last MP3 generated for the article"}
        isLoading={loadMP3}
        isLoadingButton={true}
        name={loadMP3 ? "Loading..." : "Load MP3"}
    />
}