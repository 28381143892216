import React, { useRef, useEffect, useState, useCallback } from 'react';

// modules
import { useDispatch, useSelector } from 'react-redux';
import ContentEditable from 'react-contenteditable';

// helpers
import { cleanPastedInput, sanitizeInput } from '../../../../../../helpers/articles/runtimeProcessArticle/sanitizeInput';
import { flagPart } from '../../../../../../helpers/articles/runtimeProcessArticle/flagParts';
import { partsUpdate } from '../../../../../../actions/parts.actions';
import { LexiconEntry } from '../../../../../../models/lexiconEntry.model';

// interfaces
import { Selector } from "../../../../../../interfaces/Selector.interface";
import { ConfigSyntaxErrors } from '../../../../../../interfaces/config/Config.interface';
import { ArticlePart } from '../../../../../../interfaces/article/ArticlePart.interface';
import { PlayerState } from '../../../../../../interfaces/PlayerState.interface';


interface Props {
    part: ArticlePart,
    streamEnabled: boolean,
    setPlayerState: React.Dispatch<React.SetStateAction<PlayerState>>
}

export const ArticleSectionText = React.memo(({ part, streamEnabled, setPlayerState }: Props) => {
    const dispatch = useDispatch();

    // state selector
    const articleParts = useSelector((state: Selector) => state.parts);
    const config = useSelector((state: Selector) => state.config.config);


    const currentParts = useRef(articleParts);
    const currentConfig = useRef(config);
    const [pastedData, setPastedData] = useState('');


    useEffect(() => {
        currentParts.current = articleParts;
    }, [articleParts]);


    useEffect(() => {
        currentConfig.current = config;
    }, [config]);


    const handlePaste = (e: any) => {
        setPastedData(e.clipboardData.getData('text'))
    };

    // handle change when writting or pasting content
    const handleContentChange = useCallback((e: any, partIndex: number, pastedText: string) => {
        let parts = [...currentParts.current];
        let part = parts[partIndex];

        if (part) {
            if (streamEnabled) {
                setPlayerState((state: any) => ({
                    ...state,
                    loadAgain: true,
                }));
            }

            let newArticlePartText = e.target.value;

            if (e.nativeEvent.inputType === 'insertFromPaste') {
                newArticlePartText = cleanPastedInput(newArticlePartText, pastedText);
            } else {
                // To avoid lag whenever a user is writing a piece of text, instead of copying and pasting.
                if (Math.abs(newArticlePartText?.length - part?.contentHtml?.length) >= 3) {
                    newArticlePartText = sanitizeInput(newArticlePartText);
                };
            }

            part = {
                ...part,
                content: newArticlePartText,
                contentHtml: e.target.value,
                contentHtmlOriginal: e.target.value
            }
            parts[partIndex] = part;

            dispatch(partsUpdate([...parts]));
        }
    }, [currentParts, dispatch, setPlayerState, streamEnabled]);


    // on blur event, flag the part with html tags
    const handleContentBlur = useCallback((partOrder: number) => {
        const part: ArticlePart = { ...currentParts.current[partOrder] };

        // lexicon entries from the state
        const lexicon = currentConfig.current?.filter(
            element => element.name.toLowerCase() === "lexicon"
        )[0]?.data as LexiconEntry[];

        // syntax errors that will be flagged
        const syntaxErrors = currentConfig.current?.filter(
            element => element.name === "syntaxErrors"
        )[0]?.data as ConfigSyntaxErrors || [];

        if (part) {
            const newContentHtml = sanitizeInput(part.contentHtml);
            const flaggedPart = flagPart(newContentHtml, part, syntaxErrors, lexicon);

            const newPart: ArticlePart = {
                ...part,
                content: flaggedPart.content,
                contentHtml: flaggedPart.contentHtml,
                contentHtmlOriginal: flaggedPart.contentHtml,
                hasSyntaxError: flaggedPart.hasSyntaxError
            }

            const updatedParts: ArticlePart[] = currentParts.current.map(p =>
                p.order === newPart.order ? { ...newPart } : p
            );

            dispatch(partsUpdate(updatedParts));
        }
    }, [currentParts, currentConfig, dispatch]);


    return (
        <ContentEditable
            key={`artpart${part.order}`}
            data-test={`section-text-${part.order}`}
            html={part.contentHtml}
            onPaste={handlePaste}
            onChange={(e) => { handleContentChange(e, part.order, pastedData) }}
            onBlur={() => { handleContentBlur(part.order) }}
            style={{ padding: '3px 10px' }}
        />
    )
});
