/**
 * Extend articles with additional properties for front usage.
 */
import { Article } from '../../interfaces/article/Article.interface';
import { ArticlePart } from "../../interfaces/article/ArticlePart.interface";
import { dtoToHtmlPart } from "./saveArticle/processEditorArticle";

/**
 * Extend Article with additional properties for front usage
 * @param article {Article} - The article to extend.
 * @return {Article} - The extended article.
 */
export const extendArticleInformation = (article: Article): Article => {
    return {
        ...article,
        visible: true, // Indicates whether the article is visible or not.
        isSelected: false, // Indicates whether the article is selected or not.
    }
}

/**
 * Extend ArticlePart Extended with additional properties for front usage.
 * @param articlePart {ArticlePart} - The article part to extend.
 * @return {ArticlePart} - The extended article part.
 */
export const extendArticlePart = (articlePart: ArticlePart): ArticlePart => {
    return {
        ...articlePart,
        contentHtml: dtoToHtmlPart(articlePart),
        contentHtmlOriginal: dtoToHtmlPart(articlePart)
    }
}
