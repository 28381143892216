import { ArticlePart } from "../../interfaces/article/ArticlePart.interface";

// validation adapted from the speaker recognition module
export const validateArticleParts = (parts: ArticlePart[]): ArticlePart[] => {
    let validatedParts: ArticlePart[] = [];
    let textToConcatenate: string = '';

    for (let part of parts) {
        if (textToConcatenate !== '') {
            part.content = `${textToConcatenate}${part.content}`;
            textToConcatenate = '';
        }

        if (containsReadableChars(part.content)) {
            validatedParts.push(part);
        } else {
            textToConcatenate += part.content;
        }
    }

    if (textToConcatenate !== '') {
        // if last section had only non-readable characters, concatenate them to the last verified section
        let lastSection = validatedParts[validatedParts.length - 1];
        lastSection.content = `${lastSection.content}${textToConcatenate}`;
        validatedParts.pop()
        validatedParts.push(lastSection);
    }

    return validatedParts;
}

const containsReadableChars = (str: string): boolean => {
    const regex = /[a-zA-Z0-9]/;
    return regex.test(str);
}
