import React, { useEffect } from "react";

// redux
import { useDispatch } from "react-redux";

//mui
import { Box, Button, Divider, Stack, Typography, useTheme } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

//interfaces
import { Playlist } from "../../../../../../interfaces/Playlist.interface";
import { Article, ArticleWithContent } from "../../../../../../interfaces/article/Article.interface";

// actions
import { loadPlaylists } from "../../../../../../actions/playlist.action";
import { showPopupEditArticleDisplayName } from "../../../../../../actions/popup.action";

// helpers
import { formatDateToString } from "../../../../../../helpers/formatDateToString";
import api from "../../../../../../helpers/services/api";

// data
import { getFlagCode } from "../../../../../../data/languageLabels";

// libs
import Flag from "react-world-flags";
import Swal from "sweetalert2";


interface Props {
    article: Article,
    playlist: Playlist
}

export const PlaylistContentCell = React.memo(({ playlist, article }: Props) => {
    const dispatch = useDispatch();
    const theme = useTheme();

    const [articleTitle, setArticleTitle] = React.useState<string>(article.publisherTitle ? article.publisherTitle : "loading ...");

    const getArticleTitle = async (article: Article) => {
        if (article.publisherTitle) {
            setArticleTitle(article.publisherTitle)
        } else {
            const resp = await api.article.get(article.id)
            if (resp.status === 200 || resp.status === 201) {
                const articleWithContent: ArticleWithContent = await resp.json();
                const articleTitle: string = articleWithContent.publisherTitle
                    ? articleWithContent.publisherTitle
                    : articleWithContent.parts[0].content;
                setArticleTitle(articleTitle);
            }
        }
    }

    useEffect(() => {
        getArticleTitle(article);
    }, [article]);

    const handleRemoveArticle = async (playlistId: number, articleId: number) => {
        api.playlist.removeArticle(playlistId, articleId).then(resp => {
            if (resp.status === 200 || resp.status === 201) {
                Swal.fire('Success', 'Article successfully removed from playlist', 'success');
                loadPlaylists().then(action => dispatch(action))
            } else {
                Swal.fire('Error', `There was a problem trying to remove article from the playlist`, 'error');
            }
        })
    }

    const getDate = (date: number) => {
        const dateStr = formatDateToString(date)
        return dateStr.substring(0, 10)
    }

    const getTime = (date: number) => {
        const dateStr = formatDateToString(date)
        return dateStr.substring(11, 20)
    }

    const handleArticleEditName = () => {
        const articleWithNewTitle = { ...article };
        articleWithNewTitle.publisherTitle = articleTitle;
        return dispatch(showPopupEditArticleDisplayName(articleWithNewTitle));
    }

    if (article) {
        return (
            <Box>
                <Stack
                    direction='row'
                    spacing={{ md: 2 }}
                    sx={{ justifyContent: "space-between" }}
                >
                    <Typography
                        data-test="playlistcontent-label-title"
                        sx={{
                            display: 'block',
                            fontSize: '16px',
                            fontWeight: 'bold',
                            width: '25vw',
                        }}
                    >
                        {articleTitle}
                    </Typography>

                    <Box sx={{ ml: '10px' }}>
                        <Stack
                            direction='row'
                            spacing={{ md: 5 }}
                            sx={{ justifyContent: "space-between" }}
                        >
                            <Typography
                                data-test="playlistcontent-label-name"
                                sx={{ fontSize: '16px', display: 'block', width: '8vw', wordWrap: "break-word"  }}
                            >
                                {article.fileName}
                            </Typography>

                            <Typography
                                data-test="playlistcontent-label-date"
                                sx={{ fontSize: '16px', display: 'block' }}
                            >
                                {getDate(article.createdAt)}
                                <br />
                                {getTime(article.createdAt)}
                            </Typography>

                            {
                                article.language &&
                                <Flag
                                    data-test="playlistcontent-flag"
                                    id={"flag-img-article"}
                                    code={getFlagCode(article.language?.code)}
                                />
                            }

                            <Button
                                data-test="playlistcontent-btn-edit"
                                size="medium"
                                startIcon={<EditIcon fontSize="medium" />}
                                onClick={handleArticleEditName}
                                sx={{ marginLeft: "auto" }}
                            >
                                Edit
                            </Button>
                            <Button
                                data-test="playlistcontent-btn-delete"
                                size="medium"
                                startIcon={<DeleteIcon fontSize="medium" />}
                                onClick={() => handleRemoveArticle(playlist.id, article.id)}
                                sx={{ ml: "auto" }}
                            >
                                Delete
                            </Button>
                        </Stack>
                    </Box>
                </Stack>

                <Divider sx={{ my: theme.spacing(0.3) }} />

            </Box>
        );
    } else {
        return (
            <Box>
                <Typography
                    data-test="playlistcontent-label-error"
                    sx={{ fontSize: '20px', fontWeight: 'bold', display: 'block' }}
                >
                    Error loading article
                </Typography>
            </Box>
        )
    }

});
