import { AnyAction } from 'redux';

// custom action creator
import { actionCreator } from './actionCreator/actionCreator';

// action types
import { types } from './actionTypes/actionTypes';

// helpers
import { extendArticleInformation } from "../helpers/articles/extendArticle";
import api from "../helpers/services/api";

// interfaces
import { Article } from '../interfaces/article/Article.interface';


/**
 * Get all available articles from the database for the current user.
 */
export const loadFiles = async (filter: Date | null = null): Promise<AnyAction> => {
    try {
        const resp = await api.article.getAll(filter);

        if (resp.status === 200) {
            const files: Article[] = await resp.json();

            const payload = files.map(file => extendArticleInformation(file));
            return actionCreator(types.filesRead, payload);

        } else {
            const errorMsg = 'Error in the LoadFiles action';
            throw new Error(errorMsg)
        }
    } catch (error) {
        const errorMsg = `Error in the LoadFiles action: ${error}`;
        console.error(errorMsg)
        throw new Error(errorMsg)
    }
}

/**
 * Update an an article in the list according to the received notification.
 */
export const updateArticle = async (articleId: number, articleList: Article[]): Promise<AnyAction> => {

    try {
        const resp = await api.article.getArticleInfo(articleId);

        if (resp.status === 200) {
            const updatedArticle: Article = await resp.json();

            // Remove the old instance of the article if it's already exist, then add the new instance
            var articleListToUpdate: Article[] = articleList.filter(function(article) {return article.id !== articleId;});
            articleListToUpdate.push(updatedArticle);

            const payload = articleListToUpdate.map(article => extendArticleInformation(article));
            return actionCreator(types.updateFiles, payload);
        } else {
            const errorMsg = 'Error in the UpdateArticle action';
            throw new Error(errorMsg)
        }
    } catch (error) {
        const errorMsg = `Error in the UpdateArticle action: ${error}`;
        console.error(errorMsg)
        throw new Error(errorMsg)
    }
}

export const cleanFilesList = (): AnyAction => {
    return actionCreator(types.cleanFilesList)
}

/**
 * Load all the articles from the database, no matter the user. (Admin only)
 */
export const loadAllFiles = async (filter: Date | null = null): Promise<AnyAction> => {
    try {
        const resp = await api.article.getAll(filter);

        const files: Article[] = await resp.json();

        if (resp.status === 200) {
            const payload = files.map(file => extendArticleInformation(file));
            return actionCreator(types.allFiles, payload);

        } else {
            throw new Error('Error in the allFiles action')
        }
    } catch (error) {
        const errorMsg = `Error in the allFiles action: ${error}`;
        console.error(errorMsg)
        throw new Error(errorMsg)
    }
}

/**
 * Filter the visible files.
 * @param query {string} - The query to filter the articles.
 * @param files {FileRow[]} - The files to filter.
 * @return {(function(*): Promise<void>)|*}
 */
export const filterFiles = (query: string, files: Article[]): AnyAction => {
    try {
        const resetFiles = files.map(file => ({ ...file, visible: false }));
        const filteredFiles = resetFiles.map(file => {
            if (file.fileName.toLowerCase().includes(query.toLowerCase())) {
                return { ...file, visible: true };
            }
            return file;
        })

        return actionCreator(types.filterFiles, filteredFiles);

    } catch (error) {
        console.error(error);
        throw new Error('Errors in filterFiles action');
    }
}

/**
 * Sort the articles depending on different ordenation modes
 */
export const sortFiles = (orderedMode: string, files: Article[]): AnyAction => {
    try {
        switch (orderedMode) {
            case "createdup":
                files.sort((a, b) => a.createdAt > b.createdAt ? -1 : 1)
                break;

            case "createddown":
                files.sort((a, b) => a.createdAt < b.createdAt ? -1 : 1)
                break;

            case "updatedup":
                files.sort((a, b) => a.updatedAt > b.updatedAt ? -1 : 1)
                break;

            case "updateddown":
                files.sort((a, b) => a.updatedAt < b.updatedAt ? -1 : 1)
                break;

            case "nameaz":
                const nameB = [];
                for (let file of files) {
                    nameB.push({ pub: file.fileName, id: file.id })
                    file.fileName = file.fileName.toLowerCase()
                }
                files.sort(
                    (a, b) => a.fileName.replace(/[^a-z]/ig, '') > b.fileName.replace(/[^a-z]/ig, '') ? 1 : -1
                );
                break;

            case "nameza":
                files.sort();
                files.reverse();
                break;

            case "topic":
                break;

            default:
                break;
        }

        return actionCreator(types.sortFiles, [...files]);

    } catch (error) {
        console.error(error);
        throw new Error('Errors in sortFiles action')
    }
}

/**
 * Apply search filter to articles
 */
export const searchFiles = (files: Article[]): AnyAction => {
    return actionCreator(types.filesRead, files);
}


/**
 * Update articles
 */
export const updateFiles = (files: Article[]): AnyAction => {
    return actionCreator(types.updateFiles, files);
}
