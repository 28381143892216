import { useState } from 'react';

// modules
import { useSelector } from 'react-redux'
import Swal from 'sweetalert2';

// mui
import {
    Box,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
} from '@mui/material';

// helpers
import { copyToClip } from '../../../../../../helpers/clipboard';
import { odiaTagIndexes } from "../../../../../../helpers/articles/saveArticle/indexes/odiaTagIndexes";
import { Selector } from "../../../../../../interfaces/Selector.interface";


export default function TextTagsSelector() {
    const [textTag] = useState('');

    // state selector
    const parts = useSelector((state: Selector) => state.parts);
    const selectedPart = useSelector((state: Selector) => state.article.editor.selectedPart);
    

    const possibleTags = odiaTagIndexes;

    const copyToClipboard = (html: string) => {
        copyToClip(html)
        Swal.fire({
            icon: 'success',
            position: 'center-end',
            showConfirmButton: false,
            timer: 800,
            title: 'Tag copied',
        })
    }

    const handleTextTagChange = async (event: any) => {
        let tag = odiaTagIndexes.filter(t => t.name === event.target.value)[0];
        let html = ` <span name="${tag.name}" class="break-tag" contenteditable="false">${tag.displayTag}</span> `;

        let url;
        if (event.target.value === 'audio') {
            const { value } = await Swal.fire({
                title: 'Enter the audio URL',
                confirmButtonText: 'Ok',
                input: 'text',
                inputPlaceholder: 'https://s3.eu-west-2.amazonaws.com/odia.audiofile/jingles/piano-song.mp3',
                showCancelButton: true,
                inputValidator: (value) => !value ? 'You need to write something' : null
            })
            html = html.replace('AUDIO', value);
            url = value;
        }

        if (event.target.value === 'audio' && !url) return;
        copyToClipboard(html);
    };


    return (
        <>
            {
                (possibleTags.filter(
                    tag => tag.availableTTS.find(element => element === parts[selectedPart]?.voice?.TTS)).length > 0
                ) &&
                <Box sx={{ textAlign: 'left', paddingBottom: 0 }}>
                    <FormControl fullWidth>
                        <InputLabel id="textTagsLabel">Text tags</InputLabel>
                        <Select
                            labelId="textTagsLabel"
                            id="textTags"
                            value={textTag}
                            label="Text tags"
                            onChange={handleTextTagChange}
                        >
                            {possibleTags.map((tag) => {
                                if (tag.availableTTS.find(element => element === parts[selectedPart]?.voice?.TTS)) {
                                    return <MenuItem value={tag.name} key={tag.name}>{tag.displayText}</MenuItem>
                                }
                                else return null;
                            })}
                        </Select>
                        <FormHelperText sx={{ marginTop: "5px", marginBottom: "0px" }}>
                            Select to copy the tag
                        </FormHelperText>
                    </FormControl>
                </Box>
            }
        </>
    );
}
