import { LanguageLabel } from "../interfaces/LanguageLabel.interface";
import {Language} from "../interfaces/article/Language.interface";

export const getLabel = (languages: Language[], code: string): string => {
  const languageLabels: LanguageLabel[] = languages.map(item => ({ isoCode: item.code, label: item.name }));

  const languageObject = languageLabels.find(l => l.isoCode === code);
  return languageObject !== undefined ? languageObject.label : code;
}

export const getFlagCode = (code: string): string => {
  
  if (typeof code !== 'undefined') {
    return code.substr(-2).toLowerCase();
  } else {
    return "undefined";
  }
}
