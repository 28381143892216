import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// mui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    FormControl,
    Grid,
    Input,
    InputLabel,
    MenuItem,
    Select,
    Slider,
    TextField,
    Typography,
    styled,
} from '@mui/material'

import { setImageCount, setImageGenerator, setImageStyle } from '../../../../../../actions/video.actions';
import { Selector } from '../../../../../../interfaces/Selector.interface';


export const ImageGeneratorSelector = React.memo(() => {
    const theme = useTheme();
    const dispatch = useDispatch();

    // state selector
    const video = useSelector((state: Selector) => state.video);
    const { imageGenerator, imageStyle, imageCount } = video;

    // static parameters
    const StyledInput = styled(Input)`width: 50px;`;
    const generatorList: string[] = ["None", "Midjourney", "Dalle"];

    // action handlers with dispatchers
    const handleImageGeneratorChange = (event: any) => {
        dispatch(setImageGenerator(event.target.value));
    };

    const handleImageStyleChange = (event: any) => {
        dispatch(setImageStyle(event.target.value))
    };

    const handleImageCountChange = (event: any) => {
        const imageCountValue: number = event.target.value === '' ? 0 : Number(event.target.value);
        dispatch(setImageCount(imageCountValue))
    };


    return (
        <Box sx={{ textAlign: 'left' }}>
            <Typography
                data-test="imagegenerator-label"
                sx={{ color: theme.palette.primary.main }}
            >
                Image Generator parameters
            </Typography>

            <Grid item xs sx={{ mt: '20px' }}>
                <FormControl fullWidth>
                    <InputLabel id="imageGeneratorLabel">Generator</InputLabel>
                    <Select
                        id="imageGenerator"
                        data-test="imagegenerator-select-generator"
                        label="Generator"
                        labelId="imageGeneratorLabel"
                        onChange={handleImageGeneratorChange}
                        value={imageGenerator}
                    >
                        {
                            generatorList.map(generator => (
                                <MenuItem
                                    data-test={`imagegeneratorselector-${generator.toLowerCase()}`}
                                    value={generator}
                                    key={generator.toLowerCase()}
                                >
                                    {generator}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>

                <TextField
                    data-test="imagegenerator-text-style"
                    fullWidth
                    label="Image style"
                    size="small"
                    id="source"
                    name={imageStyle}
                    value={imageStyle}
                    onChange={handleImageStyleChange}
                    sx={{ my: 2 }}
                />
                <Grid container spacing={2} alignItems="center">
                    <Grid item>
                        <Typography
                            id="input-slider"
                            component='div'
                            gutterBottom
                            sx={{ width: 80, textAlign: 'left' }}
                        >
                            Number of images
                        </Typography>
                    </Grid>
                    <Grid item xs sx={{ marginTop: '10px' }}>
                        <Slider
                            value={typeof imageCount === 'number' ? imageCount : 0}
                            aria-labelledby="input-slider"
                            size="small"
                            step={1}
                            min={0}
                            max={20}
                            onChange={handleImageCountChange}
                        />
                    </Grid>
                    <Grid item>
                        <StyledInput
                            value={imageCount}
                            size="small"
                            inputProps={{
                                step: 1,
                                min: 0,
                                max: 20,
                                type: 'number',
                                'aria-labelledby': 'input-slider',
                            }}
                            onChange={handleImageCountChange}
                            onBlur={handleImageCountChange}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
})
