import React, { useMemo, useState } from "react";

// packages
import { Provider } from 'react-redux';

// components
import { SnackBar } from './components/generics/SnackBar';

// routes
import { AppRouter } from './router/AppRouter';

// store
import { store } from './store/store';

// app style theme
import { ThemeProvider } from '@mui/material/styles';
import { appTheme } from "./theme/appTheme";
import { Popup } from "./components/generics/Popup";


export const ColorModeContext = React.createContext({ toggleColorMode: () => { } });


export default function App() {
    const [mode, setMode] = useState<'light' | 'dark'>(() => {
        const storedMode = localStorage.getItem('theme');
        return (storedMode === 'dark' ? 'dark' : 'light');
    });

    const colorMode = useMemo(() => ({
        toggleColorMode: () => {
            setMode((prevMode) => {
                const newMode = prevMode === 'light' ? 'dark' : 'light';
                localStorage.setItem('theme', newMode);
                return newMode;
            });
        }
    }), []);

    const theme = useMemo(() =>
        appTheme(mode),
        [mode]
    );

    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                <Provider store={store}>
                    <AppRouter />
                    <Popup />
                    <SnackBar />
                </Provider>
            </ThemeProvider>
        </ColorModeContext.Provider>
    );
}
