
// mui
import { useTheme } from '@mui/material/styles';
import { Box, IconButton } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';


interface Props {
    open: boolean,
    handleDrawerClose: () => void
}

export const RightPanelClose = ({ open, handleDrawerClose }: Props) => {
    const theme = useTheme();

    return (
        <Box sx={{ ml: 1 }}>
            <IconButton
                aria-label="close drawer"
                color="inherit"
                data-test="rightpanel-btn-drawer-close"
                onClick={handleDrawerClose}
                edge="start"
                sx={{
                    color: theme.palette.text.primary,
                    ...(!open && { display: 'none' })
                }}
            >
                <ChevronRightIcon />
            </IconButton>
        </Box>
    )
}
