import { useState } from 'react';


/**
 * useForm hook
 * @param initialState the initial state of the form
 */
export const useForm = (initialState = {}) => {
    const [values, setValues] = useState(initialState);

    const reset = () => setValues(initialState);

    const handleInputChange = ({ target }: any) => {
        return setValues({
            ...values,
            [target.name]: target.value
        });
    }

    return [values, handleInputChange, reset];
}
