import Swal from "sweetalert2";
import {Article} from "../../../interfaces/article/Article.interface";

export const getFileName = async (activeArticle: Article) => {
    let fileName = '';
    if (activeArticle.fileName?.length > 0) {
        fileName = activeArticle.fileName;
    } else {
        // prompt the file name modal
        const { value: newFileName } = await Swal.fire({
            title: 'This is a new file',
            confirmButtonText: 'Save',
            input: 'text',
            inputLabel: 'You have to provide the name for this file',
            inputPlaceholder: 'Enter the file name',
            showCancelButton: true,
            inputValidator: (value) => !value ? 'You need to write a name for the file' : null
        })

        if (newFileName?.length > 0) {
            fileName = newFileName;
        }
    }
    return fileName;
}