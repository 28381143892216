import React, { useRef, useCallback, useEffect } from 'react';

// modules
import { useDispatch, useSelector } from 'react-redux';
import ContentEditable from 'react-contenteditable';

// interfaces
import { ArticlePart } from '../../../../../interfaces/article/ArticlePart.interface';
import { Selector } from "../../../../../interfaces/Selector.interface";
import { sanitizeInput } from '../../../../../helpers/articles/runtimeProcessArticle/sanitizeInput';
import { partsUpdate } from '../../../../../actions/parts.actions';
import { setSelectedSubtitlePartId, setSelectedSubtitleText } from '../../../../../actions/video.actions';


interface Props {
    part: ArticlePart,
}

export const ArticleSubtitleSection = React.memo((
    { part }: Props
) => {
    const dispatch = useDispatch();

    // state selector
    const articleParts = useSelector((state: Selector) => state.parts);

    const currentParts = useRef(articleParts);

    useEffect(() => {
        currentParts.current = articleParts;
    }, [articleParts]);

    // handle change when writting or pasting content
    const handleContentChange = useCallback((e: any, index: number) => {
        let parts = [...currentParts.current];
        let part = parts[index];

        if (part) {

            const newArticlePartText = sanitizeInput(e.target.value);
            part = {
                ...part,
                subtitleText: newArticlePartText
            }
            parts[index] = part;

            dispatch(partsUpdate([...parts]));
            dispatch(setSelectedSubtitleText(newArticlePartText));
        }
    }, [currentParts, dispatch]);

    const handleContentSelected = useCallback((index: number) => {
        let parts = [...currentParts.current];
        let part = parts[index];

        if (part) {
            dispatch(setSelectedSubtitlePartId(part.id));
            dispatch(setSelectedSubtitleText(part.subtitleText || part.content));
        }
    }, [dispatch]);

    return (
        <ContentEditable
            key={`artsubtitlepart${part.order}`}
            data-test={`sectionsubtitle-text-${part.order}`}
            html={part.subtitleText || part.content}
            onChange={(e) => { handleContentChange(e, part.order) }}
            onSelect={(e) => { handleContentSelected(part.order) }}
            style={{ padding: '3px 10px' }}
        />
    )
});
