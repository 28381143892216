import { types } from "../actions/actionTypes/actionTypes";
import { SnackbarState } from "../interfaces/Selector.interface";

const severities = {
    warning: "warning",
    error: "error",
    information: "info",
    success: "success"
};

const initialState: SnackbarState = {
    message: "",
    autoHideDuration: 3000,
    showSnack: false,
    severity: "info"
};

export const snackBarReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case types.snackBarShowPermanentSuccess:
            return {
                ...state,
                showSnack: true,
                message: action.payload.data,
                severity: severities.success,
                autoHideDuration: 0,
            } as SnackbarState

        case types.snackBarShowNotPermanentSuccess:
            return {
                ...state,
                showSnack: true,
                message: action.payload.data,
                severity: severities.success,
                autoHideDuration: 3500,
            } as SnackbarState

        case types.snackBarShowPermanentError:
            return {
                ...state,
                showSnack: true,
                message: action.payload.data,
                severity: severities.error,
                autoHideDuration: null,
            } as SnackbarState

        case types.snackBarShowNotPermanentError:
            return {
                ...state,
                showSnack: true,
                message: action.payload.data,
                severity: severities.error,
                autoHideDuration: 3500,
            } as SnackbarState

        case types.snackBarShowInformation:
            return {
                ...state,
                showSnack: true,
                message: action.payload.data,
                severity: severities.information,
                autoHideDuration: 3500,
            } as SnackbarState

        case types.snackBarShowWarning:
            return {
                ...state,
                showSnack: true,
                message: action.payload.data,
                severity: severities.warning,
                autoHideDuration: 3500,
            } as SnackbarState

        case types.snackBarHide:
            return {
                ...state,
                message: '',
                showSnack: false,
            } as SnackbarState

        case types.snackBarLogout:
            return initialState;

        default:
            return state;
    };
};
