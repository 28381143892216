
export const formatDateToString = (inputDate: number): string => {
    const date = new Date(inputDate);
    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
    };

    const formatter = new Intl.DateTimeFormat('en-UK', options);
    return formatter.format(date).replace(',', ' ');
}
