/* All the text replacement that need to be done BECAUSE of front replacement (mostly html balises).
   Do not add content related text replacements here.
*/

export const textReplacementIndexes = [
    { toReplace: /<\/*div[\s\S]*?>/g, replacement: '' },
    { toReplace: /<\/*br>/g, replacement: '\n' },
    { toReplace: /&quot;/g, replacement: ' ' },
    { toReplace: /&nbsp;/g, replacement: ' ' },
    { toReplace: /<\/*span style="font-family: -apple[\s\S]*?>/g, replacement: ' ' },
    { toReplace: /&amp;/g, replacement: ' et ' },
    { toReplace: /<\/break>/g, replacement: '' },
    { toReplace: /&nbsp;/g, replacement: ' ' }, // eslint-disable-next-line
]
