// react
import React, { useState, useEffect } from "react";

// redux
import { useDispatch, useSelector } from 'react-redux';

// mui
import { Box, IconButton, Divider, TextField, Popover, useTheme } from "@mui/material";
import { TreeItem, TreeView } from '@mui/x-tree-view';
import ArticleIcon from '@mui/icons-material/Article';
import CheckIcon from '@mui/icons-material/Check';
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SendIcon from '@mui/icons-material/Send';

// components
import { VideoFileItem } from "./videoFileItem/VideoFileItem";

// interfaces
import { Article } from "../../../../../interfaces/article/Article.interface";
import { Selector } from "../../../../../interfaces/Selector.interface";
import { sidebarSetAudioFilesPublishedDays } from "../../../../../actions/sidebar.actions";


export const VideoFilesTab = React.memo(() => {
  const theme = useTheme();
  const dispatch = useDispatch();

  // state selector
  const audioFilesPublishedDaysOld = useSelector((state: Selector) => state.sidebar.audioFilesPublishedDaysOld);
  const files = useSelector((state: Selector) => state.files);

  const [expanded, setExpanded] = useState(['files', 'published']);
  const [filesList, setFilesList] = useState([]);
  const [publishedList, setPublishedList] = useState([]);
  const [publishedDaysOld, setPublishedDaysOld] = useState(audioFilesPublishedDaysOld);

  const [popoverAnchorEl, setPopoverAnchorEl] = useState<HTMLButtonElement | null>(null);


  const handlePublishedFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setPopoverAnchorEl(event.currentTarget);
  };

  const handleTextFieldChange = (event: any) => {
    setPublishedDaysOld(parseInt(event.target.value))
  }

  const handlePublishedFilterClose = () => {
    dispatch(sidebarSetAudioFilesPublishedDays(publishedDaysOld));
    setPopoverAnchorEl(null);
  };

  const openPopover = Boolean(popoverAnchorEl);
  const publishedFilterPopoverId = openPopover ? 'published-filter-popover' : undefined;


  const handleArticleVersion = (list: any[]) => {
    const newArray = list.reduce((acc, obj) => {
      const found = acc.find((arr: any[]) => arr[0] && arr[0].fileName === obj.fileName);
      if (found) {
        found.push(obj);
      } else {
        acc.push([obj]);
      }
      return acc;
    }, []);

    return newArray;
  }


  useEffect(() => {
    setFilesList(handleArticleVersion(
      files.filter(file => (file.status === 'DRAFT'))
        .sort((a, b) => a.fileName.localeCompare(b.fileName))))
    setPublishedList(handleArticleVersion(
      files.filter(file => file.status === 'PUBLISHED')
        .sort((a, b) => a.fileName.localeCompare(b.fileName))))
  }, [files])


  return (
    <TreeView
      aria-label="file system navigator"
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      key="filesTab"
      expanded={expanded}
      sx={{ flexGrow: 1, maxWidth: 300, overflowY: 'auto' }}
    >
      <TreeItem
        data-test="videotab-tree-files"
        nodeId="files"
        label="FILES"
        icon={<ArticleIcon />}
      >
        {filesList.map((items: Article[]) => {
          if (items.length > 1) {
            return (
              <TreeItem
                key={items[0].fileName}
                nodeId={items[0].fileName}
                label={items[0].fileName}
                onClick={() => {
                  if (expanded.includes(items[0].fileName)) {
                    setExpanded(expanded.filter(id => id !== items[0].fileName))
                  } else {
                    setExpanded([...expanded, items[0].fileName]);
                  }
                }}>
                {items.map(item => {
                  return (
                    <VideoFileItem key={`file-${item.id}`} item={item} showArticleName={false} />
                  )
                })}
              </TreeItem>
            )
          } else {
            return (<VideoFileItem key={`file-${items[0].id}`} item={items[0]} showArticleName={true} />)
          }
        })}
      </TreeItem>

      <Divider
        key="video-files-tab-divider"
        sx={{ my: theme.spacing(2) }}
      />

      <TreeItem
        aria-describedby={publishedFilterPopoverId}
        data-test="videotab-tree-published"
        nodeId="published"
        label={
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <span style={{ marginRight: '8px' }}>PUBLISHED</span>
            <IconButton
              aria-label="btn-filter-published"
              onClick={handlePublishedFilterClick}
            >
              <FilterAltIcon />
            </IconButton>
          </Box>
        }
        icon={<SendIcon />}
        sx={{ marginBottom: theme.spacing(2) }}
      >
        {publishedList.map((items: Article[]) => {
          if (items.length > 1) {
            return (
              <TreeItem
                key={items[0].fileName}
                nodeId={items[0].fileName}
                label={items[0].fileName}
                onClick={() => {
                  if (expanded.includes(items[0].fileName)) {
                    setExpanded(expanded.filter(id => id !== items[0].fileName))
                  } else {
                    setExpanded([...expanded, items[0].fileName]);
                  }
                }}>
                {items.map(item => {
                  return (
                    <VideoFileItem key={`file-${item.id}`} item={item} showArticleName={false} />
                  )
                })}
              </TreeItem>
            )
          } else {
            return (<VideoFileItem key={`file-${items[0].id}`} item={items[0]} showArticleName={true} />)
          }
        })}
      </TreeItem>

      <Popover
        id={publishedFilterPopoverId}
        open={openPopover}
        anchorEl={popoverAnchorEl}
        onClose={handlePublishedFilterClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box sx={{
          p: 2,
          display: "flex",
          flexDirection: "row",
          justifyContent: "center"
        }}>
          <TextField
            label="Filter published days old"
            variant="outlined"
            type="number"
            inputProps={{
              min: 1,
            }}
            value={publishedDaysOld}
            onChange={handleTextFieldChange}
          />
          <IconButton onClick={handlePublishedFilterClose}>
            <CheckIcon />
          </IconButton>
        </Box>
      </Popover>

    </TreeView>
  )
})
