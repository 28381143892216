import { AnyAction } from 'redux';

// types
import { types } from './actionTypes/actionTypes';

// action creator
import { actionCreator } from './actionCreator/actionCreator';

// interfaces
import { Article } from '../interfaces/article/Article.interface';
import { Language } from '../interfaces/article/Language.interface';


export const setActiveArticle = (article: Article): AnyAction => {
    return actionCreator(types.articlesSetActive, article)
}

export const setActiveArticleStatus = (articleStatus: string): AnyAction => {
    return actionCreator(types.articlesSetActiveStatus, articleStatus)
}

export const setActiveArticleAudioGenerationStatus = (audioGenerationStatus: string): AnyAction => {
    return actionCreator(types.articlesSetActiveAudioGenerationStatus, audioGenerationStatus)
}

export const setActiveArticleVideoGenerationStatus = (videoGenerationStatus: string): AnyAction => {
    return actionCreator(types.articlesSetActiveVideoGenerationStatus, videoGenerationStatus)
}

export const setActiveArticleLanguage = (language: Language): AnyAction => {
    return actionCreator(types.articlesSetActiveArticleLanguage, language)
}

export const clearActiveArticle = (): AnyAction => {
    return actionCreator(types.articlesClearActive)
}

export const closeArticle = (): AnyAction => {
    return actionCreator(types.articlesClose)
}

export const editorPartUpdate = (boolean: boolean): AnyAction => {
    return actionCreator(types.articlesEditorPartUpdate, boolean)
}

export const editorSetActivePart = (index: number): AnyAction => {
    return actionCreator(types.articlesEditorPartActive, index)
}

export const editorSetSelectedLanguage = (selectedLanguage: string): AnyAction => {
    return actionCreator(types.articlesEditorSelectedLanguageUpdate, selectedLanguage)
}

export const editorSetActiveArticleName = (name: string): AnyAction => {
    return actionCreator(types.articlesEditorActiveArticleName, name)
}

export const editorSetActiveArticleId = (id: number): AnyAction => {
    return actionCreator(types.articlesEditorActiveArticleId, id)
}

export const editorSetLastModifiedDate = (lastModifiedDate: string): AnyAction => {
    return actionCreator(types.articleEditorLastModified, lastModifiedDate)
}

export const editorSetLastModifiedBy = (lastModifiedBy: string): AnyAction => {
    return actionCreator(types.articleEditorLastModifiedBy, lastModifiedBy)
}

export const editorSetAudioFile = (filePath: string): AnyAction => {
    return actionCreator(types.articlesEditorAudioFile, filePath)
}

export const editorSetIsDownloadable = (isDownloadable: boolean): AnyAction => {
    return actionCreator(types.articleEditorIsDownloadable, isDownloadable)
}

export const editorLoadAudioFile = (load: any): AnyAction => {
    return actionCreator(types.articlesEditorLoadAudioFile, load)
}

export const editorHasSyntaxError = (syntaxError: any): AnyAction => {
    return actionCreator(types.articlesEditorHasSyntaxError, syntaxError)
};

export const editorHasPartWithSymbolsOnly = (hasPartWithSymbols: boolean): AnyAction => {
    return actionCreator(types.articlesEditorHasPartWithSymbolsOnly, hasPartWithSymbols)
};

export const articlesEditorChangeIntroMusic = (jingleId: number): AnyAction => {
    return actionCreator(types.articlesEditorChangeIntroMusic, jingleId)
};

export const articlesEditorChangeTitleMusic = (jingleId: number): AnyAction => {
    return actionCreator(types.articlesEditorChangeTitleMusic, jingleId)
};

export const articlesEditorChangeOutroMusic = (jingleId: number): AnyAction => {
    return actionCreator(types.articlesEditorChangeIntroMusic, jingleId)
};

export const articlesEditorChangeBackgroundMusic = (backgroundId: number): AnyAction => {
    return actionCreator(types.articlesEditorChangeBackgroundMusic, backgroundId)
};

export const articlesEditorSetHighlightMode = (setHighlightMode: boolean): AnyAction => {
    return actionCreator(types.articlesEditorSetHighlightMode, setHighlightMode)
};

export const articlesLogout = (): AnyAction => {
    return actionCreator(types.articlesLogout)
}
