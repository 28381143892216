import { useEffect } from 'react';

// modules
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom'

// components
import Header from '../../editorScreen/header/Header';
import { UserEditForm } from './userEditForm/UserEditForm';

// actions
import { readClients, readUsers, setActiveUser } from '../../../../actions/users.actions';

// mui
import { Container, CssBaseline } from '@mui/material';

// interfaces
import { Selector } from "../../../../interfaces/Selector.interface";


export const UserEdit = () => {
    const dispatch = useDispatch();

    const { id }: { id: any } = useParams();

    // state selector
    const role = useSelector((state: Selector) => state.auth.role);
    const usersList = useSelector((state: Selector) => state.users.usersList);

    // effect to fetch the users from the db
    useEffect(() => {
        if (usersList.length === 0) {
            readUsers(role).then(action => dispatch(action));
            readClients().then(action => dispatch(action));
        }
    }, [dispatch, usersList.length, role]);

    // effect to set the requested user as the active one
    useEffect(() => {
        const selectedUser = usersList.filter(user => user.id === Number(id))[0] || {};
        dispatch(setActiveUser(selectedUser));
        // eslint-disable-next-line
    }, [id, dispatch, usersList.length]);


    return (
        <>
            <CssBaseline />
            <Container maxWidth="lg">
                <Header title="User management" />
                <UserEditForm />
            </Container>
        </>
    )
}
