
// mui
import { Typography } from '@mui/material';

interface Props {
    name: string
}

export const ItemTitle = ({ name }: Props) => {

    return (
        <Typography sx={{ fontSize: 16, display: 'block' }}>
            {name}
        </Typography>
    )
}
