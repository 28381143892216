import { OdiaTag } from "../odiaTag";

export const breakTag05 = new OdiaTag(
    "break-0",
    (part: any, tagContent: string, tagParameters: any, index: number) => {
        part.pauses.push({
            duration: 500,
            offset: index
        });
        return part;
    },
    "Break tag - 0.5s",
    ["microsoft", "google", "deepzen"],
    "0.5s"
);
