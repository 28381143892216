import { types } from '../actions/actionTypes/actionTypes';


const initialState = {
    jingleList: [],
    backgroundMusicsList: [],
}


export const soundEffectsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case types.soundEffectsLoad:
            return {
                ...state,
                jingleList: action.payload.data.jingles,
                backgroundMusicsList: action.payload.data.backgrounds
            }

        case types.soundEffectsLogout:
            return initialState

        default:
            return state
    }
}