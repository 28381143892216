import React from 'react';

// redux
import { useSelector } from 'react-redux';

// mui
import { Grid } from '@mui/material';

// components
import { ThesaurusItem } from './thesaurusItem/ThesaurusItem';
import { ThesaurusActionButtons } from './thesaurusActionButtons/ThesaurusActionButtons';
import { ButtonsGrid, ContentGrid } from '../audioEditorPage/styles/EditorPageStyle';



// interfaces
import { Selector } from "../../../../interfaces/Selector.interface";
import { ThesaurusRightPanel } from './thesaurusRightPanel/ThesaurusRightPanel';


export const ThesaurusDetectionPage = React.memo(() => {
    // state selector
    const articleExtracts = useSelector((state: Selector) => state.thesaurus.articleExtracts);

    return (
        <Grid container>
            <Grid item xs={9} sm={9}>
                <ButtonsGrid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={9}>
                        <ThesaurusActionButtons />
                    </Grid>
                </ButtonsGrid>

                <ContentGrid container m={2} pt={3}>
                    {
                        articleExtracts?.length > 0 &&
                        articleExtracts?.map(extract => {
                            return <ThesaurusItem thesaurusCandidate={extract} />
                        })
                    }
                </ContentGrid>
            </Grid >

            <ThesaurusRightPanel />
        </Grid >
    )
});
