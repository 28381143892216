import { Pause } from "./Pause.interface";
import { ReplacementText } from "./ReplacementText.interface";
import { Voice } from "./Voice.interface";
import { type EmotionPart } from "./EmotionPart.interface";
import { Language } from "./Language.interface";

export enum ArticleSectionType {
    TITLE = 'TITLE',
    LEAD = 'LEAD',
    CONTENT = 'CONTENT',
    SUBHEADING = 'SUBHEADING',
}

export interface ArticlePart {
    id: number
    order: number
    voice: Voice
    content: string
    subtitleText: string | null
    contentHtml: string
    contentHtmlOriginal: string
    prosodyPitch: number
    prosodyRate: number
    prosodyVolume: number
    prosodyContour: string | null
    pauses: Pause[]
    emotion: EmotionPart
    replacementTexts: ReplacementText[]
    hasSyntaxError: boolean
    visible: boolean
    type: ArticleSectionType
    language: Language
}
