import { configureStore } from '@reduxjs/toolkit';

import { articlesReducer } from '../reducers/articlesReducer';
import { audioReducer } from '../reducers/audioReducer';
import { authReducer } from '../reducers/authReducer';
import { configReducer } from '../reducers/configReducer';
import { filesReducer } from '../reducers/filesReducer';
import { languageReducer } from '../reducers/languageReducer';
import { partsReducer } from '../reducers/partsReducer';
import { playlistReducer } from "../reducers/playlistReducer";
import { popupsReducer } from "../reducers/popupsReducer";
import { sidebarReducer } from '../reducers/sidebarReducer';
import { snackBarReducer } from '../reducers/snackBarReducer';
import { socketReducer } from '../reducers/socketReducer';
import { soundEffectsReducer } from '../reducers/soundEffectsReducer';
import { thesaurusReducer } from '../reducers/thesaurusReducer';
import { usersReducer } from '../reducers/usersReducer';
import { videoReducer } from '../reducers/videoReducer';
import { voicesReducer } from '../reducers/voicesReducer';

export const store = configureStore({
    reducer: {
        article: articlesReducer,
        audio: audioReducer,
        auth: authReducer,
        config: configReducer,
        files: filesReducer,
        languages: languageReducer,
        parts: partsReducer,
        playlists: playlistReducer,
        popups: popupsReducer,
        sidebar: sidebarReducer,
        snackBar: snackBarReducer,
        socket: socketReducer,
        soundEffects: soundEffectsReducer,
        thesaurus: thesaurusReducer,
        users: usersReducer,
        video: videoReducer,
        voices: voicesReducer,
    },
})