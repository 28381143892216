import { types } from '../actions/actionTypes/actionTypes';

const initialState = { languageList: [] }

export const languageReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case types.languageLoad:
            return {
                ...state,
                languageList: action.payload.data
            }

        default:
            return state
    }
}