import React from 'react';

// mui
import { Box, Typography } from '@mui/material';
import { PartGrid } from '../../audioEditorPage/styles/EditorPageStyle';


// interfaces
import { ThesaurusCandidate } from '../../../../../interfaces/ThesaurusCandidate.interface';


interface Props {
    thesaurusCandidate: ThesaurusCandidate;
}

export const ThesaurusItem = React.memo(({ thesaurusCandidate }: Props) => {
    const regex = new RegExp('(' + thesaurusCandidate.candidate + ')', "gi");
    const cuttedText: string[] = thesaurusCandidate.context.split(regex);

    return (
        <PartGrid item xs={12}>
            <Typography>
                {
                    cuttedText.map(text => (
                        text === thesaurusCandidate.candidate
                            ? <Box component="span" sx={{ fontWeight: 'bold' }}>{text}</Box>
                            : <Box component="span">{text}</Box>
                    ))
                }
            </Typography>
        </PartGrid>
    )
});
