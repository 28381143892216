import { useState, useEffect, useRef } from 'react';

// modules
import { useDispatch, useSelector } from 'react-redux';

// components
import { Player } from '../../../../../../generics/player/Player';

// mui
import { styled, useTheme } from '@mui/material/styles';
import {
    Box,
    CircularProgress,
    FormControl,
    Grid,
    IconButton,
    Input,
    InputLabel,
    MenuItem,
    Select,
    Slider,
    Stack,
    Typography,
} from '@mui/material';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';

// actions
import { articlesEditorChangeBackgroundMusic, setActiveArticle } from '../../../../../../../actions/articles.actions';

// default state
import { playerDefaultState } from '../../../../../../../state/playerDefaultState';

// interfaces
import { Selector } from '../../../../../../../interfaces/Selector.interface';
import { BackgroundEffect } from '../../../../../../../interfaces/article/Article.interface';

// handlers
import { handleLoadAudio, handlePlayPause, handleVolumeChange } from '../../../../../../generics/player/playerHandlers';


const StyledInput = styled(Input)`width: 50px;`;


export default function BackgroundMusicSelector() {
    const theme = useTheme();
    const dispatch = useDispatch();
    const playerRef = useRef(null);

    // state selector
    const activeArticle = useSelector((state: Selector) => state.article.activeArticle);
    const backgroundMusicsList = useSelector((state: Selector) => state.soundEffects.backgroundMusicsList);

    const [selectedBackgroundId, setSelectedBackgroundId] = useState(0);
    const [playerState, setPlayerState] = useState({ ...playerDefaultState });

    const defaultVolume: number = 1;

    const backgroundVolumeParams = {
        step: 0.05,
        min: 0,
        max: 1,
        defaultVolume: activeArticle?.backgroundMusicEffect?.volume || defaultVolume
    }

    const [backgroundVolume, setBackgroundVolume] = useState(backgroundVolumeParams.defaultVolume);

    const updateArticleBackgroundMusic = (backgroundMusicEffect: BackgroundEffect) => {
        dispatch(setActiveArticle({
            ...activeArticle,
            backgroundMusicEffect
        }))
    }

    const handleBackgroundChange = (e: any) => {
        if (e.target.value === "" || e.target.value === 0) {
            setSelectedBackgroundId(e.target.value);
            setBackgroundVolume(defaultVolume);

            const backgroundMusicEffect: BackgroundEffect = {
                id: 0,
                defaultVolume,
                volume: defaultVolume,
                url: ""
            }
            updateArticleBackgroundMusic(backgroundMusicEffect);
            handleLoadAudio(setPlayerState, "", false, backgroundVolume);

            // update state
            dispatch(articlesEditorChangeBackgroundMusic(e.target.value));

        } else {
            let selectedBackground = backgroundMusicsList.filter(b => b.id === e.target.value)[0];

            setSelectedBackgroundId(e.target.value);
            setBackgroundVolume(selectedBackground?.defaultVolume ? +selectedBackground?.defaultVolume : defaultVolume);

            const selectedBackgroundMusicEffect: BackgroundEffect = {
                id: selectedBackground.id,
                defaultVolume: selectedBackground.defaultVolume,
                volume: selectedBackground.defaultVolume,
                url: selectedBackground.url
            }
            updateArticleBackgroundMusic(selectedBackgroundMusicEffect);
            handleLoadAudio(setPlayerState, selectedBackground?.url || "", false, backgroundVolume);

            // update state
            dispatch(articlesEditorChangeBackgroundMusic(e.target.value));
        }
    }

    const handleSliderChange = (e: any, newValue: any) => {
        setBackgroundVolume(+newValue);
    };

    const handleSliderChangeCommitted = (e: any, newValue: any) => {
        updateBackgroundVolume(+newValue);
    };

    const handleInputChange = (e: any) => {
        updateBackgroundVolume(+e.target.value);
    };

    const updateBackgroundVolume = (volume: number) => {
        let selectedBackground = backgroundMusicsList.filter(b => b.id === selectedBackgroundId)[0];
        if (!selectedBackground) {
            const backgroundEffect: BackgroundEffect = {
                id: 0,
                defaultVolume,
                volume: backgroundVolume,
                url: ""
            }
            updateArticleBackgroundMusic(backgroundEffect);

        } else {
            setBackgroundVolume(volume);
            handleVolumeChange(setPlayerState, volume);

            const selectedBackgroundEffect: BackgroundEffect = {
                id: selectedBackground.id,
                defaultVolume: selectedBackground.defaultVolume,
                volume,
                url: selectedBackground.url
            }
            updateArticleBackgroundMusic(selectedBackgroundEffect);
        }
    }

    const handleInputBlur = () => {
        if (backgroundVolume < 0) {
            setBackgroundVolume(0);
            handleVolumeChange(setPlayerState, 0);

        } else if (backgroundVolume > 1) {
            setBackgroundVolume(1);
            handleVolumeChange(setPlayerState, 0);
        }
    };

    useEffect(() => {
        const backgroundId = activeArticle?.backgroundMusicEffect?.id || 0;
        const backgroundVolume = activeArticle?.backgroundMusicEffect?.volume || defaultVolume;

        setSelectedBackgroundId(backgroundId);
        setBackgroundVolume(backgroundVolume);

        let selectedBackground = backgroundMusicsList.filter(b => b.id === backgroundId)[0];
        handleLoadAudio(setPlayerState, selectedBackground?.url || "", false, backgroundVolume);

        // eslint-disable-next-line
    }, [activeArticle.id, backgroundMusicsList])


    return (
        <Box sx={{ textAlign: 'left', mb: 5 }}>
            <Player playerState={playerState} setPlayerState={setPlayerState} playerRef={playerRef} />

            <Stack direction='row'>
                <FormControl fullWidth>
                    <InputLabel id="backgroundMusicSelectorLabel">
                        Background Music
                    </InputLabel>
                    <Select
                        id="backgroundMusicSelector"
                        key="backgroundMusicSelector"
                        data-test="background-music-selector"
                        label="Background Music"
                        labelId="backgroundMusicSelectorLabel"
                        onChange={handleBackgroundChange}
                        disabled={playerState.playing}
                        value={
                            backgroundMusicsList.filter(b => b.id === selectedBackgroundId).length > 0
                                ? selectedBackgroundId
                                : ''
                        }
                    >
                        <MenuItem value={0} key={""}>
                            <em>None</em>
                        </MenuItem>
                        {
                            backgroundMusicsList && backgroundMusicsList.length > 0 &&
                            backgroundMusicsList.map(background => (
                                <MenuItem
                                    value={background.id}
                                    key={background.name}
                                >
                                    {background.name}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>

                <IconButton
                    aria-label="startPause"
                    onClick={() => handlePlayPause(setPlayerState)}
                    color='primary'
                    disabled={
                        (!playerState.playing && playerState.url === '')
                        ||
                        (playerState.playing && playerState.isBuffering)
                    }
                    sx={{ fontSize: 32, marginTop: '5px' }}
                >
                    {
                        playerState.url === '' && playerState.playing &&
                            (!playerState.duration || playerState.isBuffering)
                            ? <CircularProgress color='primary' size={32} />
                            : playerState.playing
                                ? <PauseCircleIcon fontSize='inherit' />
                                : <PlayCircleFilledWhiteIcon fontSize='inherit' />
                    }
                </IconButton>
            </Stack>

            <Box sx={{ width: '100%', mt: 2 }}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item>
                        <Typography
                            id="input-slider"
                            component='div'
                            gutterBottom
                            color={theme.palette.text.secondary}
                            fontSize={'small'}
                            sx={{ textAlign: 'left' }}
                        >
                            Volume
                        </Typography>
                    </Grid>
                    <Grid item xs mr={1.5}>
                        <Slider
                            aria-label="backgroundVolume"
                            aria-labelledby="input-slider"
                            value={backgroundVolume}
                            onChange={handleSliderChange}
                            onChangeCommitted={handleSliderChangeCommitted}
                            size="small"
                            step={backgroundVolumeParams.step}
                            min={backgroundVolumeParams.min}
                            max={backgroundVolumeParams.max}
                            disabled={(selectedBackgroundId === 0)}
                        />
                    </Grid>
                    <Grid item>
                        <StyledInput
                            value={backgroundVolume}
                            size="small"
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                            inputProps={{
                                step: backgroundVolumeParams.step,
                                min: backgroundVolumeParams.min,
                                max: backgroundVolumeParams.max,
                                type: 'number',
                                'aria-labelledby': 'input-slider',
                            }}
                            disabled={(selectedBackgroundId === 0)}
                        />
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}
