import { useState } from 'react';

// modules
import { useSelector } from 'react-redux';
import Swal from "sweetalert2";

// mui
import { Box, Button, Input, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import api from '../../helpers/services/api';
import { Selector } from '../../interfaces/Selector.interface';


interface Props {
    handleModalClose: () => void
}

export const UploadAudioPopup = ({ handleModalClose }: Props) => {
    const theme = useTheme();

    // state selector
    const activeArticle = useSelector((state: Selector) => state.article.activeArticle);

    const [audioFile, setAudioFile] = useState<File | undefined>(undefined);
    const [disableUpload, setDisableUpload] = useState(true);

    const handleAudioInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const target = e.target as HTMLInputElement;
        const file: File = (target.files as FileList)[0];

        // check that file is of type mp3
        var regex = /\.mp3$/i;
        if (regex.test(file.name)) {
            setAudioFile(file);
            setDisableUpload(false);
        } else {
            setAudioFile(undefined);
            setDisableUpload(true);
            Swal.fire({
                icon: 'error',
                title: 'Invalid file',
                text: 'The file must be mp3 format',
            });
        }
    }

    const handleUploadAudio = async () => {
        if (!audioFile) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'You must select a mp3 file to upload',
            })
            return;
        }

        const bodyFormData = new FormData();
        bodyFormData.append('file', audioFile);

        handleModalClose();
        const result = await api.audio.uploadAudio(activeArticle.id, bodyFormData);

        if (result?.status === 201) {
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'The audio file was successfully uploaded',
            });
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'The audio file could not be uploaded',
            });
        }

        return;
    }

    return (
        <Box
            component='div'
            alignItems='center'
            justifyContent='center'
            sx={{ minWidth: '50%' }}
        >
            <Stack
                alignItems='center'
                direction='column'
                justifyContent='center'
                spacing={3}
            >
                <Typography
                    color={theme.palette.primary.main}
                    component='div'
                    variant='h6'
                >
                    Upload a mp3 audio file for this article
                </Typography>

                <form encType="multipart/form-data">
                    <Input
                        type="file"
                        id="audioFile"
                        name="audioFile"
                        onChange={handleAudioInputChange}
                    />
                </form>

                <Stack
                    alignItems="center"
                    direction="row"
                    justifyContent="center"
                    spacing={{ xs: 1, sm: 2, md: 4 }}
                >
                    <Button
                        variant="contained"
                        onClick={handleUploadAudio}
                        disabled={disableUpload}
                    >
                        Upload
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={handleModalClose}
                    >
                        Cancel
                    </Button>
                </Stack>
            </Stack>

        </Box>
    )
}
