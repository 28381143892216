import React from "react";
import Button from "@mui/material/Button";
import { LoadingButton } from "@mui/lab";
import { Typography } from "@mui/material";

interface Props {
    data_test: string;
    title: string;
    icon: React.ReactNode;
    onClick?: () => void;
    name: string;
    disabled?: boolean;
    isLoadingButton?: boolean;
    isLoading?: boolean;
    variant?: "outlined" | "contained";
    href?: string;
    download?: string;
    color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
}

export const AudioActionButton = ({ data_test, title, icon, onClick, name, disabled, isLoadingButton, isLoading, variant, href, download, color }: Props) => {

    if (isLoadingButton) {
        return <LoadingButton
            data-test={data_test}
            size="small"
            title={title}
            startIcon={icon}
            onClick={onClick}
            disabled={disabled}
            variant={variant || "outlined"}
            loading={isLoading}
            loadingPosition="start"
            href={href || ""}
            download={download || ""}
            color={color}
        >
            <Typography
                variant="inherit"
                sx={{
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: "hidden",
                }}>
                {name}
            </Typography>
        </LoadingButton>
    } else {

        return <Button
            data-test={data_test}
            size="small"
            title={title}
            startIcon={icon}
            onClick={onClick}
            disabled={disabled}
            variant={variant || "outlined"}
            href={href || ""}
            download={download || ""}
            color={color}
        >
            <Typography
                variant="inherit"
                sx={{
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: "hidden",
                }}>
                {name}
            </Typography>
        </Button>
    }
}