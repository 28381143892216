import React, { useState } from 'react';

// components
import { Sidebar } from '../sidebar/Sidebar';
import { AppStatusBar } from './appStatusBar/AppStatusBar';
import { ContentPage } from './contentPage/ContentPage';

// styles
import { styled, useTheme } from '@mui/material/styles';

// mui
import {
    AppBar,
    Box,
    CssBaseline,
    Drawer,
    IconButton,
    Toolbar,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

interface StyledCustomProps {
    theme: any
    open: boolean
}

const drawerWidth = 300;

const Main = styled('main', { shouldForwardProp: prop => prop !== 'open' })(

    ({ theme, open }: StyledCustomProps) => ({
        flexGrow: 1,
        padding: theme.spacing(0),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    }),
);

const EditorAppBar = styled(AppBar, {
    shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }: StyledCustomProps) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));


export const MainPage = React.memo(() => {
    const theme = useTheme();
    const [open, setOpen] = useState(true);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <EditorAppBar
                theme={theme}
                open={open}
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
            >
                <Toolbar
                    variant='dense'
                    sx={{
                        backgroundColor: theme.palette.secondary.light
                    }}
                >
                    <IconButton
                        aria-label="open drawer"
                        color="inherit"
                        data-test="appbar-btn-drawer-open"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            color: theme.palette.text.primary,
                            mr: 2,
                            ...(open && { display: 'none' })
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <IconButton
                        aria-label="close drawer"
                        color="inherit"
                        data-test="appbar-btn-drawer-close"
                        onClick={handleDrawerClose}
                        edge="start"
                        sx={{
                            color: theme.palette.text.primary,
                            mr: 2,
                            ...(!open && { display: 'none' })
                        }}
                    >
                        {
                            theme.direction === 'ltr'
                                ? <ChevronLeftIcon />
                                : <ChevronRightIcon />
                        }
                    </IconButton>

                    <AppStatusBar />

                </Toolbar>

            </EditorAppBar>

            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <Sidebar />
            </Drawer>

            <Main
                theme={theme}
                open={open}
            >
                <ContentPage />
            </Main>
        </Box >
    );
})
