import React, { useState, useEffect } from 'react';

// modules
import { useSelector } from 'react-redux';
// mui
import {
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,   
} from "@mui/material";

// components

// interfaces
import { Selector } from '../../../../interfaces/Selector.interface';

// styles
import { HeaderCell } from '../../../../theme/styled/HeaderCell';
import { Box, Stack } from '@mui/system';

// helpers 
import api from "../../../../helpers/services/api";
import { Listening } from '../../../../models/listening.models';


export const ListeningsTable = React.memo(() => {

interface PlayerMetricsRow {
    audioName: string,
    listeners: number,
    audioDuration: number,
    totalPlayed: number,
    date: Date,
    completionRate: number,
}
    const { config } = useSelector((state: Selector) => state.config);

    const [tableRows, setTableRows] = useState([] as PlayerMetricsRow[]);

    useEffect(() => {
        const listeningsConfig = config.filter(c => c.name.toLowerCase() === "listenings")[0].data as Listening;
        const playerMetricsRow: PlayerMetricsRow[] = [];
        for (let playerMetric of (listeningsConfig.playerMetrics)){
            playerMetricsRow.push({ ...playerMetric})
        }

        setTableRows(playerMetricsRow);
    }, [config]);


    //function to handle the change of the date picker
    const handleDateChange = async (newStartDate: string, newEndDate: string) => {
        const responseMetrics = await api.metrics.getMetrics(newStartDate, newEndDate);
        let newPlayerMetrics = await responseMetrics.json();
        
        //update the table with the new metrics
        const playerMetricsRow: PlayerMetricsRow[] = [];

        for (let playerMetric of (newPlayerMetrics)){
            playerMetricsRow.push({ ...playerMetric})
        }
        setTableRows(playerMetricsRow);
    }

    return (
        <>
            <Box maxWidth="lg" m={2} pt={3}>
                <Typography
                    component='span'
                    variant='h6'
                    color='primary'
                >
                    Player metrics:&nbsp;
                </Typography>
            </Box>

            <Stack 
                direction='row' 
                spacing={3}
                display='flex'
                justifyContent='center'
                alignItems='center'
                sx={{ mb: 2 }}
            >
                <TextField 
                    id="startDate" 
                    label="Start date" 
                    type="date"
                    defaultValue={new Date(new Date().getFullYear(), new Date().getMonth(), 2).toISOString().slice(0, 10)}
                    InputLabelProps={{ shrink: true,}}
                />
                <TextField 
                    id="endDate" 
                    label="End date" 
                    type="date"
                    defaultValue={new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1).toISOString().slice(0, 10)}
                    InputLabelProps={{ shrink: true,}}
                />
                <Button
                    id="validate"
                    defaultValue={"Validate"}
                    variant="outlined"
                    className="button"
                    name={"button validate"}
                    sx={{ fontWeight: 'bold' }}
                    //call the handleValidate function with the value of the input
                    onClick={() => { 
                        handleDateChange(
                            (document.getElementById('startDate') as HTMLInputElement).value, 
                            (document.getElementById('endDate') as HTMLInputElement).value) 
                        }}
                >
                    Validate
                </Button>
            </Stack>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <HeaderCell>Audio name</HeaderCell>
                            <HeaderCell>Listeners</HeaderCell>
                            <HeaderCell>Audio duration</HeaderCell>
                            <HeaderCell>Total played</HeaderCell>
                            <HeaderCell>Date</HeaderCell>
                            <HeaderCell>Completion rate</HeaderCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            tableRows.map(item => {
                                return (
                                    <TableRow>
                                        <TableCell sx={{ fontWeight: 'bold'}}>{String(item.audioName)}</TableCell>
                                        <TableCell>{String(item.listeners)}</TableCell>
                                        <TableCell>{String(item.audioDuration)}</TableCell>
                                        <TableCell>{String(item.totalPlayed)}</TableCell>
                                        <TableCell>{String(item.date).split('T')[0]}</TableCell>
                                        <TableCell>{String(Math.round(item.completionRate) + '%')}</TableCell>
                                    </TableRow>
                                )})
                            }
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
        
});