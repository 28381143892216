import { createTheme } from "@mui/material/styles";

export const darkTheme = () => {
    return createTheme({
        palette: {
            mode: 'dark',
            primary: {
                main: '#9fcef8',
                light: '#3c4957',
                dark: '#96b8e4',
            },
            secondary: {
                main: '#ee607c',
                light: '#182129',
                dark: '#9e9e9e',
            },
            background: {
                default: '#1c262f',
                paper: '#1c262f',
            },
            info: {
                main: '#424242',
            },
            warning: {
                main: '#ff9800',
            },
        }
    });
}
