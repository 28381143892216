import { useState } from "react";

// modules
import Flag from "react-world-flags";
import { useDispatch, useSelector } from "react-redux";

// mui
import { useTheme } from '@mui/material/styles';
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, Typography } from '@mui/material'
import WarningIcon from '@mui/icons-material/Warning';

// helpers
import { getFlagCode } from "../../data/languageLabels";

// interfaces
import { LanguageLabel } from "../../interfaces/LanguageLabel.interface";
import { Selector } from "../../interfaces/Selector.interface";
import { ArticlePart } from "../../interfaces/article/ArticlePart.interface";

// actions
import { partsUpdate } from "../../actions/parts.actions";
import { editorPartUpdate } from "../../actions/articles.actions";


interface Props {
    handleModalClose: () => void
}

export const SectionLanguagePopup = ({ handleModalClose }: Props) => {
    const theme = useTheme();
    const dispatch = useDispatch();

    // state selector
    const languages = useSelector((state: Selector) => state.languages);
    const parts = useSelector((state: Selector) => state.parts);
    const selectedPart = useSelector((state: Selector) => state.article.editor.selectedPart);
    const voices = useSelector((state: Selector) => state.voices.voiceList);

    const articlePart = parts.filter(p => p.order === selectedPart)[0];

    const getAvailableLanguages = () => {
        let availableLanguages: LanguageLabel[] = languages.languageList.map(item => ({ isoCode: item.code, label: item.name }));
        availableLanguages.sort((a, b) => (a.isoCode > b.isoCode) ? 1 : -1)
        return availableLanguages;
    }

    const [selectedLanguage, setSelectedLanguage] = useState<string>(
        articlePart.language.code
    );

    const [showWarning, setShowWarning] = useState(false);



    const handleLanguageChange = (e: SelectChangeEvent<string>) => {
        const newLanguage = e.target.value
        setSelectedLanguage(newLanguage);

        // check if the selected voice is available for the selected language
        const selectVoiceLanguages = voices.filter(v => v.name === articlePart.voice.name)
        const languageAvailable = selectVoiceLanguages.filter(v => v.language === newLanguage);

        if (languageAvailable.length === 0) {
            setShowWarning(true);
        } else {
            setShowWarning(false);
        }
    }

    const handleLanguageApply = () => {
        const newLanguage = languages.languageList.filter(l => l.code === selectedLanguage)[0];

        if (newLanguage) {
            const newPart: ArticlePart = {
                ...articlePart,
                language: newLanguage
            }

            const newParts = parts.map(p => p.order === selectedPart ? newPart : p);

            dispatch(partsUpdate(newParts));
            dispatch(editorPartUpdate(true));
        }


        handleModalClose();
    }


    return (
        <Box
            component="div"
            data-test="sectionlanguage-popup"
        >
            <Stack
                spacing={5}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <Typography
                    color={theme.palette.primary.main}
                    data-test="sectionlanguage-label-header"
                    component='div'
                    variant='h6'
                >
                    Article section #{articlePart.order + 1}: Select language
                </Typography>

                <FormControl>
                    <InputLabel id="sectionLanguageSelectorLabel">Language</InputLabel>
                    <Select
                        labelId="sectionLanguageSelectorLabel"
                        id="sectionLanguageSelector"
                        data-test="sectionlanguage-select"
                        value={selectedLanguage}
                        label="Language"
                        onChange={handleLanguageChange}
                    >
                        {getAvailableLanguages().map((l: LanguageLabel) => (
                            <MenuItem
                                data-test={`sectionlanguage-select-${l.isoCode}`}
                                key={l.isoCode}
                                value={l.isoCode}
                            >
                                <Grid item xs={10} sx={{
                                    display: 'flex',
                                    flexDirection: "row"
                                }}>
                                    <Flag id="flag-img-language" code={getFlagCode(l.isoCode)} />
                                    <Typography component='div' sx={{ ml: "7px" }}>
                                        {l.label}
                                    </Typography>
                                </Grid>
                            </MenuItem>
                        ))}
                    </Select>

                </FormControl>

                {
                    showWarning &&
                    <Typography
                        data-test="sectionlanguage-label-warning"
                        variant="body2"
                        color={theme.palette.warning.dark}
                        sx={{ textAlign: 'center' }}
                    >
                        <WarningIcon />
                        <br />
                        Language not available for the current voice ({articlePart.voice.name})
                    </Typography>
                }

                <Stack
                    alignItems="center"
                    direction="row"
                    justifyContent="center"
                    spacing={{ xs: 1, sm: 2, md: 4 }}
                >
                    <Button
                        data-test="sectionlanguage-btn-apply"
                        variant="contained"
                        onClick={handleLanguageApply}
                    >
                        Apply
                    </Button>
                    <Button
                        data-test="sectionlanguage-btn-cancel"
                        variant="outlined"
                        onClick={handleModalClose}
                    >
                        Cancel
                    </Button>
                </Stack>
            </Stack>

        </Box>
    )
}
