import { AnyAction } from "redux";

// types
import { types } from './actionTypes/actionTypes';

// helpers
import api from "../helpers/services/api";

// action creator
import { actionCreator } from "./actionCreator/actionCreator";

export const loadLanguages = async (): Promise<AnyAction> => {
    try {
        const response = await api.language.getAll();
        let languageList = await response.json();

        return actionCreator(types.languageLoad, languageList)

    } catch (error) {
        const errorMsg = 'Errors in loadLanguage action'
        console.error(errorMsg, error)
        throw new Error(errorMsg)
    }
}