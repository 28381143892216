import { Link } from 'react-router-dom';

// mui
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';


export const ConfigButtons = (
    {
        element,
        configName,
        handleEdit,
        handleDelete,
    }: any) => {
    return (
        <ButtonGroup>
            {
                configName?.toLowerCase() === 'voices'
                    ?
                    <Button
                        key={`edit-${element}`}
                        color="primary"
                        variant="text"
                        component={Link}
                        to={`/voice-form/${element}`}
                    >
                        <EditIcon />
                    </Button>
                    :
                    <Button
                        key={`edit-${element}`}
                        color="primary"
                        variant="text"
                        onClick={() => handleEdit()} >
                        <EditIcon />
                    </Button>
            }
            <Button
                key={`delete-${element}`}
                color="secondary"
                variant="text"
                onClick={() => handleDelete(element)}>
                <DeleteIcon />
            </Button>
        </ButtonGroup>
    )
}
