// modules
import { useDispatch, useSelector } from 'react-redux';

// mui
import { Button } from '@mui/material';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import LyricsIcon from '@mui/icons-material/Lyrics';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import Dollars from '@mui/icons-material/AttachMoney';
// actions
import { setConfigFile } from '../../../../actions/config.actions';

// interfaces
import { Selector } from "../../../../interfaces/Selector.interface";
import { Config } from '../../../../interfaces/config/Config.interface';
import { MusicNote } from '@mui/icons-material';


const ConfigItemIcon = ({ type }: { type: string }) => {
    return (
        <>
            {type.toLowerCase() === "lexicon" && <MenuBookIcon />}
            {type.toLowerCase() === "voices" && <RecordVoiceOverIcon />}
            {type.toLowerCase() === "jingles" && <LyricsIcon />}
            {type.toLowerCase() === "backgrounds" && <LibraryMusicIcon />}
            {type.toLowerCase() === "consumptions" && <Dollars />}
            {type.toLowerCase() === "listenings" && <MusicNote />}
        </>
    )
}


export const ConfigItem = ({ item }: { item: Config }) => {
    // state selector
    const selectedConfig = useSelector((state: Selector) => state.config.selectedConfig);

    const dispatch = useDispatch();

    const handleTreeComponent = (item: Config) => {
        dispatch(setConfigFile(item.name));
    }

    return (
        <Button
            id={`${item.name}config`}
            variant="outlined"
            onClick={() => { handleTreeComponent(item) }}
            onKeyDown={(e: any) => {
                let keyCode = e.key || e.keyCode;
                if (keyCode === 32) { handleTreeComponent(item) }
            }}
            disabled={selectedConfig?.toLowerCase() === item.name.toLowerCase()}
            className="button"
            name={"button " + item.name.toLowerCase()}
            sx={{ fontWeight: 'bold' }}
            startIcon={<ConfigItemIcon type={item.name} />}
        >
            {item.name}
        </Button>
    )
}
