import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {AudioActionButton} from "../AudioActionButton";
import {Selector} from "../../../../../../../interfaces/Selector.interface";
import SaveIcon from "@mui/icons-material/Save";
import {getFileName} from "../../../../../../../helpers/articles/audioActionButtons/audioActionButtonsUtils";
import {saveArticle} from "../../../../../../../helpers/articles/saveArticle";
import Swal from "sweetalert2";
import {
    editorSetActiveArticleId,
    editorSetActiveArticleName,
    setActiveArticleAudioGenerationStatus
} from "../../../../../../../actions/articles.actions";

interface Props {
    isSaving: boolean;
    setIsSaving: (isSaving: boolean) => void;
    isGenerating: boolean;
}

export const AudioActionButtonSave = ({isSaving, setIsSaving, isGenerating}: Props) => {
    const dispatch = useDispatch();

    const article = useSelector((state: Selector) => state.article);
    const articleParts = useSelector((state: Selector) => state.parts);
    const userData = useSelector((state: Selector) => state.auth);

    const { activeArticle, editor } = article;

    const handleSaveArticle = async () => {
        setIsSaving(true);
        const fileName = await getFileName(activeArticle);
        let fileId = activeArticle.id ? activeArticle.id : -1;

        // save article
        const articleData = {
            fileName,
            fileId,
            articleParts: [...articleParts],
            activeArticle
        }
        // save article
        saveArticle(userData, articleData)
            .then(async (resp: any) => {
                if (resp === 'error_name') {
                    Swal.fire('Error', 'This name is already taken.', 'error');
                }
                else if (resp === 'error_length') {
                    Swal.fire('Error', 'There was no name provided', 'error');
                }
                else {
                    const respBody = await resp.json();
                    dispatch(editorSetActiveArticleId(respBody?.id));
                    dispatch(editorSetActiveArticleName(respBody?.name));
                }

                setIsSaving(false);
                return 'ok';
            }).catch(e => {
            dispatch(setActiveArticleAudioGenerationStatus('GENERATED'));
            Swal.fire('Error', e.message, 'error');
            setIsSaving(false);
            return 'error';
        });
    }

    return <AudioActionButton
        data_test="audioactionbuttons-btn-save"
        icon={<SaveIcon fontSize="small" />}
        onClick={handleSaveArticle}
        title={"Save the article"}
        disabled={
            (
                articleParts.length > 0 &&
                articleParts[editor.selectedPart]?.hasSyntaxError
            )
            || editor.hasSyntaxError
            || editor.hasPartWithSymbolsOnly
            || isSaving
            || isGenerating
        }
        name="Save"
    />
}