import { useEffect, useState } from "react";

// modules
import { useDispatch, useSelector } from 'react-redux';
import Swal from "sweetalert2";
import 'sweetalert2/dist/sweetalert2.min.css'

// mui
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Button, CircularProgress, Grid, IconButton, Input, Stack, Switch, TextField } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";

// components
import { Player } from "../../../../generics/player/Player";

// styles
import { FormControlLabelSwitch } from "../../../editorScreen/audioEditorPage/styles/EditorPageStyle";
import '../../../../../theme/swal2.css'

// interfaces
import { Selector } from '../../../../../interfaces/Selector.interface';
import { Jingle } from "../../../../../models/jingle.model";

// helpers
import { deleteJingle, loadConfig, saveJingle } from "../../../../../actions/config.actions";
import { getStreamDefaultState } from "../../../../../state/streamDefaultState";
import { handlePlayPauseJingles } from "../../../../generics/player/playerHandlers";
import { loadSoundEffects } from "../../../../../actions/soundEffects.actions";
import { playerDefaultState } from "../../../../../state/playerDefaultState";
import api from "../../../../../helpers/services/api";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 5,
    borderRadius: 10,
    overflowY: "auto",
    maxHeight: "90%",
};

interface Props {
    modalState: boolean,
    handleModalClose: () => void,
    modalSelectedItem: number
}

export const JingleModal = ({ modalState, handleModalClose, modalSelectedItem }: Props) => {
    const dispatch = useDispatch();

    // state selector
    const config = useSelector((state: Selector) => state.config.config);
    const editor = useSelector((state: Selector) => state.article.editor);

    const [jinglesStateList, setJingleStateList] = useState<Jingle[]>(
        config.filter(c => c.name.toLowerCase() === "jingles")[0].data as Jingle[]
    );
    const [selectedJingle, setSelectedJingle] = useState<Jingle | undefined>(
        jinglesStateList.filter(l => l.id === modalSelectedItem)[0]
    );
    const [jingleName, setJingleName] = useState<string>(selectedJingle?.name || '');
    const [jingleDefaultVolume, setJingleDefaultVolume] = useState<number>(selectedJingle?.defaultVolume || 1);
    const [jingleFadeOut, setJingleFadeOut] = useState<boolean>(selectedJingle?.fadeOut || false);
    const [jingleUrl, setJingleUrl] = useState<string>(selectedJingle?.url || '');
    const [jingleFile, setJingleFile] = useState<File | undefined>(undefined);
    const [Playing, setPlaying] = useState(false);
    const [streamState] = useState(getStreamDefaultState(editor.selectedLanguage));
    const [playerState, setPlayerState] = useState({ ...playerDefaultState });

    useEffect((() => {
        setJingleStateList(config?.filter(element => element.name.toLowerCase() === "jingles")[0]?.data as Jingle[]);
    }), [config])

    useEffect((() => {
        const jingle = jinglesStateList.filter(l => l.id === modalSelectedItem)[0];
        setSelectedJingle(jingle);
        setJingleName(jingle?.name || '');
        setJingleDefaultVolume(jingle?.defaultVolume || 1);
        setJingleFadeOut(jingle?.fadeOut || false);
        setJingleUrl(jingle?.url || '');
    }), [jinglesStateList, modalSelectedItem])

    const handleSaveJingle = async () => {
        const jingle: Jingle = {
            id: selectedJingle?.id || 0,
            name: jingleName,
            defaultVolume: jingleDefaultVolume,
            fadeOut: jingleFadeOut,
            url: jingleUrl,
        }

        if (jingle.name === '') {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Jingle name cannot be empty!',
            })
        } else {
            if (jingleFile) {
                const bodyFormData = new FormData();
                bodyFormData.append('file', jingleFile);
                const result = await api.jingle.upload(bodyFormData);
                jingle.url = await result.text();
            }
            saveJingle(jingle).then(result => {
                dispatch(result)
                loadConfig().then(action => dispatch(action));
                loadSoundEffects().then(action => dispatch(action));
                handleModalClose();
            })
        }
    }

    const handleDeleteJingle = () => {
        const jingle: Jingle = jinglesStateList.filter(l => l.id === modalSelectedItem)[0];

        if (jingle) {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                confirmButtonText: 'Delete',
                showCancelButton: true,

            }).then((result) => {
                if (result.isConfirmed) {
                    deleteJingle(jingle.id).then(result => {
                        dispatch(result)
                        loadConfig().then(action => dispatch(action));
                        handleModalClose();
                    })
                }
            })
        }
    }

    const handlePlayPauseJingle = () => {
        handlePlayPauseJingles(jingleUrl, streamState, setPlayerState)
        setPlaying(true);
    }

    const changeJingle = (audioFile: File) => {
        setJingleFile(audioFile);
        const blob = new Blob([audioFile], { type: 'audio/mp3' });
        const audioUrl = URL.createObjectURL(blob);
        setJingleUrl(audioUrl);
    }

    return (
        <Modal
            open={modalState}
            onClose={handleModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Player playerState={playerState} setPlayerState={setPlayerState} />
                <form encType="multipart/form-data">
                    <Grid item xs={12} sx={{ mt: 2 }}>
                        <Stack direction='column' spacing={1}
                            sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <TextField
                                fullWidth
                                label="Jingle Name"
                                size="small"
                                variant="standard"
                                id="jingle_name"
                                name={"jingle name"}
                                value={jingleName}
                                onChange={e => setJingleName(e.target.value)}
                            />

                            <TextField
                                fullWidth
                                label="Jingle Volume"
                                size="small"
                                variant="standard"
                                id="jingle_volume"
                                name={"jingle volume"}
                                value={jingleDefaultVolume}
                                onChange={(e: any) => setJingleDefaultVolume(e.target.value)}
                            />

                            <FormControlLabelSwitch
                                control={<Switch
                                    checked={jingleFadeOut}
                                    onChange={() => setJingleFadeOut(!jingleFadeOut)}
                                    inputProps={{ 'aria-label': 'controlled' }} />
                                }
                                label="Fade Out"
                                labelPlacement="end"
                            />

                            <Stack
                                direction='row'
                                spacing={1}
                                sx={{ display: 'flex', justifyContent: 'space-between' }}
                            >
                                <Input
                                    type="file"
                                    id="jingle_file"
                                    name="jingle_file"
                                    onChange={res => {
                                        const target = res.target as HTMLInputElement;
                                        const file: File = (target.files as FileList)[0];
                                        changeJingle(file);
                                    }}
                                />

                                <IconButton
                                    aria-label="startPause"
                                    onClick={() =>
                                        handlePlayPauseJingle()
                                    }
                                    color='primary'
                                    disabled={
                                        (jingleUrl === '')
                                        ||
                                        (
                                            playerState.playing &&
                                            (!playerState.duration || playerState.isBuffering))
                                    }
                                    sx={{ fontSize: 32, mt: 1 }}
                                >
                                    {
                                        Playing && playerState.playing &&
                                            (!playerState.duration || playerState.isBuffering)
                                            ? <CircularProgress color='secondary' size={32} />
                                            : Playing && playerState.playing
                                                ? <PauseCircleIcon fontSize='inherit' />
                                                : <PlayCircleFilledWhiteIcon fontSize='inherit' />
                                    }
                                </IconButton>
                            </Stack>
                        </Stack>
                    </Grid>
                </form>

                <Grid item xs={12} sx={{ mt: '15px' }}>
                    <Button
                        size="medium"
                        startIcon={<SaveIcon fontSize="medium" />}
                        onClick={handleSaveJingle}
                    >
                        Save
                    </Button>
                    <Button
                        size="medium"
                        startIcon={<DeleteIcon fontSize="medium" />}
                        onClick={handleDeleteJingle}
                    >
                        Delete
                    </Button>
                </Grid>
            </Box>
        </Modal>
    );
}
