import { Redirect, Route } from 'react-router-dom';

export const AdminRoute = ({
    isAuthenticated,
    role,
    component: Component,
    ...rest
}: any
) => {
    return (
        <Route {...rest}
            component={(props: any) => (
                (isAuthenticated && role.toLowerCase().includes('admin'))
                    ? (<Component {...props} />)
                    : (<Redirect to="/login" />)
            )}
        />
    )
}
