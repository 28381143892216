import { useEffect, useState } from 'react';

// modules
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';

// mui
import { IconButton, Stack, Tooltip } from '@mui/material'
import { useTheme } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import LanguageIcon from '@mui/icons-material/Language';
import DownloadIcon from "@mui/icons-material/Download";

// interfaces
import { ArticlePart } from '../../../../../../interfaces/article/ArticlePart.interface';
import { Selector } from '../../../../../../interfaces/Selector.interface';
import { editorSetActivePart } from '../../../../../../actions/articles.actions';
import { partsUpdate } from '../../../../../../actions/parts.actions';
import { showPopupSectionLanguage } from '../../../../../../actions/popup.action';
import api from '../../../../../../helpers/services/api';
import { showWarningSnackBar } from '../../../../../../actions/snackBar.actions';


interface Props {
    part: ArticlePart
}

export const ArticleSectionButtons = ({ part }: Props) => {
    const theme = useTheme();

    const dispatch = useDispatch();

    // state selector
    const articleParts = useSelector((state: Selector) => state.parts);
    const activeArticle = useSelector((state: Selector) => state.article.activeArticle);

    const [showDownload, setShowDownload] = useState(false);

    // effect to handle download button visibility
    useEffect(() => {
        if (activeArticle.status === 'PUBLISHED' || activeArticle.status === 'DRAFT') {
            setShowDownload(true);
        } else {
            setShowDownload(false);
        }

        // eslint-disable-next-line
    }, [activeArticle.status]);

    const handlePartDelete = (partIndex: number) => {
        let parts = [...articleParts];

        if (parts.length === 1) {
            Swal.fire({
                title: 'You can\'t delete all the article sections',
                icon: 'error'
            })
            return;
        }

        Swal.fire({
            title: 'Are you sure you want to delete this article section?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel'
        }).then(result => {
            if (result.isConfirmed) {
                parts.splice(partIndex, 1);

                for (let i = 0; i < parts.length; i++) {
                    if (i >= partIndex) {
                        parts[i] = {
                            ...parts[i],
                            order: i
                        }
                    }
                }

                if (parts.length > 0) {
                    dispatch(editorSetActivePart(0));
                }

                dispatch(partsUpdate([...parts]));
            }
        })
    }


    // dispatch(showPopupRawArticle());
    const handleLanguageSelection = () => {
        dispatch(showPopupSectionLanguage());
    }

    const handlePartDownload = async (partIndex: number) => {
        // Initial fetch to retrieve audio.
        const audioFile = await api.audio.getArticleSectionAudio(articleParts[partIndex].id);

        if (audioFile.status >= 400) {
            dispatch(showWarningSnackBar(`Audio not loaded: Error ${audioFile.status} - ${audioFile.statusText}`));
            return;
        } else {
            const audioBlob: Blob = await audioFile.blob();

            // create url for this blob.
            const audioBlobURL = URL.createObjectURL(audioBlob);
            const link = document.createElement("a");
            link.href = audioBlobURL;
            link.download = "section_" + articleParts[partIndex].order + ".mp3";
            document.body.appendChild(link);

            link.click();

            document.body.removeChild(link);
            window.URL.revokeObjectURL(audioBlobURL);
        }
    }

    return (
        <Stack direction='row' sx={{ mt: 0.7 }}>

            <Tooltip title="Language">
                <IconButton
                    aria-label={`section-${part.order}-btn-language`}
                    data-test={`section-btn-language-${part.order}`}
                    onClick={handleLanguageSelection}
                    size='small'
                >
                    <LanguageIcon sx={{
                        fontSize: 20,
                        "&:hover": { color: theme.palette.primary.main }
                    }} />
                </IconButton>
            </Tooltip>

            {
                showDownload &&
                <Tooltip title="Download">
                    <IconButton
                        aria-label={`section-${part.order}-btn-download`}
                        data-test={`section-btn-download-${part.order}`}
                        onClick={() => handlePartDownload(part.order)}
                        size='small'
                    >
                        <DownloadIcon sx={{
                            fontSize: 20,
                            "&:hover": { color: theme.palette.primary.main }
                        }} />
                    </IconButton>
                </Tooltip>
            }

            <Tooltip title="Delete">
                <IconButton
                    aria-label={`section-${part.order}-btn-delete`}
                    data-test={`section-btn-delete-${part.order}`}
                    onClick={() => handlePartDelete(part.order)}
                    size='small'
                >
                    <DeleteIcon sx={{
                        fontSize: 20,
                        "&:hover": { color: theme.palette.secondary.main }
                    }} />
                </IconButton>
            </Tooltip>
        </Stack>
    )
}
