// centralized action types
export const types = {
    authCheckingFinish: '[auth] Finish checking login state',
    authLogin: '[auth] Login',
    authLogout: '[auth] Logout',
    authStartLogin: '[auth] Start login',
    authStartRegister: '[auth] Start register',
    authStartTokenRenew: '[auth] Start token renew',

    usersCreate: '[users] Create user',
    usersRead: '[users] Read users',
    usersReadClients: '[users] Read clients',
    usersUpdate: '[users] Update user',
    usersDelete: '[users] Delete user',
    usersActive: '[users] Change active user',
    usersActiveClear: '[users] Clear active user',
    usersLogout: '[users] Logout: Clear state',

    articlesSetActive: '[article] Set active article',
    articlesSetActiveStatus: '[article] Update active article status',
    articlesSetActiveAudioGenerationStatus: '[article] Update active article: Audio generation status',
    articlesSetActiveVideoGenerationStatus: '[article] Update active article: Video generation status',
    articlesSetActiveArticleLanguage: '[article] Update active article: Language',
    articlesClearActive: '[article] Clear active article',
    articlesClose: '[article] Close article',
    articlesEditorPartUpdate: '[article] Editor part update',
    articlesEditorPartActive: '[article] Editor change active part',
    articlesEditorSelectedLanguageUpdate: '[article] Editor change selected language update',
    articlesEditorActiveArticleName: '[article] Editor change active article name',
    articlesEditorActiveArticleId: '[article] Editor change active article id',
    articlesEditorAudioFile: '[article] Editor set article audio file',
    articleEditorLastModified: '[article] Editor set article last modification date',
    articleEditorLastModifiedBy: '[article] Editor User that last modified the article',
    articlesEditorLoadAudioFile: '[article] Editor load audio file',
    articlesEditorHasSyntaxError: '[article] Editor has syntax error',
    articleEditorIsDownloadable: '[article] Editor article is Downloadable',
    articlesEditorHasPartWithSymbolsOnly: '[article] Editor has part with symbols only',
    articlesEditorChangeIntroMusic: '[article] Change article intro music',
    articlesEditorChangeTitleMusic: '[article] Change article title music',
    articlesEditorChangeOutroMusic: '[article] Change article outro music',
    articlesEditorChangeBackgroundMusic: '[article] Change article background music',
    articlesEditorSetHighlightMode: '[article] Editor set highlight mode',
    articlesLogout: '[article] Clean articles on logout',

    partsUpdate: '[parts] Parts update',
    partsHasSyntaxError: '[parts] Parts has syntax error',
    partsReset: '[parts] Parts reset',
    partsLogout: '[parts] Clean parts on logout',

    soundEffectsLoad: '[soundEffects] Load sound effects',
    soundEffectsLogout: '[soundEffects] Clean sound effects on logout',

    voicesLoad: '[voices] Load voices',
    voicesUpdate: '[voices] Update voices',
    voicesLogout: '[voices] Clean voices on logout',

    languageLoad: '[languages] Load languages',

    thesaurusVoiceUpdate: '[thesaurusVoice] Thesaurus voice update',

    configLoad: '[config] Load config',
    configSetSelected: '[config] Set selected config file',
    configLogout: '[config] Clean config on logout',

    sidebarTabChange: '[sidebar] Change tab',
    sidebarSetAudioFilesPublishedDays: '[sidebar] Set audio files published days old',

    filesRead: '[files] Read user files',
    allFiles: '[files] Read all files',
    updateFile: '[files] Update file',
    sortFiles: '[files] Sort files',
    filterFiles: '[files] Filter files',
    cleanFilesList: '[files] Clean files on logout',
    updateFiles: '[files] Update files',

    loadAudioMP3: '[audio] Set url for full MP3 audio',
    setAudioUrl: '[audio] Set url for audio',
    resetAudioState: '[audio] Reset audio state',

    setVideoUrl: '[video] Set url for video',
    setImageGenerator: '[video] Set the selected image generator',
    setImageStyle: '[video] Set the selected image style for generator',
    setImageCount: '[video] Set the image count for generator',
    setImageUrlList: '[video] Set the image URL list for video generation',
    setVideoPublisherName: '[video] Set the publisher name for video generation',
    setVideoPublisherLogoUrl: '[video] Set the publisher logo URL for video generation',
    setSelectedSubtitlePartId: '[video] Set the ID of the selected subtitle part',
    setSelectedSubtitleText: '[video] Set the text of the selected subtitle part',

    thesaurusArticleChecked: '[thesaurus] Set the list of articles selected to build thesaurus data',
    thesaurusArticleExtractsUpdated: '[thesaurus] Set the thesaurus data using a list of article extracts',

    snackBarShowNotPermanentSuccess: '[snackBar] Show not permanent success',
    snackBarShowPermanentSuccess: '[snackBar] Show permanent success',
    snackBarShowNotPermanentError: '[snackBar] Show not permanent error',
    snackBarShowPermanentError: '[snackBar] Show permanent error',
    snackBarShowInformation: '[snackBar] Show information',
    snackBarShowWarning: '[snackBar] Show warning',
    snackBarHide: '[snackBar] Hide',
    snackBarLogout: '[snackBar] Clean on logout',

    socketArticleInfosUpdated: '[socket] Id of the updated article',
    socketNewArticleAudioGenerated: '[socket] Id of article audio generated',
    socketNewArticleVideoGenerated: '[socket] Id of article video generated',
    articleInfosUpToDate: '[socket] Set the state to default when article infos are updated',

    popupShow: '[popup] Show popup',
    popupShowRawArticle: '[popup] Show raw article',
    popupShowTranslation: '[popup] Show translation',
    popupShowNewPlaylist: '[popup] Show new playlist',
    popupShowUploadAudio: '[popup] Show upload audio',
    popupShowEditArticleDisplayName: '[popup] Show article display name edition',
    popupShowSectionLanguage: '[popup] Show section language',
    popupHide: '[popup] Hide popup',

    allPlaylists: '[playlist] Read all playlist',
    playlistSetActive: '[playlist] Set active playlist',
    createPlaylist: '[playlist] Create playlist',
    updatePlaylist: '[playlist] Update playlist',
    deletePlaylist: '[playlist] Delete playlist',
}