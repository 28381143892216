import { lightTheme } from "./themes/lightTheme";
import { darkTheme } from "./themes/darkTheme";

/**
 * Return the theme relative to the theme name
 * @param themeName
 * @returns {Theme} the theme
 */
export const appTheme = (themeName: string) => {
    switch (themeName) {
        case 'light':
            return lightTheme();
        case 'dark':
            return darkTheme();
        default:
            return lightTheme();
    }
}
