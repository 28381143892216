import React, { useEffect, useState } from "react";

// modules
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';

// mui
import { useTheme } from '@mui/material/styles';
import {
    Button,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

// components
import { JingleModal } from "./jingleModal/JingleModal";

// interfaces
import { Selector } from "../../../../interfaces/Selector.interface";
import { Jingle } from "../../../../models/jingle.model";

// styles
import { HeaderCell } from '../../../../theme/styled/HeaderCell';

// actions
import { deleteJingle, loadConfig } from "../../../../actions/config.actions";

interface JingleRow {
    id: number,
    name: string,
    defaultVolume: number,
    fadeout: string,
    url: string,
    client: string,
}

const createData = (
    id: number,
    name: string,
    defaultVolume: number,
    fadeout: string,
    url: string,
    client: string,
) => ({
    id, name, defaultVolume, fadeout, url, client
})

export const JinglesTable = React.memo(() => {
    const dispatch = useDispatch();
    const theme = useTheme();

    // state selector
    const config = useSelector((state: Selector) => state.config.config);
    const role = useSelector((state: Selector) => state.auth.role);

    const jingleStateList = config.filter(c => c.name.toLowerCase() === 'jingles')[0].data as Jingle[];
    const [jingles, setJingles] = useState(jingleStateList || []);

    const [tableRows, setTableRows] = useState([] as JingleRow[]);

    // lexicon entry modal
    const [modalState, setModalState] = useState(false);
    const [modalSelectedItem, setModalSelectedItem] = useState(0);
    const handleModalOpen = (index: number) => {
        setModalSelectedItem(index);
        setModalState(true);
    };
    const handleModalClose = () => setModalState(false);

    // effect to update the jingle list when the state config changed
    useEffect(() => {
        setJingles(jingleStateList);
    }, [jingleStateList]);

    // useEffect to create the table rows
    useEffect(() => {
        const rows: JingleRow[] = [];

        if (jingles.length > 0) {
            for (const l of jingles) {
                const client = 'All';
                const fadeout = l.fadeOut ? "Yes" : 'No';
                rows.push(createData(l.id, l.name, l.defaultVolume, fadeout, l.url, client));
            }
            setTableRows(rows);
        }

    }, [jingles])


    // function to handle delete an element from jingles
    const handleDelete = (jingleId: number) => {
        Swal.fire({
            title: `Are you sure you want to delete the jingle with ID: '${jingleId}'?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel'
        }).then((result) => {
            return new Promise((resolve, reject) => {
                if (result.isConfirmed) {
                    deleteJingle(jingleId).then(action => {
                        dispatch(action);
                        loadConfig().then(action => dispatch(action));
                    })

                    resolve('OK');
                }
            })
        })
    }


    return (
        <>
            <TableContainer component={Paper} sx={{ overflowX: "initial" }}>
                <Table
                    aria-label="jingles-table"
                    stickyHeader
                >
                    <TableHead>
                        <TableRow>
                            <HeaderCell>Name</HeaderCell>
                            <HeaderCell>Default volume</HeaderCell>
                            <HeaderCell>Fadeout</HeaderCell>
                            {role === "SuperAdmin" && <HeaderCell>Url</HeaderCell>}
                            {role === "SuperAdmin" && <HeaderCell></HeaderCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tableRows.map(item => {
                            return (
                                <TableRow hover key={`lexicon-${item.id}`}>
                                    <TableCell>{item.name}</TableCell>
                                    <TableCell>{item.defaultVolume}</TableCell>
                                    <TableCell>{item.fadeout}</TableCell>

                                    {
                                        role === "SuperAdmin" &&
                                        <TableCell>{item.url}</TableCell>
                                    }

                                    {
                                        role === "SuperAdmin" &&
                                        <TableCell>
                                            <Button
                                                key={`edit-${item.id}`}
                                                color="primary"
                                                variant="text"
                                                onClick={() => handleModalOpen(item.id)} >
                                                <EditIcon />
                                            </Button>
                                            <Button
                                                key={`delete-${item.id}`}
                                                color="secondary"
                                                variant="text"
                                                onClick={() => handleDelete(item.id)}>
                                                <DeleteIcon />
                                            </Button>
                                        </TableCell>
                                    }
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            {
                role === "SuperAdmin" &&
                <>
                    <Grid
                        container
                        sx={{
                            bottom: 0,
                            position: 'sticky',
                        }}
                    >
                        <Grid
                            item
                            xs={12}
                            sx={{ mt: 5, textAlign: "center" }}
                        >
                            <AddCircleIcon
                                htmlColor={theme.palette.secondary.main}
                                onClick={() => handleModalOpen(0)}
                                sx={{
                                    cursor: 'pointer',
                                    fontSize: '40px',
                                }}
                            />
                        </Grid>
                    </Grid>
                    <JingleModal
                        modalState={modalState}
                        handleModalClose={handleModalClose}
                        modalSelectedItem={modalSelectedItem}
                    />
                </>
            }
        </>
    );
});
