
// mui
import { Stack, Typography } from '@mui/material';
import VerifiedIcon from '@mui/icons-material/Verified';


interface Props {
    title: string,
    isParentArticle: boolean,
    selectedVersion: boolean,
}

export const ItemTitle = ({ title, isParentArticle, selectedVersion }: Props) => {
    return (
        <Stack
            direction='row'
            spacing={1}
            sx={{
                alignItems: 'center',
                display: 'flex',
            }}>
            <Typography
                variant={isParentArticle ? 'inherit' : 'subtitle2'}
            >
                {title}
            </Typography>
            {selectedVersion && <VerifiedIcon sx={{ fontSize: 16 }} />}
        </Stack>
    )
}