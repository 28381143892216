import { types } from '../actions/actionTypes/actionTypes';


const initialState = {
    selectedPlaylist: null,
    playlistList: []
};

export const playlistReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case types.allPlaylists:
            return {
                ...state,
                playlistList: action.payload.data
            }

        case types.playlistSetActive:
            return {
                ...state,
                selectedPlaylist: action.payload.data
            }

        default:
            return state
    }
}