import { AnyAction } from 'redux';

// types
import { types } from './actionTypes/actionTypes';

// action creator
import { actionCreator } from './actionCreator/actionCreator';


export const articleInfosUpdated = (articleId: number): AnyAction => {
    return actionCreator(types.socketArticleInfosUpdated, articleId)
}

export const articleInfosUpToDate = (): AnyAction => {
    return actionCreator(types.articleInfosUpToDate)
}

export const newArticleAudioGenerated = (articleId: number): AnyAction => {
    return actionCreator(types.socketNewArticleAudioGenerated, articleId)
}

export const newArticleVideoGenerated = (articleId: number): AnyAction => {
    return actionCreator(types.socketNewArticleVideoGenerated, articleId)
}