import he from "he";

import { textSanitizerIndexes } from "../saveArticle/indexes/textSanitizerIndexes";


/**
 * @function
 * Used to sanitize input text being pasted from another page / article.
 * @param {String} text: Input text to be sanitized.
 * @returns {String}: Sanitized text without certain unnecessary span, div, href, pre, h, p and code tags.
 */
export const cleanPastedInput = (text: string, pastedText: string): string => {
    // First, identify if the pasted text contains any custom span tag
    // That would mean that it is an internal paste
    const spanTags = /<span name[\s\S]*?>[\s\S]*?<\/span>/g;
    const matchedTags = pastedText.match(spanTags);
    if (matchedTags) return text;

    // If is not internal, remove all html tags. Sanitize the whole text, as it already contains the pasted text
    let sanitizedText = he.decode(text);
    sanitizedText = sanitizedText.replace(/(<([^>]+)>)/ig, ' ');
    sanitizedText = sanitizedText.replace(/  +/g, ' ');
    return sanitizedText;
}

/**
 * @function
 * Used to sanitize input text after it being written or being pasted from another page / article.
 * @param {String} text: Input text to be sanitized.
 * @returns {String}: Sanitized text without certain unnecessary span, div, href, pre, h, p and code tags.
 */
export const sanitizeInput = (text: string): string => {
    try {
        let textToBeModified = text;

        // This process is nested within a while since there are similar span tags.
        // Ours is <span name="..." ...> content </span> while others that we need to get rid off are
        // <span style="..." ...> content </span>. Since both end with the same tags, then we need
        // to first extract the "bad" tags with its content, then we remove both tags, and we replace
        // all the previous into only the content of those particularly removed span tags.
        const badSpanTagsWithTheirContent = /<span( +style)*(?! name)[\s\S]*?>[\s\S]*?<\/span>/;
        const badSpanTags = /<\/*span( +style)*(?! name)[\s\S]*?>/g;

        while (badSpanTagsWithTheirContent.test(textToBeModified)) {
            const spanTagWithInsideContent: Array<any> = badSpanTagsWithTheirContent.exec(textToBeModified) || [];
            const contentWithoutSpanTags = spanTagWithInsideContent[0].replaceAll(badSpanTags, "");
            textToBeModified = textToBeModified.replace(badSpanTagsWithTheirContent, contentWithoutSpanTags);
        };

        for (const tag of textSanitizerIndexes) {
            if (tag.toReplace.test(textToBeModified)) {
                textToBeModified = textToBeModified.replaceAll(tag.toReplace, tag.replacement);
            }
        }

        return textToBeModified;

    }
    catch (error) {
        console.error('Couldn\'t sanitize input text. Returning original text.', error);
        return text;
    };
};
