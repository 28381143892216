import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// mui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    FormControl,
    Grid,
    TextField,
    Typography
} from '@mui/material'

import { setImageUrlList } from '../../../../../../actions/video.actions';
import { Selector } from '../../../../../../interfaces/Selector.interface';


export const ImageUrlSelector = React.memo(() => {
    const theme = useTheme();
    const dispatch = useDispatch();

    // state selector
    const imageUrlList = useSelector((state: Selector) => state.video.imageUrlList);

    // Local state
    const [imageUrls, setImageUrls] = useState(imageUrlList.join('\n'));

    // Action handlers with dispatchers
    const handleImageUrlsChange = (event: any) => {
        const imageUrls: string = event.target.value;
        const urlList: string[] = [];
        for (let url of imageUrls.replace(/\n/g, " ").split(" ")) {
            if (url.length > 1) {
                urlList.push(url);
            }
        }

        setImageUrls(imageUrls);
        dispatch(setImageUrlList(urlList))
    };


    return (
        <Box sx={{ textAlign: 'left' }}>
            <Typography
                data-test="imageurls-label"
                sx={{ color: theme.palette.primary.main }}
            >
                Image URLs
            </Typography>

            <FormControl fullWidth>
                <Grid item xs sx={{ marginTop: '20px' }}>
                    <TextField
                        data-test="imageurls-text"
                        fullWidth
                        size="small"
                        label="Url list"
                        id="source"
                        name={''}
                        value={imageUrls}
                        onChange={handleImageUrlsChange}
                        multiline
                    />
                </Grid>
            </FormControl>
        </Box>
    );
})
