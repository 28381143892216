/* Indexes of all the available tags in the editorPage.
   All tags are instance of odiaTag.
*/

import { breakTag1 } from "../tags/breakTag1";
import { breakTag05 } from "../tags/breakTag05";

export const odiaTagIndexes = [
    breakTag1,
    breakTag05
    // Add new tags here
]
