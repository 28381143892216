import { Box, FormControlLabel, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';


export const ButtonsGrid = styled(Grid)(({ theme }) => ({
    display: "flex",
    padding: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    textAlign: 'left',
}));

export const ContentGrid = styled(Grid)(({ theme }) => ({
    display: "flex",
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    textAlign: 'left'
}));

export const PartGrid = styled(Grid)(({ theme }) => ({
    borderTopWidth: 1,
    borderTopStyle: 'solid',
    borderTopColor: theme.palette.divider,
    marginTop: theme.spacing(0),
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(0),
    paddingBottom: theme.spacing(1),
}));

export const RightPanelGrid = styled(Grid)(({ theme }) => ({
    height: '100%',
    paddingTop: theme.spacing(1),
    position: 'sticky',
    top: '48px',
}));

export const TitleBox = styled(Box)(({ theme }) => ({
    color: theme.palette.text.secondary,
    textAlign: 'left',
    fontSize: '0.9em'
}));

export const FormControlLabelSwitch = styled(FormControlLabel)(({ theme }) => ({
    color: theme.palette.text.secondary
}))
