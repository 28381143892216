import React from 'react';

// mui
import { Box, Typography } from '@mui/material';

// components
import { ThesaurusPanel } from '../../thesaurusPanel/ThesaurusPanel';

// styled
import {
    RightPanelGrid
} from '../../audioEditorPage/styles/EditorPageStyle'

interface TabPanelProps {
    children: any,
    index: number,
    value: number
}


const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <span
            role="tabpanel"
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            <Box component='div' sx={{ p: 3 }}>
                <Typography component='div'>{children}</Typography>
            </Box>
        </span>
    );
}


export const ThesaurusRightPanel = React.memo(() => {
    return (
        <RightPanelGrid item xs={3}>
            <TabPanel value={1} index={1}>
                <ThesaurusPanel />
            </TabPanel>
        </RightPanelGrid >
    )
})
