import React, { useEffect, useState } from 'react';

// modules
import ReactPlayer from 'react-player';
import Swal from 'sweetalert2';
import { useSelector, useDispatch } from 'react-redux';

// components
import { ArticleInfo } from '../audioEditorPage/audioEditorHeader/articleInfo/ArticleInfo';
import { ArticleSubtitleSection } from './articleSubtitleSection/ArticleSubtitleSection';
import { VideoActionButtons } from './videoActionButtons/VideoActionButtons';
import { VideoRightPanel } from './videoRightPanel/VideoRightPanel';

// styles
import { TitleArticleGrid } from './styles/VideoEditorPageStyle';
import { ButtonsGrid, ContentGrid, PartGrid } from '../audioEditorPage/styles/EditorPageStyle';

// mui
import {
    Box,
    Button,
    Grid,
    Typography,
} from '@mui/material';
import SaveAltIcon from '@mui/icons-material/SaveAlt';

// interfaces
import { Selector } from "../../../../interfaces/Selector.interface";

// helpers
import { resetAudioState, setFullVideoUrl } from '../../../../actions/video.actions';
import { updateArticlePartSubtitle } from '../../../../helpers/articles/updateArticlePartSubtitle';
import api from '../../../../helpers/services/api';


export const VideoEditorPage = React.memo(() => {
    const dispatch = useDispatch();

    // state selector
    const activeArticle = useSelector((state: Selector) => state.article.activeArticle);
    const articleParts = useSelector((state: Selector) => state.parts);
    const video = useSelector((state: Selector) => state.video);

    const { videoUrl, selectedSubtitlePartId, selectedSubtitleText } = video;

    const [activeArticleId, setActiveArticleId] = useState(activeArticle.id);


    useEffect(() => {
        if (activeArticleId !== activeArticle.id || activeArticle.id === -1) {
            setActiveArticleId(activeArticle.id);

            if (activeArticle.videoMediaFile && activeArticle.videoMediaFile.url?.length > 0) {
                loadFullVideoUrl(activeArticle.id);
            }
            else {
                dispatch(setFullVideoUrl(''));
            }
            dispatch(resetAudioState())
        }

        // eslint-disable-next-line
    }, [activeArticle])


    // Loading of video URL according to an article ID
    const loadFullVideoUrl = async (articleId: number) => {
        const resp = await api.video.getFullVideo(articleId);

        if (resp.status === 200) {
            const fullVideoUrl: string = await resp.text();
            dispatch(setFullVideoUrl(fullVideoUrl));
        } else {
            const errorMsg = 'Error recovering the full video URL';
            throw new Error(errorMsg)
        }
    }

    const handleUpdateSubtitleText = () => {
        const subtitle = { text: selectedSubtitleText };

        updateArticlePartSubtitle(selectedSubtitlePartId, subtitle).then(resp => {
            if (resp.status === 200 || resp.status === 201) {
                Swal.fire('Success', 'Article part subtitle successfully updated', 'success');
            } else {
                Swal.fire('Error', `There was a problem trying update the article part subtitle`, 'error');
            }
        })
    }

    return (
        <Grid container>
            <Grid item xs={9} sm={9}>
                <ButtonsGrid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={9}>
                        <VideoActionButtons />
                    </Grid>
                    <TitleArticleGrid item xs={12}>
                        <ArticleInfo />
                    </TitleArticleGrid>
                </ButtonsGrid>

                <ContentGrid container height='50%'>
                    {
                        (videoUrl && videoUrl?.length > 0) ?
                            <Box
                                className='player-wrapper'
                                alignItems='top-center'
                                display='flex'
                                justifyContent='center'
                            >
                                <ReactPlayer
                                    className='react-player fixed-bottom'
                                    url={videoUrl}
                                    width='80%'
                                    height='80%'
                                    controls={true}
                                />
                            </Box>
                            :
                            <Box>
                                <Typography component="h2"
                                    data-test="video-lbl-notgenerated"
                                    variant="h5"
                                    color="secondary"
                                    noWrap
                                >
                                    Video not generated
                                </Typography>
                            </Box>
                    }
                </ContentGrid>

                <ContentGrid container m={2} pt={3}>
                    {
                        articleParts?.length > 0 &&
                        articleParts?.map(part => (
                            <Grid
                                item
                                xs={12}
                                key={`artfrag${part.order}`}
                            >
                                <Grid container>
                                    <PartGrid item xs={2}>
                                        <Grid container>
                                            <Button
                                                data-test={`sectionsubtitle-btn-save-${part.order}`}
                                                size="small"
                                                variant="outlined"
                                                startIcon={<SaveAltIcon fontSize="small" />}
                                                onClick={handleUpdateSubtitleText}
                                                sx={{ borderRadius: '0' }}
                                            >
                                                Save subtitle
                                            </Button>
                                        </Grid>

                                    </PartGrid>
                                    <PartGrid item xs={10}>
                                        <ArticleSubtitleSection
                                            key={`artpart${part.order}`}
                                            part={part}
                                        />
                                    </PartGrid>
                                </Grid>
                            </Grid>
                        ))}
                </ContentGrid>
            </Grid>

            <VideoRightPanel />
        </Grid>
    )
});
