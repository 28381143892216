import { styled } from '@mui/material/styles';
import { TableCell } from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';

export const HeaderCell = styled(TableCell)(({ theme }) => ({
    color: theme.palette.primary.main,
    fontSize: 16,
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.secondary.light,
    },
}));