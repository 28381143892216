import { Voice } from "../interfaces/article/Voice.interface";

export const defaultVoice: Voice = {
    id: 1,
    name: 'Laurie',
    TTS: 'microsoft',
    TTSvoice: 'fr-FR-JosephineNeural',
    ssmlGender: 'FEMALE',
    pitch: 1,
    speakingRate: 1,
    volume: 1,
    enabled: true,
    description: '',
    automaticVersionEnabled: true,
    emotion: false,
    emotions: [],
    order: 1,
    language: 'fr-FR',
    languageId: 1,
    visible: true,
    client: null,
    voiceQuality: null,
    languageCodes: ['fr-FR']
}