import { AnyAction } from "redux";

// types
import { types } from './actionTypes/actionTypes';

// helpers
import api from "../helpers/services/api";

// action creator
import { actionCreator } from "./actionCreator/actionCreator";


export const loadPlaylists = async (): Promise<AnyAction> => {
    try {
        const response = await api.playlist.getAll();
        let playlistList = await response.json();

        return actionCreator(types.allPlaylists, playlistList)

    } catch (error) {
        const errorMsg = 'Errors in loadPlaylists action'
        console.error(errorMsg, error)
        throw new Error(errorMsg)
    }
}

export const setActivePlaylist = (playlistId: number): AnyAction => {
    return actionCreator(types.playlistSetActive, playlistId);
}