import React from 'react';

// mui
import { Box, Typography } from '@mui/material';

// components
import { ArticleSelector } from "./articleSelector/ArticleSelector";

// styled
import { RightPanelGrid } from '../../audioEditorPage/styles/EditorPageStyle'
import { useSelector } from "react-redux";
import { Selector } from "../../../../../interfaces/Selector.interface";

interface TabPanelProps {
    children: any,
    index: number,
    value: number
}


const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <span
            role="tabpanel"
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            <Box component='div' sx={{ p: 3 }}>
                <Typography component='div'>{children}</Typography>
            </Box>
        </span>
    );
}


export const PlaylistRightPanel = React.memo(() => {

    // state selector
    const playlistList = useSelector((state: Selector) => state.playlists.playlistList);
    const selectedPlaylist = useSelector((state: Selector) => state.playlists.selectedPlaylist);
    
    const playlist = playlistList.find(playlist => playlist.id === selectedPlaylist);

    return (
        <RightPanelGrid item xs={3}>

            <TabPanel value={1} index={1}>
                {playlist !== undefined ? <ArticleSelector playlist={playlist} /> : <></>}
            </TabPanel>

        </RightPanelGrid >
    )
})