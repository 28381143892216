/* All the text replacement that need to be done in runtime to check errors.
*/

export const textSanitizerIndexes = [
    { toReplace: /<\/*div>/g, replacement: '' },
    { toReplace: /<\/*a[\s\S]*?>/g, replacement: '' },
    { toReplace: /<\/*pre[\s\S]*?>/g, replacement: '' },
    { toReplace: /<\/*code[\s\S]*?>/g, replacement: '' },
    { toReplace: /<\/*h[\s\S]*?>/g, replacement: '' },
    { toReplace: /<\/*p[\s\S]*?>/g, replacement: '' }
]
