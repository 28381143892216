import { AnyAction } from 'redux';

// types
import { types } from './actionTypes/actionTypes';

// action creator
import { actionCreator } from './actionCreator/actionCreator';

// interfaces
import { ThesaurusCandidate } from '../interfaces/ThesaurusCandidate.interface';


export const setSelectedThesaurusArticles = (articleIds: number[]): AnyAction => {
    return actionCreator(types.thesaurusArticleChecked, articleIds)
}

export const setThesaurusArticleExtracts = (articleExtracts: ThesaurusCandidate[]): AnyAction => {
    return actionCreator(types.thesaurusArticleExtractsUpdated, articleExtracts)
}