import { types } from "../actions/actionTypes/actionTypes";

const initialState = {}

export const audioReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case types.loadAudioMP3:
            return {
                ...state,
                initialState: action.payload.data
            }
        case types.resetAudioState:
            return {
                ...initialState
            }

        default:
            return state
    }
}