// redux
import { useDispatch, useSelector } from 'react-redux';

// modules
import Swal from 'sweetalert2';

// mui
import { Box, Divider, MenuItem, Stack, Typography, useTheme } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay';
import CategoryIcon from '@mui/icons-material/Category';
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';
import VerifiedIcon from "@mui/icons-material/Verified";
import MusicOffIcon from '@mui/icons-material/MusicOff';
import TranslateIcon from "@mui/icons-material/Translate";
import UploadFileIcon from '@mui/icons-material/UploadFile';

// actions & helpers
import { showPopupTranslation, showPopupUploadAudio } from "../../../../../../actions/popup.action";
import { updateArticle } from "../../../../../../helpers/articles/updateArticle";
import { selectArticleVersion } from "../../../../../../helpers/articles/selectArticleVersion";
import { ArticleVersion } from "../../../../../../helpers/articleSet";

// interfaces
import { Article } from '../../../../../../interfaces/article/Article.interface';
import { Selector } from "../../../../../../interfaces/Selector.interface";

// helpers
import api from "../../../../../../helpers/services/api";


interface Props {
    item: Article
    items: ArticleVersion
    setIconLanguage: Function
}

export const SideBarItemContextMenu = ({ item, items, setIconLanguage }: Props) => {
    const theme = useTheme();
    const dispatch = useDispatch();

    // state selector
    const files = useSelector((state: Selector) => state.files);

    const {
        fileName,
        id,
        status,
        selectedVersion,
        language
    } = item;

    const fileNameWithoutExt: string = fileName?.replace(".xml", "");
    const translationArticles = files.filter(article => article.originalArticleId === id);

    const handleDeleteAudio = () => {
        Swal.fire({
            title: "Are you sure?",
            html: `Do you really want to delete audio from the article <b>${fileNameWithoutExt}</b> ?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel'
        })
            .then(result => {
                if (result.isConfirmed) {
                    updateArticle(id, { status: 'PENDING' }).then(() => {
                        Swal.fire(
                            'Success',
                            `<b>${fileNameWithoutExt}</b> audio was successfully deleted`,
                            'success'
                        );
                    })
                }
            })
            .catch(err =>
                Swal.fire(
                    'Error',
                    `An error occurred. The audio could not be deleted: ${err}`,
                    'error'
                )
            )
    }

    const handleDeleteArticle = () => {
        Swal.fire({
            title: "Are you sure?",
            html: `Do you really want to delete the article <b>${fileNameWithoutExt}</b> ?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel'
        })
            .then(result => {
                if (result.isConfirmed) {

                    // Delete translation articles
                    for (let article of translationArticles) {
                        updateArticle(article.id, { status: 'DELETED' }).then(() => {
                            Swal.fire(
                                'Success',
                                `<b>${fileNameWithoutExt}</b> file was successfully deleted`,
                                'success'
                            );
                        })
                    }

                    updateArticle(id, { status: 'DELETED' }).then(() => {
                        Swal.fire(
                            'Success',
                            `<b>${fileNameWithoutExt}</b> file was successfully deleted`,
                            'success'
                        );
                    })

                    setIconLanguage(0);
                }
            })
            .catch(
                err =>
                    Swal.fire(
                        'Error',
                        `An error occurred. The article could not be deleted: ${err}`,
                        'error')
            )

    }


    // call this before showing SweetAlert:
    function fixBootstrapModal() {
        var focusedNodes = document.querySelectorAll('div[tabindex="-1"]');
        if (!focusedNodes) return;
        focusedNodes.forEach(elmnt => {
            elmnt.removeAttribute('tabindex');
            elmnt.classList.add('js-swal-fixed');
        })
    }

    // call this before hiding SweetAlert (inside done callback):
    function restoreBootstrapModal() {
        var previouslyFocusedNodes = document.querySelectorAll('.modal.js-swal-fixed');
        if (!previouslyFocusedNodes) return;
        previouslyFocusedNodes.forEach(elmnt => {
            elmnt.setAttribute('tabindex', '-1');
            elmnt.classList.remove('js-swal-fixed');
        })
    }

    const handleRenameArticle = () => {
        fixBootstrapModal()

        Swal.fire(
            {
                title: `Rename the file "${fileNameWithoutExt}"`,
                confirmButtonText: 'Rename',
                cancelButtonText: 'Cancel',
                input: 'text',
                inputLabel: 'New file name:',
                inputPlaceholder: 'Enter the new file name',
                showCancelButton: true,
                inputValidator: (value) => !value ? 'New name field is required' : null
            }

        ).then(answer => {
            restoreBootstrapModal()
            const nameExists = files.find((file: any) => file.fileName.replace('.xml', '') === answer.value)

            if (answer.isConfirmed) {
                if (!nameExists) {
                    const newFileName = answer.value;

                    updateArticle(id, { name: newFileName })
                        .then(res => {
                            if (res.status === 500) {
                                Swal.fire(
                                    'Error',
                                    `An error occurred while renaming the file ${newFileName}`,
                                    'error'
                                )
                            }
                        })

                } else {
                    Swal.fire(
                        'Error',
                        'This file name is already used',
                        'error'
                    )
                }
            }
        })
            .catch(
                err =>
                    Swal.fire(
                        'Error',
                        `An error occurred in the editor while renaming the file: ${err}`,
                        'error')
            )
    }

    const handleUnpublishArticle = () => {
        Swal.fire({
            title: "Are you sure?",
            html: `Do you really want to unpublish <b>${fileNameWithoutExt}</b> ?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel'
        })
            .then((result) => {
                if (result.isConfirmed) {
                    updateArticle(id, { status: 'DRAFT' })
                }
            })
            .catch(
                err =>
                    Swal.fire(
                        'Error',
                        `An error occurred in the editor while unpublishing the file: ${err}`,
                        'error')
            )
    }

    const handleOpenPlayer = () => {

        api.client.getName().then(async (resp: any) => {
            const clientName: string = (await resp.json()).clientName;
            if (resp.ok) {
                const playerEnv = process.env.REACT_APP_ODIA_PLAYER_URL;
                const url = `${playerEnv}/${language.code}/${clientName}/${encodeURIComponent(fileNameWithoutExt)}`;
                window.open(url);
            } else {
                throw new Error('Errors recovering client name');
            }
        });
    }

    const handleChangeTopic = () => {
        fixBootstrapModal()

        Swal.fire({
            title: 'Change the topic',
            html: 'Enter the new topic:',
            input: 'text',
            inputLabel: 'New topic:',
            inputPlaceholder: 'Enter the new topic',
            showCancelButton: true,
            confirmButtonText: 'Change',
            cancelButtonText: 'Cancel',
            inputValidator: (value) => !value ? 'New topic field is required' : null
        })
            .then(answer => {
                restoreBootstrapModal()
                if (answer.isConfirmed) {
                    const newTopic = answer.value;
                    updateArticle(id, { topic: newTopic })
                        .then(res => {
                            if (res.status === 500) {
                                Swal.fire(
                                    'Error',
                                    `An error occurred while changing the topic of the file ${fileNameWithoutExt}`,
                                    'error'
                                )
                            }
                            else {
                                Swal.fire(
                                    'Success',
                                    `The topic of the file <b>${fileNameWithoutExt}</b> was successfully changed to <b>${newTopic}</b>`,
                                    'success'
                                )
                            }
                        })
                }
            })
            .catch(
                err =>
                    Swal.fire(
                        'Error',
                        `An error occurred in the editor while changing the topic of the file: ${err}`,
                        'error')
            )
    }

    const handleArticleVersion = () => {
        Swal.fire({
            title: "Are you sure?",
            html: `Do you really want to put this version of the article <b>${fileNameWithoutExt}</b> as the selected one?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel'
        })
            .then(result => {
                if (result.isConfirmed) {
                    selectArticleVersion(id).then(() => {
                        Swal.fire(
                            'Success',
                            `<b>${fileNameWithoutExt}</b> selected version was successfully changed.`,
                            'success'
                        );
                    })
                }
            })
            .catch(err =>
                Swal.fire(
                    'Error',
                    `An error occurred. The version could not be changed: ${err}`,
                    'error')
            )
    }

    const handleTranslate = () => {
        dispatch(showPopupTranslation(items));
    }

    const handleUploadAudio = () => {
        dispatch(showPopupUploadAudio());
    }

    const handleMenuAction = (action: string) => {
        switch (action) {
            case 'rename':
                handleRenameArticle();
                break;
            case 'deleteAudio':
                handleDeleteAudio();
                break;
            case 'deleteArticle':
                handleDeleteArticle();
                break;
            case 'unpublish':
                handleUnpublishArticle();
                break;
            case 'openPlayer':
                handleOpenPlayer();
                break;
            case 'changeTopic':
                handleChangeTopic();
                break;
            case 'selectVersion':
                handleArticleVersion();
                break;
            case 'translate':
                handleTranslate();
                break;
            case 'uploadAudio':
                handleUploadAudio();
                break;
            default:
                break;
        }
    }


    return (
        <Box>
            <Box px={2} py={0.5}>
                <Typography
                    variant="body1"
                    color={theme.palette.primary.main}
                >
                    <Stack direction='row' justifyContent="space-between">
                        <Typography>{fileNameWithoutExt}</Typography>
                        <Typography color={theme.palette.text.secondary}>{`#${id}`}</Typography>
                    </Stack>
                </Typography>
            </Box>

            <Divider sx={{ my: theme.spacing(0.5) }} />

            {
                status !== 'PUBLISHED' &&
                <MenuItem onClick={() => handleMenuAction('rename')}>
                    <EditIcon />
                    &nbsp;
                    Rename
                </MenuItem>
            }

            {
                status === 'DRAFT' &&
                <MenuItem
                    onClick={() => handleMenuAction('deleteAudio')}
                >
                    <MusicOffIcon />
                    &nbsp;
                    Delete audio
                </MenuItem>
            }

            {
                status !== 'PUBLISHED' &&
                <MenuItem
                    data-test="contextmenu-btn-delete"
                    onClick={() => handleMenuAction('deleteArticle')}
                >
                    <DeleteIcon />
                    &nbsp;
                    Delete article
                </MenuItem>
            }

            {
                status === 'PUBLISHED' &&
                <MenuItem onClick={() => handleMenuAction('unpublish')}>
                    <CancelScheduleSendIcon />
                    &nbsp;
                    Unpublish
                </MenuItem>
            }

            {
                (status === 'DRAFT' || status === 'PUBLISHED') &&
                <MenuItem onClick={() => handleMenuAction('openPlayer')}>
                    <SmartDisplayIcon />
                    &nbsp;
                    Open in Odia Player
                </MenuItem>
            }
            {
                (status === 'DRAFT' || status === 'PUBLISHED') &&
                <MenuItem onClick={() => handleMenuAction('changeTopic')}>
                    <CategoryIcon />
                    &nbsp;
                    Change topic
                </MenuItem>
            }

            {
                <MenuItem onClick={() => handleMenuAction('translate')}>
                    <TranslateIcon />
                    &nbsp;
                    Translate to...
                </MenuItem>
            }

            {
                <MenuItem onClick={() => handleMenuAction('uploadAudio')}>
                    <UploadFileIcon />
                    &nbsp;
                    Upload mp3 audio
                </MenuItem>
            }

            {
                !selectedVersion &&
                <MenuItem onClick={() => handleMenuAction('selectVersion')}>
                    <VerifiedIcon />
                    &nbsp;
                    Put as Selected Version
                </MenuItem>
            }

        </Box>
    )
}
