import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AudioActionButton } from "../AudioActionButton";
import { articlesEditorSetHighlightMode } from "../../../../../../../actions/articles.actions";
import { Selector } from "../../../../../../../interfaces/Selector.interface";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import api from "../../../../../../../helpers/services/api";
import { textTags } from "../../../../../../../state/textTags";
import { partsUpdate } from "../../../../../../../actions/parts.actions";
import { deleteTags } from "../../../../../../../helpers/articles/runtimeProcessArticle/flagParts";
import { LexiconEntry } from "../../../../../../../models/lexiconEntry.model";
import { ArticlePart } from "../../../../../../../interfaces/article/ArticlePart.interface";

export const AudioActionButtonHighlight = () => {
    const dispatch = useDispatch();

    const article = useSelector((state: Selector) => state.article);
    const articleParts = useSelector((state: Selector) => state.parts);
    const config = useSelector((state: Selector) => state.config.config);

    const { activeArticle } = article;
    const { isHighlightModeActivated } = article.editor;
    const lexicon = config?.filter(element => element.name === "Lexicon")[0]?.data as LexiconEntry[];

    const [highlightDisabled, setHighlightDisabled] = useState(false);

    const handleHightlight = async () => {
        setHighlightDisabled(true);

        let lexiconOriginalValues = Array.from(new Set(lexicon.map(l => l.originalText)));
        let parts = [...articleParts];

        let texts: string[] = [];
        for (let i = 0; i < parts.length; i++) {
            texts.push(parts[i].content);
        }

        await api.highlight.getThesaurusCandidates(activeArticle.id).then(
            async thesaurusResponse => {
                let thesaurusCandidates = await thesaurusResponse.json();

                let candidates: string[] = []
                for (let thesaurusCandidate of thesaurusCandidates["candidates"]) {
                    candidates.push(thesaurusCandidate.text)
                }
                candidates = Array.from(new Set(candidates))

                const flagThesaurusTag = textTags.filter(element => element.id === "flag-word");
                const flagLiaisonTag = textTags.filter(element => element.id === "flag-liaison");

                let updatedParts: ArticlePart[] = [];
                for (let i = 0; i < parts.length; i++) {
                    let updatedPart: ArticlePart = { ...parts[i] };

                    await api.highlight.getLiaisonsCandidates(updatedPart.content).then(
                        async liaisonsResponse => {
                            let liaisonsCandidates = await liaisonsResponse.json();

                            if (liaisonsCandidates.length > 0) {
                                for (let liaisonsCandidate of liaisonsCandidates) {
                                    // check that the candidate is not in a span
                                    const regex = new RegExp(`<span[^>]+>[^<]*${liaisonsCandidate}[^<]*</span>`, 'g');
                                    const found = updatedPart?.contentHtml.match(regex);
                                    if (!found) {
                                        updatedPart.contentHtml = updatedPart?.contentHtml.replaceAll(
                                            liaisonsCandidate,
                                            flagLiaisonTag[0]?.html?.replace("PLACEHOLDER", `${liaisonsCandidate}`)
                                        );
                                    }
                                }
                            }

                            if (candidates.length > 0) {
                                for (let thesaurusCandidate of candidates) {
                                    if (!lexiconOriginalValues.includes(thesaurusCandidate)) {
                                        updatedPart.contentHtml = updatedPart?.contentHtml.replaceAll(
                                            thesaurusCandidate,
                                            flagThesaurusTag[0]?.html?.replace("PLACEHOLDER", `${thesaurusCandidate}`)
                                        );
                                    }
                                }
                            }

                            if (i === parts.length - 1) setHighlightDisabled(false)               
                        }
                    );

                    updatedParts.push(updatedPart);
                }

                dispatch(articlesEditorSetHighlightMode(true));
                dispatch(partsUpdate(updatedParts));
            }
        );
    }

    const handleStopHighlight = () => {
        dispatch(articlesEditorSetHighlightMode(false));
        let parts = [...articleParts];

        const updatedParts: ArticlePart[] = parts.map(p => {
            let cleanedContentHtml = p.contentHtml;
            cleanedContentHtml = deleteTags(cleanedContentHtml, "flag-word");
            cleanedContentHtml = deleteTags(cleanedContentHtml, "flag-liaison");
            return {
                ...p,
                contentHtml: cleanedContentHtml
            }
        })
        dispatch(partsUpdate(updatedParts));
    }

    return <AudioActionButton
        data_test="audioactionbuttons-btn-highlight"
        title={"Highlight important words"}
        icon={<DriveFileRenameOutlineIcon fontSize="small" />}
        disabled={highlightDisabled}
        onClick={isHighlightModeActivated ? handleStopHighlight : handleHightlight}
        variant={isHighlightModeActivated ? "contained" : "outlined"}
        name="Highlight"
    />
}