import api from "./services/api";
import { PlaylistToCreate } from "../interfaces/services/api.interfaces";


export const createNewPlaylist = async (playlist: PlaylistToCreate): Promise<Response> => {
    const data = { ...playlist };

    const resp = await api.playlist.create(data);
    return resp;
}
