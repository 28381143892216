import React, { useEffect, useState, useDeferredValue } from "react";

// modules
import { useDispatch, useSelector } from 'react-redux';
import Swal from "sweetalert2";
import Flag from "react-world-flags";

// mui
import { Box, Button, Checkbox, Divider, IconButton, Popover, Select, Stack, TextField, useTheme } from "@mui/material";
import { TreeItem, TreeView } from "@mui/x-tree-view";
import ArticleIcon from '@mui/icons-material/Article';
import ButtonGroup from "@mui/material/ButtonGroup";
import CheckIcon from "@mui/icons-material/Check";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import LanguageIcon from '@mui/icons-material/Language';
import MenuItem from "@mui/material/MenuItem";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import SendIcon from "@mui/icons-material/Send";

// components
import { AudioFileItem } from "./audioFileItem/AudioFileItem";
import { ParentItemLabel } from "./audioFileItem/parentItemLabel/ParentItemLabel";
import { SearchInput } from "./searchInput/SearchInput";

// interfaces
import { Selector } from "../../../../../interfaces/Selector.interface";

// actions
import { ArticleSet, ArticleVersion, getArticlesAsSets } from "../../../../../helpers/articleSet";
import { closeArticle } from "../../../../../actions/articles.actions";
import { getFlagCode } from "../../../../../data/languageLabels";
import { showPopupRawArticle } from "../../../../../actions/popup.action";
import { sidebarSetAudioFilesPublishedDays } from "../../../../../actions/sidebar.actions";
import { updateArticle } from "../../../../../helpers/articles/updateArticle";
import { Article } from "../../../../../interfaces/article/Article.interface";
import { updateFiles } from "../../../../../actions/files.actions";


export const AudioFilesTab = React.memo(() => {
    const theme = useTheme();
    const dispatch = useDispatch();

    // state selector
    const files = useSelector((state: Selector) => state.files);
    const audioFilesPublishedDaysOld = useSelector((state: Selector) => state.sidebar.audioFilesPublishedDaysOld);

    const [expanded, setExpanded] = useState(['files', 'published']);

    const [filesList, setFilesList] = useState<ArticleSet[]>([]);
    const [publishedList, setPublishedList] = useState<ArticleSet[]>([]);
    const [isDeleteMode, setIsDeleteMode] = useState<boolean>(false);
    const [isSetChecked, setIsSetChecked] = useState<Map<number, boolean>>(new Map());

    const [popoverAnchorEl, setPopoverAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [publishedDaysOld, setPublishedDaysOld] = useState(audioFilesPublishedDaysOld);

    const deferredFilesList = useDeferredValue(filesList);
    const deferredPublishedList = useDeferredValue(publishedList);

    const openPopover = Boolean(popoverAnchorEl);
    const publishedFilterPopoverId = openPopover ? 'published-filter-popover' : undefined;


    const handleTreeItemNodeToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
        setExpanded(nodeIds);
    };

    const handlePublishedFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setPopoverAnchorEl(event.currentTarget);
    };

    const handleTextFieldChange = (event: any) => {
        setPublishedDaysOld(parseInt(event.target.value))
    };

    const handlePublishedFilterClose = () => {
        dispatch(sidebarSetAudioFilesPublishedDays(publishedDaysOld));
        setPopoverAnchorEl(null);
    };

    const handleCreateManualArticle = () => {
        Swal.fire({
            title: 'Are you sure you want to close this article to create a new one ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel'
        }).then(result => {
            if (result.isConfirmed) {
                dispatch(closeArticle());
            }
        })
    }

    const isArticleSelectedAtAll = (): boolean => {
        for (let articleSet of deferredFilesList) {
            for (let articleVersion of articleSet.articlesVersions) {
                for (let articleLanguage of articleVersion.articleLanguages) {
                    if (articleLanguage.getArticle().isSelected) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    const handleDeleteMode = () => {
        if (isDeleteMode) {
            if (isArticleSelectedAtAll()) {
                Swal.fire({
                    title: 'Are you sure you want to delete these articles ?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Confirm',
                    cancelButtonText: 'Cancel'
                }).then(result => {
                    if (result.isConfirmed) {
                        setIsDeleteMode(false)

                        // Delete articles
                        let articlesToDelete: number[] = [];
                        for (let articleSet of deferredFilesList) {
                            for (let articleVersion of articleSet.articlesVersions) {
                                for (let articleLanguage of articleVersion.articleLanguages) {
                                    if (articleLanguage.getArticle().isSelected) {
                                        articlesToDelete.push(articleLanguage.getArticle().id);
                                    }
                                }
                            }
                        }

                        if (articlesToDelete.length > 0) deleteSelectedFiles(articlesToDelete);
                    }
                })
            } else {
                setIsDeleteMode(false)
            }
        } else {
            setIsDeleteMode(true)
        }
    }

    const deleteSelectedFiles = (articlesToDelete: number[]) => {
        for (let id of articlesToDelete) {
            updateArticle(id, { status: 'DELETED' })
        }
    }

    const handleCreateArticle = (e: any) => {
        switch (e.target.value) {
            case 'preprocessed':
                dispatch(showPopupRawArticle());
                break;
            case 'manual':
                handleCreateManualArticle();
                break;
            default:
                break;
        }
    }

    const handleSelectSet = (items: ArticleSet) => {
        const isSelected = isSetChecked.get(items.getFirstArticle()?.id || 0);
        if (isSelected !== undefined) {
            let updatedFiles: Article[] = [...files];

            isSetChecked.set(items.getFirstArticle()?.id || 0, !isSelected);
            setIsSetChecked(new Map(isSetChecked));

            for (let articleVersion of items.articlesVersions) {
                for (let articleLanguage of articleVersion.articleLanguages) {
                    const article = files.filter(article => article.id === articleLanguage.getArticle().id)[0];

                    updatedFiles = updatedFiles.map(a => {
                        if (a.id !== article.id) return a;
                        return { ...article, isSelected: !isSelected }
                    })
                }
            }
            dispatch(updateFiles(updatedFiles));
        }
    }

    const deselectSet = (articleId: number) => {
        const set = deferredFilesList.find(
            (set: ArticleSet) => set.articlesVersions.find(
                (articleVersion: ArticleVersion) => articleVersion.articleLanguages.find(
                    articleLanguage => articleLanguage.getArticle().id === articleId))
        );
        if (set) {
            const exist = isSetChecked.get(set.getFirstArticle()?.id || 0);
            if (exist !== undefined) {
                isSetChecked.set(set.getFirstArticle()?.id || 0, false);
                setIsSetChecked(new Map(isSetChecked));
            }
        }
    }

    // effect to set the files list
    useEffect(() => {
        const filesList = files
            .filter(
                file => (
                    (file.status === 'CREATING' || file.status === 'PENDING' || file.status === 'DRAFT')
                    &&
                    file.visible
                )
            )
            .sort((a, b) => a.fileName.localeCompare(b.fileName));

        const articlesSet = getArticlesAsSets(filesList);
        for (let articleSet of articlesSet) {
            const exist = isSetChecked.get(articleSet.getFirstArticle()?.id || 0);
            if (exist === undefined) {
                setIsSetChecked(isSetChecked.set(articleSet.getFirstArticle()?.id || 0, false));
            }
        }
        setFilesList(articlesSet);

        // Published list
        const publishedList = files
            .filter(file => file.status === 'PUBLISHED' && file.visible)
            .sort((a, b) => a.fileName.localeCompare(b.fileName));

        const publishedArticlesSet = getArticlesAsSets(publishedList);
        setPublishedList(publishedArticlesSet);

        // eslint-disable-next-line
    }, [files])


    return (
        <>
            <ButtonGroup
                variant="outlined"
                aria-label="outlined primary button group"
                orientation="horizontal"
            >

                <Select
                    labelId="audio-tab-creation-select"
                    id="audio-tab-creation-select"
                    key="audio-tab-creation-select"
                    data-test="audiotab-select-creation"
                    value="New"
                    onChange={handleCreateArticle}
                    sx={{ height: '2.3em', mb: 0.5, ml: 0, borderRadius: '4px 0 0 4px', borderRight: 'none' }}
                >
                    <MenuItem value="New" key='new-article' sx={{ display: 'none' }}>
                        <NoteAddIcon fontSize="small" sx={{ margin: '0 0.5em 0 0' }} />
                        NEW FILE
                    </MenuItem>
                    <MenuItem
                        data-test="creation-btn-preprocessed"
                        key="preprocessed-article"
                        value="preprocessed"
                    >
                        Preprocessed
                    </MenuItem>
                    <MenuItem
                        data-test="creation-btn-manual"
                        key="manual-article"
                        value="manual"
                    >
                        Manual
                    </MenuItem>
                </Select>

                <Button
                    id="btn-delete-articles"
                    data-test="audiotab-btn-delete"
                    startIcon={<DeleteIcon />}
                    color={isDeleteMode ? "error" : "primary"}
                    sx={{ borderRadius: '0 4px 4px 0', height: '2.6em' }}
                    onClick={handleDeleteMode}
                >
                    {isDeleteMode ? "Confirm" : "Delete"}
                </Button>

            </ButtonGroup>


            <SearchInput />

            <TreeView
                aria-label="audio-files-tab"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                expanded={expanded}
                onNodeToggle={handleTreeItemNodeToggle}
                key="audio-files-tab"
            >
                <TreeItem
                    data-test="audiotab-tree-files"
                    label={<ParentItemLabel Icon={ArticleIcon} text="FILES" />}
                    nodeId="files"
                    key="audio-tab-draft-files"
                >
                    {deferredFilesList.map((article: ArticleSet) => {
                        if (article.articlesVersions.length > 0 && article.articlesVersions[0].articleLanguages.length > 0) {
                            if (article.articlesVersions.length > 1) {
                                return (
                                    <Box
                                        key={`audio-tab-draft-parent-file-box-${article.getFilename()}-${article.getFileId()}`}>
                                        <Divider sx={{ my: theme.spacing(0.3) }} />

                                        <Stack direction='row' sx={{ display: 'flex', alignItems: 'start' }}>
                                            {
                                                isDeleteMode &&
                                                <Checkbox
                                                    key={article.getFilename() + "-box"}
                                                    color="primary"
                                                    sx={{ p: 0, mr: "5px" }}
                                                    checked={isSetChecked.get(article.getFirstArticle()?.id || 0)}
                                                    onChange={() => handleSelectSet(article)}
                                                />
                                            }
                                            <Box sx={{ ml: '-2px' }}>
                                                {
                                                    article.getNumberOfLanguages() > 1
                                                        ? <LanguageIcon
                                                            sx={{ color: '#333', fontSize: 20, mt: '8px' }} />
                                                        : <Flag
                                                            id={"flag-img-article"}
                                                            code={getFlagCode(article.getFirstArticle()?.language?.code || "fr-FR")}
                                                        />
                                                }

                                            </Box>
                                            <TreeItem
                                                key={`audio-tab-draft-parent-file-${article.getFilename()}-${article.getFileId()}`}
                                                nodeId={article.getFilename() || ""}
                                                label={
                                                    <Box sx={{
                                                        py: expanded.includes(article.getFilename() || "") ? 0 : 1
                                                    }}>
                                                        {article.getFilename()}
                                                    </Box>
                                                }
                                                onClick={() => {
                                                    if (expanded.includes(article.getFilename() || "")) {
                                                        setExpanded(expanded.filter(id => id !== article.getFilename()))
                                                    } else {
                                                        setExpanded([...expanded, article.getFilename() || ""]);
                                                    }
                                                }}
                                                sx={{ width: '100%' }}
                                            >
                                                {article.articlesVersions.map((item: ArticleVersion) => (
                                                    <AudioFileItem
                                                        deleteMode={isDeleteMode}
                                                        deselectAction={deselectSet}
                                                        articleItem={item}
                                                        key={`audio-tab-draft-child-file-${item.getArticleLanguages()[0].getArticle().id}`}
                                                        showArticleName={false}
                                                    />)
                                                )}
                                            </TreeItem>
                                        </Stack>

                                    </Box>

                                )
                            } else {
                                return (
                                    <Box key={`audio-draft-file-box-${article.getFirstArticle()?.id}`}>
                                        <Divider sx={{ my: theme.spacing(0.3) }} />

                                        <AudioFileItem
                                            deleteMode={isDeleteMode}
                                            deselectAction={deselectSet}
                                            articleItem={article.articlesVersions[0]}
                                            key={`audio-draft-file-${article.getFirstArticle()?.id}`}
                                            showArticleName={true}
                                        />
                                    </Box>
                                )
                            }
                        } else {
                            return <React.Fragment key={`draft-empty-${Math.random()}`}></React.Fragment>
                        }
                    })}
                </TreeItem>

                <Divider
                    key="audio-files-tab-divider"
                    sx={{ my: theme.spacing(2) }}
                />

                <TreeItem
                    aria-describedby={publishedFilterPopoverId}
                    data-test="audiotab-tree-published"
                    key='audio-tab-published-files'
                    nodeId="published"
                    label={
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                        }}>
                            <Box
                                sx={{ mr: '8px', display: 'flex', alignItems: 'center' }}>
                                <SendIcon />
                                &nbsp;
                                PUBLISHED
                            </Box>
                            <IconButton
                                aria-label="btn-filter-published"
                                onClick={handlePublishedFilterClick}
                            >
                                <FilterAltIcon />
                            </IconButton>
                        </Box>
                    }
                    sx={{ mb: theme.spacing(2) }}
                >

                    {deferredPublishedList.map((article: ArticleSet) => {
                        if (article.articlesVersions.length > 0 && article.articlesVersions[0].articleLanguages.length > 0) {
                            if (article.articlesVersions.length > 1) {
                                return (
                                    <TreeItem
                                        key={`audio-tab-published-parent-file-${article.getFilename()}-${article.getFileId()}`}
                                        nodeId={article.getFilename() ? article.getFilename()! : ''}
                                        label={article.getFilename()}
                                        onClick={() => {
                                            if (expanded.includes(article.getFilename() || '')) {
                                                setExpanded(expanded.filter(id => id !== article.getFilename()))
                                            } else {
                                                setExpanded([...expanded, article.getFilename() || '']);
                                            }
                                        }}>
                                        {article.articlesVersions.map((item: ArticleVersion) => (
                                            <AudioFileItem
                                                deleteMode={isDeleteMode}
                                                deselectAction={deselectSet}
                                                articleItem={item}
                                                key={`audio-tab-published-child-file-${item.articleLanguages[0].getArticle().id}`}
                                                showArticleName={false}
                                            />
                                        )
                                        )}
                                    </TreeItem>
                                )
                            } else {
                                return (
                                    <AudioFileItem
                                        deleteMode={isDeleteMode}
                                        deselectAction={deselectSet}
                                        articleItem={article.articlesVersions[0]}
                                        key={`audio-published-file-${article.getFirstArticle()!.id}`}
                                        showArticleName={true}
                                    />
                                )
                            }
                        } else {
                            return <React.Fragment key={`published-empty-${Math.random()}`}></React.Fragment>
                        }
                    })}
                </TreeItem>

                <Popover
                    id={publishedFilterPopoverId}
                    open={openPopover}
                    anchorEl={popoverAnchorEl}
                    onClose={handlePublishedFilterClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <Box sx={{
                        p: 2,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center"
                    }}>
                        <TextField
                            label="Filter published days old"
                            variant="outlined"
                            type="number"
                            inputProps={{ min: 1 }}
                            value={publishedDaysOld}
                            onChange={event => handleTextFieldChange(event)}
                        />
                        <IconButton onClick={handlePublishedFilterClose}>
                            <CheckIcon />
                        </IconButton>
                    </Box>
                </Popover>

            </TreeView>

        </>
    )
}
)

