/**
 * This is a generic component for creating context menu
 *
 * How to use:
 * In the component where you want to use the context menu, import the ContextMenu component
 * and the handle function from this file.
 *
 * In the component, create a state for the context menu and a function to set the state in the parent component:
 * ```ts
 * const [contextMenu, setContextMenu] = useState(null) as ContextMenuState;
 * ```
 *
 * Create an open and close function for the context menu in the parent component:
 * ```ts
 * const handleOpenContextMenu = (event: MouseEvent<HTMLDivElement>) =>
 *         setContextMenu(ContextMenu.handle.open(event, contextMenu));
 * const handleCloseContextMenu = () => setContextMenu(ContextMenu.handle.close());
 * ```
 *
 * Create a parent div for the component where you want to use the context menu and add the following props:
 * ```ts
 * <div onContextMenu={handleOpenContextMenu}>
 * ```
 *
 * Use the ContextMenu component in the component where you want to use the context menu:
 * ```ts
 * <ContextMenu.ContextMenu
 *     open={contextMenu !== undefined}
 *     handleClose={handleCloseContextMenu}
 *     anchorPosition={contextMenu}
 *     data={() => SideBarItemContextMenu(item)}
 * />
 * ```
 *
 * You can see an example of this in frontend\src\components\sidebar\filesTab\fileItem\FileItem.tsx
 */

import React from "react";
import { Box, Menu, PopoverPosition } from "@mui/material";

/**
 * Create a context menu
 * @param open - boolean to check if the context menu is open
 * @param handleClose - function to close the context menu
 * @param anchorPosition - position of the context menu
 * @param data - data to be displayed in the context menu
 */
export const MenuRender = ({ open, handleClose, anchorPosition, data }: ContextMenuProps) => {
    return (
        <Menu
            open={open}
            onClose={handleClose}
            anchorReference="anchorPosition"
            anchorPosition={anchorPosition}
        >
            {data != null ? data() : <Box>Context menu is empty</Box>}
        </Menu>
    );
}

/**
 * Handle the opening of the context menu
 * @param event - event that triggered the context menu
 * @param currentState - current state of the context menu
 * @param disableDefault - boolean to disable the default behaviour of the context menu
 */
export const handleOpen = (event: any, currentState: any, disableDefault: boolean = true): PopoverPosition | undefined => {
    if (disableDefault)
        event.preventDefault();
    if (currentState === undefined) {
        return {
            top: event.clientX + 2, // mouseX
            left: event.clientY - 6, // mouseY
        }
    }
    return undefined;
}


/**
 * Handle the closing of the context menu
 */
export const handleClose = () => {
    return undefined;
}

/**
 * Interface for the ContextMenu component
 * @param open - boolean to check if the context menu is open
 * @param handleClose - function to close the context menu
 * @param anchorPosition - position of the context menu
 * @param data - data to be displayed in the context menu
 */
interface ContextMenuProps {
    open: boolean,
    handleClose: () => void,
    anchorPosition: PopoverPosition | undefined,
    data: () => JSX.Element
}

/**
 * Interface for the ContextMenuState.
 * The type of the required state to be user by the ContextMenu component's parent.
 */
export type ContextMenuState = [PopoverPosition | undefined, React.Dispatch<React.SetStateAction<PopoverPosition | undefined>>]

const ContextMenu = {
    ContextMenu: MenuRender,
    handle: {
        open: handleOpen,
        close: handleClose
    }
}

export default ContextMenu;