import { useDispatch, useSelector } from 'react-redux';
import { Snackbar, Alert } from '@mui/material';
import { hideSnackBar } from '../../actions/snackBar.actions';
import { Selector } from '../../interfaces/Selector.interface';

export const SnackBar = () => {
    const dispatch = useDispatch();

    const { autoHideDuration, message, severity, showSnack } = useSelector(
        (state: Selector) => state.snackBar
    );

    const handleClose = () => {
        dispatch(hideSnackBar());
    };

    return (
        <Snackbar
            open={showSnack}
            autoHideDuration={autoHideDuration}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
            <Alert
                onClose={handleClose}
                severity={severity}
                sx={{ width: "100%" }}
                variant="filled"
            >
                {message}
            </Alert>
        </Snackbar>
    );
};
