import { types } from '../actions/actionTypes/actionTypes';
import { SidebarState } from '../interfaces/Selector.interface';

const initialState: SidebarState = {
    selectedTab: 'editor',
    selectedTabName: 'Audio',
    audioFilesPublishedDaysOld: 2
}

export const sidebarReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case types.sidebarTabChange:
            return {
                ...state,
                ...action.payload.data
            }

        case types.sidebarSetAudioFilesPublishedDays:
            return {
                ...state,
                audioFilesPublishedDaysOld: action.payload.data
            }


        default:
            return state
    }
}