import React from 'react';

interface Props {
  seconds: number
}

const format = (seconds: number) => {
  const date = new Date(seconds * 1000)
  const hh = date.getUTCHours()
  const mm = date.getUTCMinutes().toString()
  const ss = pad(date.getUTCSeconds().toString())
  if (hh) {
    return `${hh}:${pad(mm)}:${ss}`
  }
  return `${mm}:${ss}`
}

const pad = (str: string) => `0${str}`.slice(-2)


export const Duration = React.memo(({ seconds }: Props) => {
  return (
    <time dateTime={`P${Math.round(seconds)}S`}>
      {format(seconds)}
    </time>
  )
})
