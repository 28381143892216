import { AnyAction } from "redux";

// types
import { types } from "./actionTypes/actionTypes";

// action creator
import { actionCreator } from "./actionCreator/actionCreator";

// interfaces
import { ArticlePart } from "../interfaces/article/ArticlePart.interface";


export const partsUpdate = (parts: ArticlePart[]): AnyAction => {
    return actionCreator(types.partsUpdate, parts)
}

export const partsReset = (parts: ArticlePart[]): AnyAction => {
    return actionCreator(types.partsReset, parts)
}

export const partsHasSyntaxError = (hasSyntaxError: boolean, partNumber: number): AnyAction => {
    const payload = { hasSyntaxError, partNumber }
    return actionCreator(types.partsHasSyntaxError, payload)
}

export const partsLogout = (): AnyAction => {
    return actionCreator(types.partsLogout)
}
