import { AnyAction } from "redux";

// types
import { types } from "./actionTypes/actionTypes";

// action creator
import { actionCreator } from "./actionCreator/actionCreator";
import { ArticleVersion } from "../helpers/articleSet";
import { Article } from "../interfaces/article/Article.interface";


export const showPopupRawArticle = (): AnyAction => {
    return actionCreator(types.popupShowRawArticle);
};

export const showPopupTranslation = (article: ArticleVersion): AnyAction => {
    return actionCreator(types.popupShowTranslation, article);
}

export const showPopupNewPlaylist = (): AnyAction => {
    return actionCreator(types.popupShowNewPlaylist);
}

export const showPopupUploadAudio = (): AnyAction => {
    return actionCreator(types.popupShowUploadAudio);
}

export const showPopupEditArticleDisplayName = (article: Article): AnyAction => {
    return actionCreator(types.popupShowEditArticleDisplayName, { article });
}

export const showPopupSectionLanguage = (): AnyAction => {
    return actionCreator(types.popupShowSectionLanguage);
}

export const hidePopup = (): AnyAction => {
    return actionCreator(types.popupHide);
}
