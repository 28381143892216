import { useEffect, useState } from 'react';

// modules
import { useDispatch, useSelector } from 'react-redux';

// mui
import { styled } from '@mui/material/styles';
import {
    Box,
    Grid,
    Input,
    Slider,
    Typography,
} from '@mui/material';

// interfaces
import { Selector } from "../../../../../../interfaces/Selector.interface";

// actions
import { partsUpdate } from "../../../../../../actions/parts.actions";
import { editorPartUpdate } from "../../../../../../actions/articles.actions";


const StyledInput = styled(Input)`width: 50px;`;

const typeProps = [
    {
        'id': 'prosodyRate',
        'name': 'rate',
        'label': 'Speed rate',
        'min': 0.60,
        'max': 1.40,
        'step': 0.01
    },
    {
        'id': 'prosodyPitch',
        'name': 'pitch',
        'label': 'Pitch',
        'min': -2,
        'max': 4,
        'step': 0.01
    },
    {
        'id': 'prosodyVolume',
        'name': 'volume',
        'label': 'Volume',
        'min': 0,
        'max': 2,
        'step': 0.01
    },
]

interface Props {
    type: 'rate' | 'pitch' | 'volume'
}

export default function SettingSlider({ type }: Props) {
    const dispatch = useDispatch();

    // state selector
    const parts = useSelector((state: Selector) => state.parts);
    const selectedPart = useSelector((state: Selector) => state.article.editor.selectedPart);
    const voiceList = useSelector((state: Selector) => state.voices.voiceList);

    const [value, setValue] = useState(1);

    const currentType = typeProps.filter(t => t.name === type)

    const updatePartsState = (newValue: number) => {
        const paramType = currentType[0].id;
        const newParts = parts.map(p => p.order === selectedPart ? { ...p, [paramType]: Number(newValue) } : p)

        dispatch(partsUpdate([...newParts]));
        dispatch(editorPartUpdate(true));
    }


    const handleSliderChange = (event: Event, newValue: number | number[]) => {
        if (typeof newValue === 'number') {
            setValue(newValue);
        }
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setValue(Number(value));
        handleSliderBlur('', Number(value));
    };

    const handleInputBlur = () => {
        if (value < 0) {
            setValue(0);
        } else if (value > 2) {
            setValue(2);
        }
    };

    const handleSliderBlur = (e: any, val: any) => {
        let newVal = val || value;
        updatePartsState(newVal);
    }


    useEffect(() => {
        let paramValue = 1;

        if (!parts[selectedPart]) return;

        if (selectedPart <= parts.length) {
            switch (type) {
                case 'rate':
                    paramValue = parts[selectedPart].prosodyRate;
                    break;
                case 'pitch':
                    paramValue = parts[selectedPart].prosodyPitch;
                    break;
                case 'volume':
                    paramValue = parts[selectedPart].prosodyVolume;
                    break;
            }
        }

        setValue(paramValue);

        // eslint-disable-next-line
    }, [selectedPart, voiceList, parts[selectedPart]?.voice.id])


    return (
        <>
            {
                (parts[selectedPart]?.voice?.TTS !== "deepzen") &&
                (parts[selectedPart]?.voice?.TTS !== "elevenlabs") &&
                <Box sx={{ width: '100%', marginBottom: '10px' }}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item>
                            <Typography
                                id="input-slider"
                                data-test={`slider-label-${type}`}
                                component='div'
                                gutterBottom
                                sx={{ width: 80, textAlign: 'left' }}
                            >
                                {currentType[0].label}
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Slider
                                data-test={`slider-bar-${type}`}
                                value={typeof value === 'number' ? value : 0}
                                onChange={handleSliderChange}
                                onChangeCommitted={handleSliderBlur}
                                aria-labelledby="input-slider"
                                size="small"
                                step={currentType[0].step}
                                min={currentType[0].min}
                                max={currentType[0].max}
                            />
                        </Grid>
                        <Grid item>
                            <StyledInput
                                data-test={`slider-input-${type}`}
                                value={value}
                                size="small"
                                onChange={handleInputChange}
                                onBlur={handleInputBlur}
                                inputProps={{
                                    step: currentType[0].step,
                                    min: currentType[0].min,
                                    max: currentType[0].max,
                                    type: 'number',
                                    'aria-labelledby': 'input-slider',
                                }}
                                disabled={parts[selectedPart]?.voice?.TTS === "deepzen"}
                            />
                        </Grid>
                    </Grid>
                </Box>
            }
        </>
    );
}
