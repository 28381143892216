const baseUrl = process.env.REACT_APP_API_URL;


const fetchWithoutToken = (endpoint: string, method: string, data = null) => {
    const url = `${baseUrl}${endpoint}`;

    if (method === 'GET') {
        return fetch(url);
    } else {
        return fetch(url, {
            method,
            headers: {
                'Content-type': 'application/json'
            },
            body: JSON.stringify(data)
        });
    }
}

const fetchWithToken = (
    endpoint: string, method: string, data = null, contentType = 'application/json', tokenKey = 'token'
) => {
    const url = `${baseUrl}${endpoint}`;
    const token = localStorage.getItem(tokenKey) || '';

    if (method !== 'PUT' && method !== 'POST') {
        return fetch(url, {
            method,
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        });
    } else {
        if (contentType === '') {
            const headers = new Headers();
            headers.append('Authorization', 'Bearer ' + token);
            headers.delete('Content-Type')

            return fetch(url, {
                method,
                headers,
                body: data
            });
        } else {
            const body = contentType === 'application/json' ? JSON.stringify(data) : data;
            return fetch(url, {
                method,
                headers: {
                    'Content-type': contentType,
                    'Authorization': 'Bearer ' + token
                },
                body: body
            });
        }
    }
}

export const externalSevices = {
    withToken: {
        get: (endpoint: string, tokenKey = 'token') => fetchWithToken(endpoint, 'GET', null, 'application/json', tokenKey),
        post: (
            endpoint: string, data: any, contentType = 'application/json', tokenKey = 'token'
        ) => fetchWithToken(endpoint, 'POST', data, contentType, tokenKey),
        put: (endpoint: string, data: any) => fetchWithToken(endpoint, 'PUT', data),
        patch: (endpoint: string) => fetchWithToken(endpoint, 'PATCH'),
        delete: (endpoint: string, data?: any) => fetchWithToken(endpoint, 'DELETE', data)
    },
    withTokenImpersonated: {
        get: (endpoint: string) => fetchWithToken(endpoint, 'GET')
    },
    withoutToken: {
        get: (endpoint: string) => fetchWithoutToken(endpoint, 'GET'),
        post: (endpoint: string, data: any) => fetchWithoutToken(endpoint, 'POST', data),
        put: (endpoint: string, data: any) => fetchWithoutToken(endpoint, 'PUT', data),
        patch: (endpoint: string) => fetchWithoutToken(endpoint, 'PATCH'),
        delete: (endpoint: string, data: any) => fetchWithToken(endpoint, 'DELETE', data)
    }
}
