import { useEffect, useRef, useState } from "react";

// modules
import { useDispatch, useSelector } from "react-redux";

// mui
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import { Box, CircularProgress, FormControlLabel, IconButton, Stack, Switch } from "@mui/material";

// interfaces
import { EditorContext } from "../../../../../../interfaces/editor/EditorContext.interface";
import { Selector } from "../../../../../../interfaces/Selector.interface";

// default state
import { playerDefaultState } from '../../../../../../state/playerDefaultState';

// actions
import { setActiveArticle } from "../../../../../../actions/articles.actions";

// handlers
import { handlePlayPause } from "../../../../../generics/player/playerHandlers";
import api from "../../../../../../helpers/services/api";
import { IntroDTO } from "../../../../../../interfaces/article/Intro.interface";
import { Player } from "../../../../../generics/player/Player";

/**
 * @function
 * IntroSelector component. Renders the intro selection area
 * @returns JSX: A Box with the selector and play elements
 */
export default function IntroSelector() {
    const dispatch = useDispatch();
    const { activeArticle }: EditorContext = useSelector((state: Selector) => state.article);

    const [useIntro, setUseIntro] = useState(activeArticle.introActivated);
    const [articleNotLoaded, setArticleNotLoaded] = useState(true);
    const [playerState, setPlayerState] = useState({ ...playerDefaultState });
    const [intro_url, setIntroUrl] = useState("");
    const playerRef = useRef(null);


    const handleUseIntro = () => {
        setUseIntro(!useIntro);
        dispatch(setActiveArticle({
            ...activeArticle,
            introActivated: !useIntro
        }))
    }

    const getIntroUrl = async () => {
        const resp = await api.article.getIntro();
        const intro: IntroDTO = await resp.json();
        setIntroUrl(intro.url);
    }

    useEffect(() => {
        getIntroUrl();
        setUseIntro(activeArticle.introActivated);

        if (activeArticle.id !== -1) setArticleNotLoaded(false);
        // eslint-disable-next-line
    }, [activeArticle])

    useEffect(() => {
        setPlayerState({
            ...playerState,
            url: intro_url
        })
        // eslint-disable-next-line
    }, [intro_url])


    return (
        <Box sx={{ textAlign: 'center', mb: 4 }}>
            <Player playerState={playerState} setPlayerState={setPlayerState} playerRef={playerRef} />

            <Stack direction='row'>
                <FormControlLabel
                    onChange={() => handleUseIntro()}
                    control={<Switch checked={useIntro} />}
                    label="Add the Publisher's Intro"
                    disabled={articleNotLoaded || intro_url === undefined} />

                <IconButton
                    aria-label="startPause"
                    onClick={() => handlePlayPause(setPlayerState)}
                    color='primary'
                    disabled={intro_url === undefined}
                    sx={{ fontSize: 32, marginTop: '5px' }}
                >
                    {
                        playerState.url === '' && playerState.playing &&
                            (!playerState.duration || playerState.isBuffering)
                            ? <CircularProgress color='primary' size={32} />
                            : playerState.playing
                                ? <PauseCircleIcon fontSize='inherit' />
                                : <PlayCircleFilledWhiteIcon fontSize='inherit' />
                    }
                </IconButton>
            </Stack>
        </Box>
    );
}
