import React, { useState, MouseEvent, useMemo, useEffect } from 'react';

// modules
import { useDispatch, useSelector } from 'react-redux';
import Flag from "react-world-flags";

// mui
import { Box, Checkbox, MenuItem, Select, SelectChangeEvent, Stack } from "@mui/material";
import { AddCircle, ExpandMore } from "@mui/icons-material";

// components
import { ItemIcon } from './itemIcon/ItemIcon';
import { ItemTitle } from './itemTitle/ItemTitle';
import { SideBarItemContextMenu } from "../contextMenu/SideBarItemContextMenu";
import ContextMenu from '../../../../../generics/ContextMenu';

// interfaces
import { Article, ArticleWithContent } from '../../../../../../interfaces/article/Article.interface';

// actions
import {
    clearActiveArticle,
    editorSetIsDownloadable,
    editorSetSelectedLanguage,
    setActiveArticle,
} from "../../../../../../actions/articles.actions";
import { resetAudioState } from '../../../../../../actions/audio.actions';
import { partsUpdate } from '../../../../../../actions/parts.actions';
import {
    showWarningSnackBar
} from '../../../../../../actions/snackBar.actions';

// helpers
import api from "../../../../../../helpers/services/api";
import { dtoToHtmlPart } from '../../../../../../helpers/articles/saveArticle/processEditorArticle';

// interfaces
import { getFlagCode } from "../../../../../../data/languageLabels";
import { showPopupTranslation } from "../../../../../../actions/popup.action";
import { ArticleVersion } from "../../../../../../helpers/articleSet";
import { Selector } from '../../../../../../interfaces/Selector.interface';
import { setFileNameLabel } from '../../../../../../helpers/articles/setFileNameLabel';
import { TreeItem } from "@mui/x-tree-view";
import { updateFiles } from '../../../../../../actions/files.actions';


interface Props {
    articleItem: ArticleVersion,
    showArticleName: boolean,
    deleteMode: boolean,
    deselectAction: (articleId: number) => void
}

export const AudioFileItem = ({ articleItem, showArticleName, deleteMode, deselectAction }: Props) => {
    const dispatch = useDispatch();
    const activeArticle = useSelector((state: Selector) => state.article.activeArticle);
    const files = useSelector((state: Selector) => state.files);

    const [contextMenu, setContextMenu] = useState(null) as any;
    const [iconLanguage, setIconLanguage] = useState(0);
    const [isSelected, setIsSelected] = useState(false);

    const { id, createdAt, fileName } = articleItem.articleLanguages[iconLanguage].getArticle();
    const title: string = useMemo(
        () => setFileNameLabel(fileName, createdAt, showArticleName),
        [fileName, createdAt, showArticleName]
    );

    const handleOpenContextMenu = (event: MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        setContextMenu(
            contextMenu === null
                ? {
                    mouseX: event.clientX + 2,
                    mouseY: event.clientY - 6,
                }
                : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                // Other native context menus might behave different.
                // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                null,
        );
    };

    const handleCloseContextMenu = () => setContextMenu(null);

    /**
     * Manage the selection of an article and set it active.
     * @param article {Article} - The article to load

     */
    const handleSelectFile = async (article: Article): Promise<void> => {
        if (!article) return;

        const resp = await api.article.get(article.id);
        const respBody: ArticleWithContent = await resp.json();

        const { parts, ...rest } = respBody;
        const articleData: Article = { ...rest };

        const extendedParts = parts.map(part => ({
            ...part,
            contentHtml: dtoToHtmlPart(part),
            contentHtmlOriginal: dtoToHtmlPart(part)
        }))

        if (respBody) {
            dispatch(setActiveArticle(articleData));
            dispatch(partsUpdate([...extendedParts]));

            if (articleData.language) {
                dispatch(editorSetSelectedLanguage(articleData.language?.code));
            } else if (respBody.parts[0].voice.language) {
                dispatch(editorSetSelectedLanguage(respBody.parts[0].voice.language));
            }
        } else {
            dispatch(clearActiveArticle());
            dispatch(showWarningSnackBar('Article could not be loaded'))
        }


        const { status, audioMediaFile } = articleData;

        // length > 4 to ensure it's longer than a file extersion like '.mp3'
        if ((status === 'DRAFT' || status === 'PUBLISHED') && audioMediaFile?.url && audioMediaFile?.url?.length > 4) {
            dispatch(editorSetIsDownloadable(true));
        }
        else {
            dispatch(editorSetIsDownloadable(false));
            dispatch(resetAudioState());
        }
    }

    const handleTranslate = async (e: SelectChangeEvent<number>): Promise<void> => {
        if (e.target.value === "add") {
            dispatch(showPopupTranslation(articleItem));
        } else {
            if (e.target.value !== null) {
                setIconLanguage(+e.target.value);

                if (articleItem.articleLanguages.find(
                    article => article.getArticle().id === activeArticle.id) !== undefined
                ) {
                    await handleSelectFile(articleItem.articleLanguages[+e.target.value].getArticle());
                }
            }
        }
    }

    const handleSelect = async (): Promise<void> => {
        const isVersionSelected = articleItem.articleLanguages[0].getArticle().isSelected;
        let updatedFiles: Article[] = [...files]
        for (let articleLanguage of articleItem.articleLanguages) {
            const article = files.filter(article => article.id === articleLanguage.getArticle().id)[0];
            updatedFiles = updatedFiles.map(a => {
                if (a.id !== article.id) return a;
                return { ...article, isSelected: !isSelected }
            })
        }

        dispatch(updateFiles(updatedFiles));

        if (isVersionSelected) {
            deselectAction(articleItem.articleLanguages[0].getArticle().id);
        }
        setIsSelected(!isVersionSelected);
    }

    useEffect(() => {
        const isVersionSelected = articleItem.articleLanguages[0].getArticle().isSelected;
        setIsSelected(isVersionSelected);
        // eslint-disable-next-line
    }, [articleItem.articleLanguages[0].getArticle().isSelected])

    // This wrapper is necessary to edit slots of the Select component, otherwise an error is raised.
    const wrapper = React.forwardRef(function wrapper(props, ref) {
        // @ts-ignore
        const { ownerState, ...other } = props;
        // @ts-ignore
        return <div {...other} ref={ref}></div>;
    });

    return (
        <Stack
            data-test={`audiofile-item-${id}`}
            direction='row'
            onContextMenu={handleOpenContextMenu}
            sx={{ display: "flex", justifyContent: "left", alignItems: 'center' }}
        >
            {
                deleteMode &&
                <Box className="delete-mode">
                    <Checkbox
                        key={id}
                        color="primary"
                        sx={{ p: 0, mr: "5px" }}
                        checked={isSelected}
                        onChange={handleSelect}
                    />
                </Box>
            }
            <Box>
                {
                    articleItem.articleLanguages.length > 1 &&
                    <ExpandMore
                        sx={{
                            position: "absolute",
                            ml: "8px",
                            mt: "16px",
                            color: "black",
                            fontSize: 18,
                        }}
                    />
                }
                <Select
                    disableUnderline={true}
                    variant={"standard"}
                    value={iconLanguage}
                    slots={{ root: wrapper }}
                    onChange={(e) => handleTranslate(e)}
                    sx={{
                        transition: 'all 300ms',
                        "& .MuiSelect-select": {
                            padding: "0px",
                            paddingRight: "0px !important",
                        },
                        "& .MuiSelect-icon": {
                            visibility: "hidden !important",
                        },
                        "& .MuiSelect-select:hover": {
                            transform: "scale(1.3)",
                            transition: 'all 300ms',
                        },
                    }}
                >

                    {articleItem.articleLanguages.map((articleLanguage, index) => {
                        const article = articleLanguage.getArticle();
                        return (
                            <MenuItem
                                value={index}
                                key={`-${index}-${article.fileName}-${article.language?.code}`}
                            >
                                <Flag
                                    id={"flag-img-article" + index}
                                    code={getFlagCode(article.language?.code || "fr-FR")}
                                />
                            </MenuItem>)
                    })}
                    <MenuItem value="add"><AddCircle /></MenuItem>
                </Select>
            </Box>

            <TreeItem
                id={`${id}`}
                icon={<ItemIcon item={articleItem.articleLanguages[iconLanguage].getArticle()} />}
                key={`audio-file-${id}`}
                label={
                    <ItemTitle
                        title={title}
                        creationDate={createdAt}
                        isParentArticle={showArticleName}
                        selectedVersion={articleItem.articleLanguages[iconLanguage].getArticle().selectedVersion}
                    />
                }
                nodeId={`${id}`}
                onClick={() =>
                    articleItem.articleLanguages[iconLanguage].getArticle().status !== 'CREATING' &&
                    handleSelectFile(articleItem.articleLanguages[iconLanguage].getArticle())
                }
                sx={{
                    width: "100%",
                    borderRadius: 10,
                    "& .MuiSelect-select:hover": {
                        background: 'red'
                    },
                }}
                disabled={articleItem.articleLanguages[iconLanguage].getArticle().status === 'CREATING'}
            />
            <ContextMenu.ContextMenu
                open={contextMenu !== null}
                handleClose={handleCloseContextMenu}
                anchorPosition={
                    contextMenu !== null
                        ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                        : undefined
                }
                data={() => SideBarItemContextMenu({
                    item: articleItem.articleLanguages[iconLanguage].getArticle(),
                    items: articleItem,
                    setIconLanguage: setIconLanguage
                })}
            />
        </Stack>
    )
}
