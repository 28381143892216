import React, { useState } from "react";

// modules
import { useDispatch, useSelector } from 'react-redux';

// mui
import { Box, InputBase } from "@mui/material";
import { styled, alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';

// interfaces
import { Selector } from "../../../../../../interfaces/Selector.interface";

// actions
import { searchFiles } from "../../../../../../actions/files.actions";

// helpers
import { formatDateToString } from "../../../../../../helpers/formatDateToString";


const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: 10,
    backgroundColor: alpha(theme.palette.info.main, 0.6),
    '&:hover': {
        backgroundColor: alpha(theme.palette.info.main, 0.9),
    },
    marginTop: theme.spacing(0.5),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '13ch',
            '&:focus': {
                width: '23ch',
            },
        },
    },
}));


export const SearchInput = React.memo(() => {
    const dispatch = useDispatch();

    // state selector
    const files = useSelector((state: Selector) => state.files);

    const [searchQuery, setSearchQuery] = useState('');

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchString = event.target.value;
        setSearchQuery(searchString);

        const searchQuery = searchString.toLowerCase();

        if (searchString.length === 0) {
            const initialFiles = files.map(file => ({ ...file, visible: true }));
            dispatch(searchFiles(initialFiles));
        } else {
            const resetFiles = files.map(file => ({ ...file, visible: false }));
            const filteredFiles = resetFiles.map(file => {
                const name = file.fileName?.toLowerCase() || '';
                const creationDate = formatDateToString(file.createdAt) || '';
                const tone = file.tone?.name?.toLowerCase() || '';
                const topic = file.topic?.toLowerCase() || '';

                const fileSearchString = `${name}${creationDate}${tone}${topic}`;

                if (fileSearchString.includes(searchQuery)) {
                    return { ...file, visible: true };
                }
                return file;
            })
            dispatch(searchFiles(filteredFiles));
        }
    }


    return (
        <Box sx={{
            alignItems: 'flex-end',
            display: 'flex',
            justifyContent: 'center',
            mb: 2,
        }}>
            <Search>
                <SearchIconWrapper>
                    <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                    data-test="audiotab-input-search"
                    placeholder="Search…"
                    inputProps={{ 'aria-label': 'search' }}
                    value={searchQuery}
                    onChange={handleSearchChange}
                />
            </Search>
        </Box>
    )
});
