import React from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';

// mui
import { Box, Button, Grid, Typography } from '@mui/material';

// interfaces
import { Selector } from "../../../../interfaces/Selector.interface";
import { PlaylistContent } from "./playlistContent/PlaylistContent";

// actions
import { showPopupNewPlaylist } from "../../../../actions/popup.action";


export const PlaylistPage = React.memo(() => {
    const dispatch = useDispatch();

    // state selector
    const playlistList = useSelector((state: Selector) => state.playlists.playlistList);
    const selectedPlaylist = useSelector((state: Selector) => state.playlists.selectedPlaylist);

    const playlist = playlistList.find(playlist => playlist.id === selectedPlaylist);

    const handleCreatePlaylist = () => {
        dispatch(showPopupNewPlaylist());
    }

    return (
        <Grid container>
            {
                playlist
                    ? <PlaylistContent playlist={playlist} />
                    : <Grid
                        item
                        xs={12} sm={12} md={12} lg={12} xl={9}
                        alignItems="center"
                        justifyContent="center"
                        marginTop="20%"
                    >
                        <Box textAlign="center">
                            <Typography
                                data-test="playlisteditor-label"
                                sx={{ fontSize: '20px', fontWeight: 'bold', display: 'block' }}
                            >
                                Choose a Playlist to modify or click the button below to create a Playlist
                            </Typography>
                        </Box>
                        <Box textAlign="center" sx={{ mt: '10px' }}>
                            <Button
                                data-test="playlisteditor-btn-new"
                                variant="contained"
                                onClick={handleCreatePlaylist}
                                disableElevation
                            >
                                New Playlist
                            </Button>
                        </Box>
                    </Grid>
            }
        </Grid>
    )
});
