import { useContext } from 'react';

import { useDispatch } from 'react-redux';

import { ColorModeContext } from '../../../App';

// mui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Button,
    Container,
    CssBaseline,
    FormControlLabel,
    TextField,
    Typography,
} from '@mui/material';

import { useForm } from '../../../hooks/useForm';
import { startLogin } from '../../../actions/auth.actions';
import { ThemeSwitch } from '../../generics/ThemeSwitch';


/**
 * The login page component for the app
 * @constructor
 */
export const LoginScreen = () => {
    const theme = useTheme();
    const colorMode = useContext(ColorModeContext);
    const dispatch = useDispatch();

    const [formLoginValues, handleLoginInputChange] = useForm({
        lUser: '',
        lPassword: ''
    })

    const { lUser, lPassword }: any = formLoginValues;

    const handleLogin = (e: any) => {
        e.preventDefault();
        startLogin(lUser, lPassword).then(action => dispatch(action));  
    }


    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: theme.spacing(8),
                }}
            >
                <img
                    alt='logo'
                    data-test="login-img-logo"
                    src={
                        theme.palette.mode === 'dark'
                            ? './assets/logo_odia_light.png'
                            : './assets/logo_odia.png'
                    }
                    style={{ marginBottom: theme.spacing(5) }}
                />

                <Typography
                    component="h1"
                    data-test="login-label-title"
                    mt={5}
                    variant="h5"
                >
                    Sign in
                </Typography>

                <form
                    onSubmit={handleLogin}
                    noValidate
                    style={{
                        width: '100%',
                        marginTop: theme.spacing(1),
                    }}
                >
                    <TextField
                        autoComplete="user"
                        autoFocus
                        data-test="login-input-user"
                        fullWidth
                        id="lUser"
                        label="User"
                        margin="normal"
                        name="lUser"
                        onChange={handleLoginInputChange as typeof Object}
                        required
                        value={lUser}
                        variant="outlined"
                    />
                    <TextField
                        autoComplete="current-password"
                        data-test="login-input-pass"
                        fullWidth
                        id="lPassword"
                        label="Password"
                        margin="normal"
                        name="lPassword"
                        onChange={handleLoginInputChange as typeof Object}
                        required
                        type="password"
                        value={lPassword}
                        variant="outlined"
                    />
                    <Button
                        color="primary"
                        data-test="login-btn-submit"
                        fullWidth
                        sx={{ m: theme.spacing(3, 0, 2) }}
                        type="submit"
                        variant="contained"
                    >
                        Sign In
                    </Button>
                </form>

                <Box mt={10}>
                    <FormControlLabel
                        onClick={colorMode.toggleColorMode}
                        control={
                            <ThemeSwitch
                                data-test="login-switch-theme"
                                defaultChecked={theme.palette.mode === 'dark'}
                            />
                        }
                        label={
                            <Typography
                                component='span'
                                data-test="login-label-theme"
                                variant="body2"
                                noWrap
                                sx={{ color: theme.palette.text.secondary }}
                            >
                                {theme.palette.mode === 'dark' ? 'Dark Mode' : 'Light Mode'}
                            </Typography>
                        }
                    />
                </Box>

            </Box>
        </Container>
    );
}