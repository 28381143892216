import { Grid, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';


export const VoiceSettingsGrid = styled(Grid)(({ theme }) => ({
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
    borderRadius: '20px',
    padding: theme.spacing(2),
    textAlign: 'center',
}));

export const LabelTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,
    padding: theme.spacing(1)
}));

export const AudioEffectsGrid = styled(Grid)(({ theme }) => ({
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
    borderRadius: '20px',
    marginTop: 15,
    padding: theme.spacing(2),
    textAlign: 'center',
}));

export const ArticleInfoGrid = styled(Grid)(({ theme }) => ({
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
    borderRadius: '20px',
    marginTop: 15,
    padding: theme.spacing(2),
    textAlign: 'center',
}));

export const CharacterCountTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.secondary,
    padding: theme.spacing(1)
}));

export const ModifiedDateTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.secondary,
    padding: '5px 0 0 5px',
    fontSize: '13px',
    marginTop: '5px',
    textAlign: 'left',
}));

export const ImageGeneratorSettingsGrid = styled(Grid)(({ theme }) => ({
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
    borderRadius: '20px',
    padding: theme.spacing(2),
    textAlign: 'center',
}));

export const ImageUrlSettingsGrid = styled(Grid)(({ theme }) => ({
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
    borderRadius: '20px',
    padding: theme.spacing(2),
    textAlign: 'center',
}));

export const PublisherSelectorSettingsGrid = styled(Grid)(({ theme }) => ({
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
    borderRadius: '20px',
    padding: theme.spacing(2),
    textAlign: 'center',
}));
