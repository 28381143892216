// mui
import VideoCameraFront from '@mui/icons-material/VideoCameraFront';

// interfaces
import { Article } from '../../../../../../../interfaces/article/Article.interface';


interface Props {
    item: Article
}

export const ItemIcon = ({ item }: Props) => {
    return (
        item.videoMediaFile?.url && item.videoMediaFile?.url?.length > 4
            ? <VideoCameraFront />
            : <></>
    )
}
