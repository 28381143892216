import { types } from '../actions/actionTypes/actionTypes';
import { ArticlePart } from '../interfaces/article/ArticlePart.interface';
import { editorInitialParts } from '../state/editorInitialState';

const initialState: ArticlePart[] = { ...editorInitialParts };

export const partsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case types.partsUpdate:
            return [
                ...action.payload.data
            ]

        case types.partsReset:
            return [
                ...action.payload.data
            ]

        case types.partsHasSyntaxError: {
            try {
                const newState = [...state];
                if (newState[action?.payload?.data?.partNumber]) {
                    newState[action?.payload?.data?.partNumber].hasSyntaxError = action?.payload?.data?.hasSyntaxError;
                }
                return newState;
            }
            catch (error) {
                return [...state];
            };
        }

        case types.partsLogout: {
            return initialState
        }

        default:
            return state
    }
}

